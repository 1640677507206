import React, { useEffect, useState } from "react";
import "./home.scss";
import { useQuery } from "react-query";

import Search from "../../../Assets/icons/Search.svg";
import SideNav from "../component/side-nav/side-nav";
import { fetchAppointment } from "../../../apiCall";
import linkdin from "../../../Assets/icons/Linkdin.svg";
import facebook from "../../../Assets/icons/Facebook.svg";
import instagram from "../../../Assets/icons/Instagram.svg";
import { FaTwitter } from "react-icons/fa";
import {
  AppointmentDetailModal,
  BookAppointmentModal,
  CancelAppointmentModal,
} from "../../../components/modal/modal";
import { Link } from "react-router-dom";

function DashboardHomeComponent() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [appointmentModalShow, setAppointmentModalShow] = useState(false);

  const { data: appointments, isLoading, refetch } = useQuery(
    "appointments",
    fetchAppointment,
    {
      onSuccess: (data) => {
        if (data?.status) {
          if (data.status[0].Response === 200) {
            setSelectedOption("Upcoming");
            setAppointmentsDetail(
              shortAppointment(
                data?.data.filter(
                  (element) =>
                    isDateTimeOlder(element) &
                    (element.appointmentStatus !== "Cancelled")
                )
              )
            );
          }
        }
      },
    }
  );

  const [appointmentsDetail, setAppointmentsDetail] = useState([]);
  const handleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const [selectedOption, setSelectedOption] = useState("Upcoming");
  const [selectedappointment, setSelectedappointment] = useState("");
  const shortAppointment = (data) => {
    const compareDateTimes = (appointment1, appointment2) => {
      const date1 = new Date(
        `${appointment1.date} ${appointment1.startTime.slice(0, 8)}`
      );
      const date2 = new Date(
        `${appointment2.date} ${appointment2.startTime.slice(0, 8)}`
      );

      // Compare dates
      if (date1 < date2) {
        return -1;
      } else if (date1 > date2) {
        return 1;
      }

      // If dates are equal, compare times
      const time1 = date1.getTime();
      const time2 = date2.getTime();

      if (time1 < time2) {
        return -1;
      } else if (time1 > time2) {
        return 1;
      }

      // If both dates and times are equal
      return 0;
    };
    return data.sort(compareDateTimes);
  };

  const handleOptionChange = (event, appointment) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Rescheduled") {
      setModalShow(true);
      setSelectedappointment(appointment);
    } else if (event.target.value === "Cancelled") {
      setCancelModalShow(true);
      setSelectedappointment(appointment);
    }
    console.log("sdfdsfsdf");
  };
  const isDateTimeOlder = (data) => {
    // Given date and time
    const givenDateTime = new Date(
      `${data.date}T${data.startTime.slice(0, 8)}`
    );
    // Current date and time
    const currentDateTime = new Date();

    // Compare the two dates
    if (givenDateTime > currentDateTime) {
      return true;
    }
    return false;
  };
  const handleFilterOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "All") {
      setAppointmentsDetail(shortAppointment(appointments?.data).reverse());
    } else if (event.target.value === "Upcoming") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter(
            (element) =>
              isDateTimeOlder(element) &
              (element.appointmentStatus !== "Cancelled")
          )
        )
      );
    } else if (event.target.value === "Past Appointment") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter((element) => !isDateTimeOlder(element))
        ).reverse()
      );
    } else if (event.target.value === "Cancelled") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter(
            (element) => element.appointmentStatus === event.target.value
          )
        ).reverse()
      );
    }
  };
  const handleSearchInput = (event) => {
    console.log(event.target.value);
    if (event.target.value === "") {
      setAppointmentsDetail(shortAppointment(appointments?.data).reverse());
    } else {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter((element) =>
            element.name.includes(event.target.value)
          )
        ).reverse()
      );
    }
  };

  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <h2 className="txt-01">Hello! {localStorage.getItem("name")}</h2>
        <p className="txt-02">What would you like to do today?</p>
        <div className="ap-001">
          <div className="appointment-div">
            <div className="a-01">
              <div className="app-div-01">
                <h3>Appointments</h3>
                <select
                  value={selectedOption}
                  onChange={handleFilterOptionChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Upcoming">Upcoming</option>
                  <option value="Past Appointment">Past Appointment</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div className="search-div">
                <input
                  type="text"
                  placeholder="Search here"
                  onChange={handleSearchInput}
                />
                <img src={Search} alt="" />
              </div>
            </div>
          </div>
          <div>
            <table className="hide-mobile">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {appointmentsDetail.length === 0 ? (
                  <tr>
                    <td colSpan="5">No appointments found.</td>
                  </tr>
                ) : (
                  appointmentsDetail.map((appointment, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className="img-001"
                          onClick={() => {
                            setSelectedappointment(appointment);
                            setAppointmentModalShow(true);
                          }}
                        >
                          <img src={appointment.profile_image} alt="" />
                          <span>{appointment.name}</span>
                        </div>
                      </td>
                      <td>{appointment.date}</td>
                      <td>{appointment.startTime.slice(0, 8)}</td>
                      <td>{appointment.appointmentStatus}</td>
                      <td className="address-text disabled">
                        {appointment.address}
                      </td>
                      <td>
                        <select
                          className="action-btn outline-button"
                          value={appointment.appointmentStatus}
                          onChange={(e) => handleOptionChange(e, appointment)}
                          disabled={
                            appointment.appointmentStatus === "Cancelled"
                          }
                        >
                          <option value="Booked">Action</option>
                          <option value="Cancelled">Cancel</option>
                          <option value="Rescheduled">Reschedule</option>
                        </select>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <div className="appointment-list hide-web">
              <p className="p-tag">Profile</p>
              <hr className="line" />
              {appointmentsDetail.length === 0 ? (
                // <tr>
                <p colSpan="5">No appointments found.</p>
              ) : (
                // </tr>
                appointmentsDetail.map((appointment, index) => (
                  <React.Fragment key={index}>
                    {/* <tr> */}
                    {/* <td> */}
                    <div
                      className="profile-div"
                      onClick={() => handleCollapse(index)}
                    >
                      <img
                        src={appointment.profile_image}
                        alt=""
                        className="profile-img"
                      />
                      <div className="content-wrapper">
                        <div className="title">{appointment.name}</div>
                        <div className="content">
                          {appointment.facebookLink && (
                            <Link to={`${appointment.facebookLink}`}>
                              <img src={facebook} alt="" />
                            </Link>
                          )}
                          {appointment.instagramLink && (
                            <Link to={`${appointment.instagramLink}`}>
                              <img src={instagram} alt="" />
                            </Link>
                          )}
                          {appointment.twitterLink && (
                            <Link to={`${appointment.twitterLink}`}>
                              <FaTwitter size={20} />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* </td> */}
                    {/* <td>{appointment.date}</td>
                      <td>{appointment.startTime}</td>
                      <td>{appointment.problem}</td>
                      <td>{appointment.address}</td> */}
                    {/* </tr> */}
                    <div
                      className={`info-div collapse${
                        expandedIndex === index ? " show" : ""
                      }`}
                    >
                      {/* <td colSpan="5"> */}
                      <p className="info-heading">Appointment Details</p>
                      <hr className="info-divider" />
                      <p className="info-data">Date: {appointment.date}</p>
                      <p className="info-data">Time: {appointment.startTime}</p>
                      <p className="info-data">Email: {appointment.email}</p>
                      <p className="info-data">
                        Phone Number: {appointment.phoneNumber}
                      </p>
                      <p className="info-data">
                        Status: {appointment.appointmentStatus}
                      </p>
                      <p className="info-data">Where: {appointment.city}</p>
                      <p className="info-data">
                        Address: {appointment.address}
                      </p>
                      {/* </td> */}
                      <select
                        className="action-btn outline-button"
                        value={appointment.appointmentStatus}
                        onChange={(e) => handleOptionChange(e, appointment)}
                        disabled={appointment.appointmentStatus === "Cancelled"}
                      >
                        <option value="Booked">Action</option>
                        <option value="Cancelled">Cancel</option>
                        <option value="Rescheduled">Reschedule</option>
                      </select>
                    </div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
      <BookAppointmentModal
        title="Reschedule Appointment"
        successResponseMessage="The appointment is successfully rescheduled."
        show={modalShow}
        appointment={selectedappointment}
        handleClose={() => setModalShow(false)}
      />
      <CancelAppointmentModal
        show={cancelModalShow}
        appointment={selectedappointment}
        refetch={refetch}
        handleClose={() => setCancelModalShow(false)}
      />
      <AppointmentDetailModal
        show={appointmentModalShow}
        appointment={selectedappointment}
        handleClose={() => setAppointmentModalShow(false)}
      />
    </div>
  );
}

export default DashboardHomeComponent;
