import React, { useEffect } from "react";
import AOS from "aos";
import "./PhoneNumber.scss";
import page from "../../../../Assets/image/signup-4.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import { phoneNumberCheck } from "../../../../apiCall";
import toast from "react-hot-toast";



function PhoneNumber({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarValue,
  progresBarMaxValue,
}) {
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  const mobile_number_regex = /^[6-9]\d{9}$/i;
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const handleNext = async () => {
    const re = await phoneNumberCheck({ phoneNumber: formData.phoneNumber });
    if (re.status[0].Response === 200) {
      nextSteps();
    } else {
      toast.error("Phone Number Is Already In Use.");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    if (e.target.value.length < 1 || value === "") {
      setPhoneNumberErr("Phone number is required");
      return setFormData({ ...formData, phoneNumber: "" });
    }
    if (!mobile_number_regex.test(e.target.value)) {
      setFormData({ ...formData, phoneNumber: e.target.value });
      setPhoneNumberErr("Phone number is not valid");
    } else {
      setPhoneNumberErr("");
      setFormData({ ...formData, phoneNumber: e.target.value });
    }
  };
  return (
    <section className="section-phone-number">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Dental Hygiene for all
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" ><strong>Hi Dr. {formData.name}</strong> We are glad to have you here at gentledental, where we will help people achieve good dental hygiene together. <span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                Cool! Please enter your phone number
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter your phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    size="sm"
                    className="input"
                    id="form1"
                    type="tel"
                    onChange={handleInputChange}
                  />
                  <small className="error">{phoneNumberErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !mobile_number_regex.test(formData.phoneNumber) || phoneNumberErr
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={
                    !mobile_number_regex.test(formData.phoneNumber) || phoneNumberErr
                  }
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default PhoneNumber;
