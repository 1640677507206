import React, { useEffect, useState } from "react";

import SideNav from "../component/side-nav/side-nav";
import Search from "../../../Assets/icons/Search.svg";
import Zip from "../../../Assets/icons/File.svg";
import Upload from "../../../Assets/icons/upload.svg";

import "./shared-files.scss";
function SharedFiles() {
  const [currentDateTime, setDateTime] = useState(new Date());
  useEffect(() =>{
      const interval = setInterval(() => {
        setDateTime(new Date());
      }, 1000);

      return(()=>{
        clearInterval(interval);
      })
  }, []);

  const files = [
    {
      name: "File.zip",
      // designation: "consultant",
      // pic: Pic,
      size: "500 mb",
    },
    {
      name: "Report",
      // designation: "consultant",
      // pic: Pic2,
      size: "1.89 kb"
    },
   
  ];

  const formateDate = (date) => {
    if(date) {
    const options = {
      day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }
    return date.toLocaleString(undefined, options);
  }
  return "";
  }


  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <div className="ap-001">
          <div className="appointment-div">
            <div className="a-01"></div>
          </div>
          <div>
            <table>
              <thead className="thead-bkc">
                <tr>
                  <th className="your-visit">Your Files</th>
                  <th>
                    {" "}
                    <div className="search-div">
                      <input type="text" placeholder="Search here" />
                      <img src={Search} alt="" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="shared-files-tbody">
                {files.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="img-001">
                        <img src={Zip} alt="" />
                        <div className="txt-01">
                          <p className="name">{data.name}</p>
                          {console.log(currentDateTime)}
                          <p className="designation">{formateDate( currentDateTime)} {data.size}</p>
                        </div>
                      </div>
                    </td>
                    <td className="upload"><img src={Upload} alt="" /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SharedFiles;
