import React, { useEffect, useState } from "react";
import "./CancelAppointment.scss";
import { getDentistAppointments, updateAppointment,cancelAppointment } from "../../apiCall";
import toast from "react-hot-toast";

const CancelAppointment = ({ appointment, handleClose,refetch }) => {
  console.log(appointment)
  const bookAppointment = async () => {
    const data = {
      appointmentId:appointment.id,
    }
    const re = await cancelAppointment(data)
    if(re.status[0].Response === 200){
      toast.success("The appointment is successfully Cancelled.")
      appointment.appointment_status = "Cancelled"
      refetch()
      handleClose()
    }
  };

  return (
    <div className="cancel-appointment">
      <div className="heading"> Cancel Appointment</div>
      <div className="text">
        Are you sure you want to cancel your appointment?
      </div>
      {/* <div className="sub-heading">Appointment Details:</div>
      <div className="sub-text">
        <span> <strong>Date : </strong>{appointment.date}</span>
        <span> <strong>Time : </strong>{appointment.startTime}</span>
        <span> <strong>With : </strong>{appointment.name} </span>
      </div> */}

      <button className="primary-button" onClick={bookAppointment}>
        Conform
      </button>
    </div>
  );
};

export default CancelAppointment;
