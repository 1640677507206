import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useStateValue } from '../StateProvider';

function ProtectedRoute({ children }) {
    const [{ isLoggedIn,isGoogleLogIn,isProfileCompleted, user_type }] = useStateValue();

  let location = useLocation();
    

  const allowedRoutes = ["/", "/sign-up", "/sign-in"];


  if (isLoggedIn && isGoogleLogIn && !isProfileCompleted &&location.pathname !== "/google/sign-up-form" ) {
    return <Navigate to="/google/sign-up-form" state={{ from: location }} replace />;
  }
  if (isLoggedIn && isGoogleLogIn && isProfileCompleted &&location.pathname === "/google/sign-up-form" ) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (isLoggedIn && user_type === "doctor" && (location.pathname === "/sign-up" || location.pathname === "/sign-in" || location.pathname === "/dashboardhome" || location.pathname === "/dashboard-home")) {
    return <Navigate to="/doctor-dashboard-home" state={{ from: location }} replace />;
  }

  if (isLoggedIn && user_type === "patient" && (location.pathname === "/sign-up" || location.pathname === "/doctor-dashboard-home" || location.pathname === "/sign-in")) {
    return <Navigate to="/dashboardhome" state={{ from: location }} replace />;
  }
  if (isLoggedIn && user_type === "patient" && location.pathname === "/doctor-edit-profile"){
    return <Navigate to="/edit-profile" state={{ from: location }} replace />;
  }
  if (isLoggedIn && user_type === "doctor" && location.pathname === "/membership"){
    return <Navigate to="/doctor-dashboard-home" state={{ from: location }} replace />;
  }
  if (isLoggedIn && user_type === "doctor" && location.pathname === "/edit-profile"){
    console.log("condition 4 started");
    return <Navigate to="/doctor-edit-profile" state={{ from: location }} replace />;
  }
  // // If user is not logged in and tries to access a restricted route, redirect to sign-in page
  console.log(isLoggedIn,location.pathname,localStorage.getItem("token") ? true : false,"ertertertertr")
  if (!isLoggedIn && !allowedRoutes.includes(location.pathname)) {
    return <Navigate to="/sign-in" replace />;
  }

  // // If user is logged in and tries to access sign-up or sign-in pages, redirect to home page
  if (isLoggedIn && allowedRoutes.includes(location.pathname) && user_type==="patient") {
    return <Navigate to="/" replace />;
  }

  if (isLoggedIn && allowedRoutes.includes(location.pathname) && user_type==="doctor") {
    return <Navigate to="/doctor-dashboard-home" replace />;
  }
  return children;
}

export default ProtectedRoute;
