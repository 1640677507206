import React, { useEffect, useRef, useState } from "react";
import { FaRegImages } from "react-icons/fa";
import { IoIosCamera } from "react-icons/io";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import "./ImageUpload.scss";
import { CameraImageUploadModal } from "../../../components/modal/modal";
const ImageUpload = ({ handleSend, handleImageUpload }) => {
  //   const [image, setImage] = useState(null);
  const [cameraModalShow, setCameraModalShow] = useState(false);

  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log("Sdsdddddddddddddddddddddddddd")
      reader.onloadend = () => {
        handleImageUpload(reader.result);
        fileInputRef.current.value = ''
      };
      reader.readAsDataURL(file);
    }
  };

  // Trigger file input click
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  

  return (
    <div className="icon-container">
      <MdOutlineDriveFolderUpload
        size={30}
        onClick={handleFileClick}
        style={{ cursor: "pointer" }}
        title="Upload Image"
        fill="#212529"
      />
      <IoIosCamera
        size={"30px"}
        onClick={() => setCameraModalShow(true)}
        style={{ cursor: "pointer" }}
        title="Take Photo"
        fill="#212529"
      />



      {/* Hidden file input for selecting images */}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleImageChange}
      />

      {/* Hidden file input for camera capture */}
      <input
        type="file"
        accept="image/*"
        capture="environment"
        style={{ display: "none" }}
        ref={cameraInputRef}
        onChange={handleImageChange}
      />
      <CameraImageUploadModal
        show={cameraModalShow}
        handleImageUpload={handleImageUpload}
        handleClose={() => setCameraModalShow(false)}
      />
    </div>
  );
};

export default ImageUpload;
