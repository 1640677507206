import React, { useState } from "react";
import "./GoogleSignupForm.scss";

import ChooseOne from "./GoogleSignupFormComponent/ChooseOne/ChooseOne";
import Name from "../Signup/SignupComponent/Name/Name";
import RegistreationNo from "../Signup/SignupComponent/RegistreationNo/RegistreationNo";
import PhoneNumber from "../Signup/SignupComponent/PhoneNumber/PhoneNumber";
import Birthdate from "../Signup/SignupComponent/Birthdate/Birthdate";
import Experience from "../Signup/SignupComponent/Experience/Experience";
import ClinicDetails from "../Signup/SignupComponent/ClinicDetails/ClinicDetails";
import SocialLink from "../Signup/SignupComponent/SocialLink/SocialLink";
import OtherLink from "../Signup/SignupComponent/OtherLink/OtherLink";
import Fees from "../Signup/SignupComponent/Fees/Fees";
import Hours from "../Signup/SignupComponent/Hours/Hours";
import Expertise from "../Signup/SignupComponent/Expertise/Expertise";
import Specialty from "../Signup/SignupComponent/Specialty/Specialty";
import ThankYou from "./GoogleSignupFormComponent/ThankYou/ThankYou";
import Address from "../Signup/SignupComponent/Address/Address";
import {
  DoctorGoogleSignUpForm,
  PatientGoogleSignUpForm,
  dentistSignup,
  patientSignup,
} from "../../apiCall";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

function GoogleSignupForm() {
  const initialValues = {
    step: 1,
    name: "",
    registrationNumber: "",
    phoneNumber: "",
    birthdate: "",
    gender: "male",
    workExperience: "",
    clinicName: "",
    clinicAddress: "",
    address: "",
    postalCode: "",
    state: "",
    city: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    otherSocialLink: "",
    otherLink: "",
    personalWebsite: "",
    checkupPrice: "",
    appointmentSlot: [{ from: "", to: "" }],
    expertise: [],
    specialty: [],
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const [userType, setUserType] = useState("dentist");
  const [, dispatch] = useStateValue();
  const setUser = (Type) => {
    setFormData(initialValues);
    setUserType(Type);
    nextStep();
  };
  const prevStep = () => {
    const value = formData.step - 1;
    setFormData({ ...formData, step: value });
  };
  const nextStep = async () => {
    if (formData.step === 13 && userType === "dentist") {
      formData.specialty = formData.specialty.map((data) => data.id);
      formData.expertise = formData.expertise.map((data) => data.id);

      console.log(formData);
      dispatch({ type: "SET_LOADING", status: true });
      const signup = await DoctorGoogleSignUpForm(formData);
      dispatch({ type: "SET_LOADING", status: false });
      const reMessage = signup["status"][0]["Error"];
      console.log(signup, "response");
      if (reMessage === "False") {
        toast.success(signup["status"][0]["Message"]);
        const value = formData.step + 1;
        setFormData({ ...formData, step: value });
        window.localStorage.setItem("token", signup["access_token"]);
        window.localStorage.setItem("email", signup.data.email);
        window.localStorage.setItem("name", signup.data.name);
        window.localStorage.setItem("phoneNumber", signup.data.phoneNumber);
        window.localStorage.setItem("profile", signup.data.profile);
        window.localStorage.setItem("userType", "doctor");
        window.localStorage.setItem("isGoogleLogIn", true);
        window.localStorage.setItem("isProfileCompleted", true);
        dispatch({
          type: "USER_TYPE",
          status: "doctor",
        });
        dispatch({ type: "LOGIN", status: true });
        dispatch({ type: "GOOGLELOGINSTATUS", status: true });
        dispatch({ type: "USERPROFILESTATUS", status: true });

        navigate("/dashboardhome");
      } else {
        toast.error(signup["status"][0]["Message"]);
        setFormData(initialValues);
      }
    } else if (formData.step === 6 && userType === "patient") {
      // toast.warning("Wait! we will proceed your request.");
      dispatch({ type: "SET_LOADING", status: true });
      const signup = await PatientGoogleSignUpForm(formData);
      dispatch({ type: "SET_LOADING", status: false });
      const reMessage = signup["status"][0]["Error"];
      console.log(signup);
      if (reMessage === "False") {
        toast.success(signup["status"][0]["Message"]);
        const value = formData.step + 1;
        setFormData({ ...formData, step: value });
        window.localStorage.setItem("token", signup["access_token"]);
        window.localStorage.setItem("email", signup.data.email);
        window.localStorage.setItem("name", signup.data.name);
        window.localStorage.setItem("phoneNumber", signup.data.phoneNumber);
        window.localStorage.setItem("profile", signup.data.profile);
        window.localStorage.setItem("userType", userType);
        window.localStorage.setItem(
          "is_subscriber",
          signup.data.is_subscriber
        );
        window.localStorage.setItem("isGoogleLogIn", true);
        window.localStorage.setItem("isProfileCompleted", true);
        dispatch({ type: "LOGIN", status: true });
        dispatch({ type: "USER_TYPE", status: userType });
        dispatch({ type: "GOOGLELOGINSTATUS", status: true });
        dispatch({ type: "USERPROFILESTATUS", status: true });
        navigate("/");
      } else {
        toast.error(signup["status"][0]["Message"]);
        setFormData(initialValues);
      }
    } else {
      const value = formData.step + 1;
      setFormData({ ...formData, step: value });
    }
  };
  if (userType === "dentist") {
    switch (formData.step) {
      case 1:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ChooseOne
                  setUser={setUser}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </div>
          </section>
        );
      case 2:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Name
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );
      case 3:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <RegistreationNo
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={20}
                />
              </div>
            </div>
          </section>
        );
      case 4:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <PhoneNumber
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={30}
                />
              </div>
            </div>
          </section>
        );
      case 5:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Birthdate
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={60}
                />
              </div>
            </div>
          </section>
        );
      case 6:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Experience
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      case 7:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ClinicDetails
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      case 8:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <SocialLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={90}
                />
              </div>
            </div>
          </section>
        );
      case 9:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <OtherLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={100}
                />
              </div>
            </div>
          </section>
        );
      case 10:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Fees
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={110}
                />
              </div>
            </div>
          </section>
        );
      case 11:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Hours
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={120}
                />
              </div>
            </div>
          </section>
        );
      case 12:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Expertise
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={130}
                />
              </div>
            </div>
          </section>
        );
      case 13:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Specialty
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={140}
                />
              </div>
            </div>
          </section>
        );
      case 14:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ThankYou
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={140}
                />
              </div>
            </div>
          </section>
        );
      default:

      // do nothing
    }
  } else {
    switch (formData.step) {
      case 1:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ChooseOne
                  setUser={setUser}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );
      case 2:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Name
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );
      case 3:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <PhoneNumber
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={20}
                />
              </div>
            </div>
          </section>
        );
      case 4:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Birthdate
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={50}
                />
              </div>
            </div>
          </section>
        );

      case 5:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <SocialLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={60}
                />
              </div>
            </div>
          </section>
        );
      case 6:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Address
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );

      case 7:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ThankYou
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      default:

      // do nothing
    }
  }
}

export default GoogleSignupForm;
