import React from "react";
import Pic from "../../../Assets/image/p1.png";
import Pic2 from "../../../Assets/image/p2.png";
import SideNav from "../component/side-nav/side-nav";
import Search from "../../../Assets/icons/Search.svg";
import Instagram from "../../../Assets/icons/Instagram.svg";
import LinkedIn from "../../../Assets/icons/Linkdin.svg";
import Facebook from "../../../Assets/icons/Facebook.svg";
import "./your-visits.scss";

function YourVisits() {
  const patients = [
    {
      name: "John Sena",
      designation: "consultant",
      pic: Pic,
    },
    {
      name: "John Sena",
      designation: "consultant",
      pic: Pic2,
    },
    {
      name: "John Sena",
      designation: "consultant",
      pic: Pic,
    },
  ];
  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <div className="ap-001">
          <div className="appointment-div">
            <div className="a-01"></div>
          </div>
          <div>
            <table>
              <thead className="thead-bkc">
                <tr>
                  <th className="your-visit">Your Visit</th>
                  <th>
                    {" "}
                    <div className="search-div">
                      <input type="text" placeholder="Search here" />
                      <img src={Search} alt="" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="shared-files-tbody">
                {patients.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="img-001">
                        <img src={data.pic} alt="" />
                        <div className="txt-01">
                          <p className="name">{data.name}</p>
                          <p className="designation">{data.designation}</p>
                          <div className="social-icon display-flex">
                            <img src={Instagram} alt="" /><img src={Facebook} alt="" /><img src={LinkedIn} alt="" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>-</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default YourVisits;
