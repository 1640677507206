import React from 'react'
import './fileView.scss'
import { ReactComponent as FileIcon } from '../../../../Assets/icons/file-download.svg'

const FileView = ({ link, isSelf }) => {
    const lastSlashIndex = link ? link.lastIndexOf('/') : -1;
    const name = link ? link.substring(lastSlashIndex + 1) : "";

    const handleDownload = async () => {
        const response = await fetch(link);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const down = document.createElement('a');
        down.href = url;
        down.target = "_blank"
        down.download = name; // Set the desired file name
        down.click();
    };

    return (
        <div className={`file-container ${isSelf ? 'self' : ''}`} onClick={handleDownload}>
            <div className="file-icon">
                <FileIcon />
            </div>
            <span>{name}</span>

        </div>
    )
}

export default FileView