import React, { useEffect, useState } from "react";
import AOS from "aos";
import "./Hours.scss";
import page from "../../../../Assets/image/signup-5.png";
import { MDBInput } from "mdb-react-ui-kit";

function Hours({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  const [errors, setErrors] = useState([]);
  const timeIsAfter = (time1, time2) => {
    const [hour1, minute1] = time1.split(":");
    const [hour2, minute2] = time2.split(":");

    return hour1 > hour2 || (hour1 === hour2 && minute1 > minute2);
  };
  const isTimeDurationValide = (time1, time2) => {
    const [hour1, minute1] = time1.split(":");
    const [hour2, minute2] = time2.split(":");
    const totalMinutes1 = parseInt(hour1) * 60 + parseInt(minute1);
    const totalMinutes2 = parseInt(hour2) * 60 + parseInt(minute2);
    return totalMinutes1 >= totalMinutes2 + 60;
  };
  const isSlotValide = (pair) => {
    if (!timeIsAfter(pair.to, pair.from)) {
      return ({errorStatus:true,errorMessage:" End time must be after start time."})
    }
    if (!isTimeDurationValide(pair.to, pair.from)){
      return ({errorStatus:true,errorMessage:" The duration of solt is greater than or equal to 60 minutes."})
    }
    return ({errorStatus:false,errorMessage:""})
  };

  const addSlot = () => {
    const newErrors = formData.appointmentSlot.map((pair) =>
      isSlotValide(pair)
    );
    setErrors(newErrors);

    if (newErrors.every((err) => !err.errorStatus)) {
      setErrors([]);
      nextSteps();
    }
  };

  const handleInputChange = (index, type, value) => {
    const newInputPairs = [...formData.appointmentSlot];
    newInputPairs[index][type] = value;
    setFormData({ ...formData, appointmentSlot: newInputPairs });
  };

  const addInputPair = () => {
    setFormData({
      ...formData,
      appointmentSlot: [...formData.appointmentSlot, { from: "", to: "" }],
    });
    setErrors((prevErrors) => [...prevErrors, false]);
  };

  const removeInputPair = (index) => {
    setFormData({
      ...formData,
      appointmentSlot: formData.appointmentSlot.filter((_, i) => i !== index),
    });
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  return (
    <section className="section-hours">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              We make smiles healthier & brighter
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                Tell us your preferred hours for consultation.
              </div>
              <div className="input-container">
                {formData.appointmentSlot.map((pair, index) => (
                  <div className="input-wrapper">
                    <div className="input-inner-container">
                      <div className="md-wrapper">
                        {index === 0 && <span>From</span>}
                        <MDBInput
                          name="birthdate"
                          size="sm"
                          className="input"
                          id="form1"
                          type="time"
                          value={pair.from}
                          onChange={(e) =>
                            handleInputChange(index, "from", e.target.value)
                          }
                        />
                      </div>
                      <div className="md-wrapper">
                        {index === 0 && <span>To</span>}

                        <MDBInput
                          name="birthdate"
                          size="sm"
                          className="input"
                          id="form1"
                          type="time"
                          value={pair.to}
                          onChange={(e) =>
                            handleInputChange(index, "to", e.target.value)
                          }
                        />
                      </div>
                      {formData.appointmentSlot.length > 1 && (
                        <div className="btn-wrapper">
                          <button
                            className="disable-button rm-btn"
                            onClick={() => removeInputPair(index)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                    {console.log(errors)}
                    {errors[index]?.errorStatus && (
                      <span className="input-error">
                       {errors[index].errorMessage}
                      </span>
                    )}
                  </div>
                ))}
              </div>
              <div className="add-slot">
                <button className=" disable-button" onClick={addInputPair}>
                  Add Slot
                </button>
              </div>

              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>

                <button
                  className="next disable-button primary-button "
                  onClick={addSlot}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Hours;
