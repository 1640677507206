import React from "react";
import "./ContactUs.scss";
import { FiPhone } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import image from "../../Assets/image/signup-1.png";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="left">
        <div className="title">Contact us</div>
        <div className="content">
          For any inquiries, support, or feedback regarding GentleDental.ai's
          services, including the Gentle AI Smart Toothbrush, or to initiate a
          return or discuss your subscription, please feel free to reach out to
          us through the following channels. Our dedicated team is here to
          provide you with the assistance you need to ensure a satisfying
          experience with our digital dental solutions.
        </div>
        <div className="content">
          <h5>Live Chat:</h5>
          <span>
            Get instant support by using the live chat feature on our website.
            Available Monday to Friday, 9 AM to 5 PM (Time Zone).
          </span>
        </div>
        <div className="content">
          <h5>Social Media:</h5>
          <span>Follow us and direct message us on:</span>
          <ul>
            <li>Twitter: @gentledentalAI</li>
            <li>
              Facebook:{" "}
              <a href="https://facebook.com/GentleDentalAI" target="_blank">
                facebook.com/GentleDentalAI
              </a>
            </li>
            <li>Instagram: @GentleDental.AI</li>
          </ul>
        </div>
        <div className="content">
          <h5>Support Form:</h5>
          <span>
            You can also fill out a support form on our website with your query,
            and one of our team members will get back to you within 24-48 hours.
          </span>
          <br />
          <br />
          <span>
            Our goal is to respond to all queries promptly and efficiently.
            Whether you have a question about our services, need technical
            support, or wish to provide feedback, we're here to listen and help.
            Your satisfaction and dental health are our top priorities at
            GentleDental.ai.
          </span>
        </div>
        <div className="form-wrapper">
          <form action="">
            <div className="input-wrapper">
              <label htmlFor="">Name</label>
              <input type="text" placeholder="write your name" />
            </div>
            <div className="input-wrapper">
              <label htmlFor="">Email</label>
              <input type="text" placeholder="write your email" />
            </div>
            <div className="input-wrapper">
              <label htmlFor="">Phone number</label>
              <input type="text" placeholder="write your phone number" />
            </div>
            <div className="input-wrapper">
              <label htmlFor="">Message</label>
              <textarea type="text" placeholder="Leave us a message" />
            </div>
            <div className="submit-btn">
              <button className="primary-button">Send Message </button>
            </div>
          </form>
        </div>
      </div>
      <div className="right">
        <div className="image-wrapper">
          <img src={image} alt="" />
        </div>
        <div className="content-wrapper">
          <div>
            <FiPhone />
            +91 7896646246{" "}
            <span> (Available Monday to Friday, 9 AM to 5 PM (IST))</span>
          </div>
          <div>
            <MdMailOutline />
            support@gentledental.ai
          </div>
          <div>
            <MdMailOutline />
            returns@gentledental.ai
          </div>
          <div>
            <HiOutlineLocationMarker />
            Neordent Healthcare Solutions Pvt Ltd House no. 39, Urmi Nagar, PO
            Narengi Guwahati 781026, Assam
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
