import React, { useEffect } from "react";
import AOS from "aos";
import "./Experience.scss";
import page from "../../../../Assets/image/signup-2.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";

function Experience({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  const [expErr, setExpErr] = useState("");

  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    if (value.length < 1) {
      setExpErr("Years of work experience is required");
      return setFormData({ ...formData, workExperience: "" });
    }
    setFormData({ ...formData, workExperience: value });
    setExpErr("");
  };

  return (
    <section className="section-experience">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              We make smiles healthier & brighter
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                So Dr. {formData.name}, for how many years have you been practicing
                dentistry? <span>&#x1F914;</span>
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter the number of years"
                    value={formData.workExperience}
                    name="experience"
                    size="sm"
                    className="input"
                    id="form1"
                    type="number"
                    onChange={handleInputChange}
                  />
                  <small className="error">{expErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !formData.workExperience
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.name}
                  onClick={() => nextSteps()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Experience;
