import React from "react";
import { ReactComponent as Dashboard } from "../../../../Assets/icons/Dashboard.svg";
import { ReactComponent as Profile } from "../../../../Assets/icons/profile.svg";
import { ReactComponent as Chat } from "../../../../Assets/icons/chat.svg";
import { BsCalendar2Date } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdCardMembership } from "react-icons/md";
import "./side-nav.scss";
import { useStateValue } from "../../../../StateProvider";
function SideNav() {
  const navigate = useNavigate();
  const [{ user_type }] = useStateValue();

  return (
    <>
      <div className="outer-side-nav">
        <section className="side-nav">
          <div className="side-div" onClick={() => navigate("/dashboardhome")}>
            {/* <img src={Dashboard} alt="dashboard" /> */}
            <Dashboard />
            <p className="side-nav-txt">Dashboard</p>
          </div>
          <div className="side-div" onClick={() => navigate("/calendar")}>
            {/* <img src={Dashboard} alt="dashboard" /> */}
            <BsCalendar2Date size={24} />
            <p className="side-nav-txt">Calendar</p>
          </div>
          <div className="side-div" onClick={() => navigate("/chat")}>
            {/* <img src={Chat} alt="dashboard" /> */}
            <Chat />
            <p className="side-nav-txt ">Chat</p>
          </div>
          {user_type === "patient" &&
          
            <div
              className="side-div-Membership"
              onClick={() => navigate("/membership")}
            >
              {/* <img src={Chat} alt="dashboard" /> */}
              <MdCardMembership size={24} />
              <p className="side-nav-txt ">Membership</p>
            </div>
          }
          <div
            className="profile side-div"
            onClick={() => navigate("/edit-profile")}
          >
            {/* <img src={Profile} alt="profile" /> */}
            <Profile />
            <p className="side-nav-txt ">Profile</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default SideNav;
