import React, { useEffect } from 'react';
import AOS from 'aos';
import "./SocialLink.scss"
import page from "../../../../Assets/image/signup-2.png"



function SocialLink({ nextSteps, prevStep, formData, setFormData,progresBarMaxValue,progresBarValue }) {
    useEffect(() => {
        // window.scrollTo(0, 0)
        AOS.init({
            // initClassName: 'aos-init',
            // useClassNames: false,
            once: true,
        });
    }, [])
    return (
        <section className="section-social-link">
            <div className="inner-container">
                <div className="left-column">
                    <div className="greeting-message" >
                        <span data-aos="fade-right" data-aos-duration="1000">
                            Dental Hygiene for all
                        </span>
                    </div>
                    <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
                </div>
                <div className="right-column">
                    <div className="container-wrapper" >
                        <div className='container-wrapper-inner' data-aos="fade-up" data-aos-duration="1000" >

                            <progress id="form-step" value={progresBarValue} max={progresBarMaxValue} ></progress>
                            {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
                            <div className="sub-title">Do you have any social media profiles?</div>
                            <div className="input-container" >
                                <label htmlFor="">Facebook Link</label>
                                <div className="input-inner-container">
                                    <i class="fab fa-facebook"></i>
                                    <input type="text" placeholder='Enter your Facebook Link' value={formData.facebookLink} onChange={(e) => setFormData({ ...formData, facebookLink: e.target.value })} />
                                </div>
                            </div>
                            <div className="input-container" >
                                <label htmlFor="">Twitter Link</label>
                                <div className="input-inner-container">
                                    <i class="fab fa-twitter"></i>
                                    <input type="text" placeholder='Enter your Twitter Links' value={formData.twitterLink} onChange={(e) => setFormData({ ...formData, twitterLink: e.target.value })} />
                                </div>
                            </div>
                            <div className="input-container" >
                                <label htmlFor="">Instagram Link</label>
                                <div className="input-inner-container">
                                    <i class="fab fa-instagram"></i>
                                    <input type="text" placeholder='Enter your Instagram Links' value={formData.instagramLink} onChange={(e) => setFormData({ ...formData, instagramLink: e.target.value })} />
                                </div>
                            </div>
                            <div className="input-container other-link" >
                                <label htmlFor="">Other Social Media Link</label>
                                <div className="input-inner-container other-link">
                                    <input type="text" className='other-link' placeholder='Enter your Other Social Media Link' value={formData.otherSocialLink} onChange={(e) => setFormData({ ...formData, otherSocialLink: e.target.value })} />
                                </div>
                            </div>
                            <div className="button-container" >
                                <button className='prev disable-button' onClick={() => prevStep()}>Previous</button>
                                <div className="end">

                                    <button className='next outline-button ' onClick={() => nextSteps()}>Skip</button>
                                    <button className='next primary-button ' onClick={() => nextSteps()}>Next</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default SocialLink