import { toast } from "react-hot-toast";
import { activateSubscription, paymentStart, postChatBotConvo } from "../apiCall";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(planId) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  console.log(res)

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // creating a new order
  const result = await paymentStart({ planId });
  console.log(result);
  const order = result["order"];

  if (result.status[0].Error === "True") {
    toast.error(result.status[0].ResponseMessage);
    return;
  }

  // Getting the order details back

  console.log(order);
  const options = {
    key: order["razorpay_merchant_key"], // Enter the Key ID generated from the Dashboard
    amount: order["razorpay_amount"],
    currency: order["currency"],
    name: "Gentle Dental",
    description: "Test Transaction",
    order_id: order["razorpay_order_id"],
    handler: async function (response) {
      console.log(response);
      const data = {
        orderCreationId: order["razorpay_order_id"],
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };
      var count = 0
      const activatesub = async () => {
        count += 1
        const activate = await activateSubscription({ razorpay_order_id: data.razorpayOrderId, razorpay_payment_id: data.razorpayPaymentId })
        if (activate.status[0].Response === 200) {
          toast.success("subscription activate successfully.")
          // window.localStorage.setItem("is_subscriber",true)
          window.location.href = "/"

          // const saveHistoryAndCreateGroup = async (renderedSteps,chatSummery) => {
          //   for (let i = 0; i < renderedSteps.length; i++) {
          //     renderedSteps[i]["id"] = i + 1;
          //   }
          //   const token = localStorage.getItem("token");
          //   localStorage.setItem("is_subscriber","true")

          //   const res = await postChatBotConvo( {conversation:renderedSteps,summary:chatSummery})
          //   console.log(res)
          //   const reStatus = res["status"][0]["Error"]

          //   if (reStatus === "True") {
          //     toast.error(res["status"][0]["Message"])
          //   } else {
          //     toast.success("Appoinment created.")
          //     window.location.href = "/dashboardhome"
          //   }
          // }
          // saveHistoryAndCreateGroup(orderData.chatHistory,orderData.chatSummery)
        }
        else if (activate.status[0].Response === 400) {
          if (count === 1 | count > 4) {
            toast.error("payment not captured. wait for some time and don't reload the page")
          }
          if (count < 6) {

            setTimeout(activatesub, 1000 * (2 ** count));
          }

        }
        else {
          toast.error("Something went wrong; please reload the page.")
        }
      }
      activatesub()

      
    },
    prefill: {
      name: "Gentle Dental",
      email: "gentledental@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Gentle Dental",
    },
    theme: {
      color: "#0290DE",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}