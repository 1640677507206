import React, { useEffect, useRef, useState } from "react";
import { MdOutlineFlipCameraIos } from "react-icons/md"; // Flip camera icon
import "./ImageCapture.scss";

const ImageCapture = ({ handleImageUpload, handleClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [facingMode, setFacingMode] = useState("user"); // Default to front camera
  const [isMobile, setIsMobile] = useState(false); // State to check if device is mobile
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL("image/jpeg");
    setPhoto(dataURL);
    closeModal();
  };

  const startVideoStream = (mode) => {
    let stream;

    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: mode, // Switch between front and rear camera
        },
      })
      .then((mediaStream) => {
        stream = mediaStream;
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error("Error accessing webcam: ", err);
      });

    return stream;
  };

  useEffect(() => {
    // Detect mobile devices like iPhones or Android
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/android|iphone|ipad|ipod/.test(userAgent.toLowerCase()));
    };

    checkIfMobile(); // Call on component mount

    let stream = null;
    if (isOpen) {
      stream = startVideoStream(facingMode);
    } else {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isOpen, facingMode]);

  const flipCamera = () => {
    // Flip between front and back camera
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  return (
    <div className="modal-detail">
      <div className={`content ${isOpen ? "" : "d-none"}`}>
        <div className="video-wrapper">
          <video ref={videoRef} className="video-feed" playsInline />
          {isMobile && (
            <div className="btn">
              <MdOutlineFlipCameraIos
                onClick={flipCamera}
                size={20}
                fill="white"
              />
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <button
            className="outline-button"
            onClick={() => {
              handleClose();
              closeModal();
            }}
          >
            Close
          </button>
          <button className="primary-button" onClick={capturePhoto}>
            Capture Photo
          </button>
        </div>
      </div>

      {photo && (
        <div className="content">
          <h5>Captured Photo:</h5>
          <img src={photo} alt="Captured" />
          <div className="button-wrapper">
            <button
              className="outline-button"
              onClick={() => {
                setPhoto(null);
                openModal();
              }}
            >
              Retake Photo
            </button>
            <button
              className="primary-button"
              onClick={() => {
                handleImageUpload(photo);
                handleClose();
              }}
            >
              Upload Photo
            </button>
          </div>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default ImageCapture;
