import React, { useEffect, useRef } from "react";
import SideNav from "../component/side-nav/side-nav";
import { useState } from "react";
import "./edit-profile.scss";
import {
  changePassword,
  fetchPatientProfile,
  patientSocialLinksUpdate,
  profileImageUpdate,
  updatePatientAddress,
  updatePatientInformation,
} from "../../../apiCall";
import { toast } from "react-hot-toast";
import Ig from "../../../Assets/icons/Instagram.svg";
import Fb from "../../../Assets/icons/Facebook.svg";
import Li from "../../../Assets/icons/twitter-icon.svg";
import userProfile from "../../../Assets/image/user-profile.png";
import { useStateValue } from "../../../StateProvider";
import axios from "axios";
import { PINCODE_API } from "../../../Constants";

function EditProfile() {
  const fileInputRef = useRef();
  const [profilePic, setProfilePic] = useState("");
  const [{ isGoogleLogIn }, dispatch] = useStateValue();
  const [pincodeErr, setPincodeErr] = useState("");
  const stateRef = useRef(null);
  const cityRef = useRef(null);

  const [personalErrors, setPersonalErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [addressErrors, setAddressErrors] = useState({
    address: "",
  });

  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    birthdate: "",
    phoneNumber: "",
    gender: "",
  });

  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [addressData, setAddressData] = useState({
    city: "",
    address: "",
    postalCode: "",
    state: "",
  });

  const [socialLinks, setSocialLinks] = useState({
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    otherLink: "",
  });

  const [isEditable, setIsEditable] = useState({
    personalEdit: false,
    socialEdit: false,
    websiteEdit: false,
    passwordEdit: false,
    dentistEdit: false,
    addressEdit: false,
  });

  const [personalDetailsRes, setPersonalDetailsRes] = useState({});
  const [socialLinksRes, setSocialLinksRes] = useState({});
  const [addressDataRes, setAddressDataRes] = useState({});

  const handleChange = (e, type) => {
    let { name, value } = e.target;
    value = value.trim();
    switch (type) {
      case "personal":
        console.log(name);
        if (name === "birthdate") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              name: "birthdate is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
        }
        if (name === "gender") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              name: "gender is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
        }
        if (name === "name") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({ ...personalErrors, name: "Name is required" });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
          if (/^[^-\s][a-zA-Z0-9_\s-]+$/.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }

        if (name === "email") {
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              email: "Email is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          if (!/^[\w.]+@([\w-]+\.)+[\w-]{2,4}/.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({
              ...personalErrors,
              email: "Email is not valid",
            });
          } else {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({ ...personalErrors, email: "" });
          }
        }

        if (name === "phoneNumber") {
          const mobile_number_regex = /^[6-9]\d{9}$/i;
          if (value.length === 0) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
            return setPersonalErrors({
              ...personalErrors,
              phoneNumber: "Phone number is required",
            });
          }
          if (!mobile_number_regex.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({
              ...personalErrors,
              phoneNumber: "Phone number is not valid",
            });
          } else {
            setPersonalErrors({ ...personalErrors, phoneNumber: "" });
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }

        break;

      case "social":
        setSocialLinks((prevData) => ({ ...prevData, [name]: value }));
        break;

      case "password":
        const pass_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}/;
        if (name === "oldPassword") {
          if (value.length === 0) {
            setPasswordErrors({
              ...passwordErrors,
              oldPassword: "Old password is required",
            });
            return setPasswordDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }

          if (value.length < 8 || value.length > 16) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            return setPasswordErrors({
              ...passwordErrors,
              oldPassword:
                "Old password must have at least 8 characters and maximum 16 characters",
            });
          } else {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({ ...passwordErrors, oldPassword: "" });
          }
        }

        if (name === "newPassword") {
          if (value.length === 0) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: "" }));
            return setPasswordErrors({
              ...passwordErrors,
              newPassword: "New password is required",
            });
          }
          if (pass_regex.test(value)) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({ ...passwordErrors, newPassword: "" });
          } else {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({
              ...passwordErrors,
              newPassword:
                "Password must contain uppercases, lowercases, numbers and special characters",
            });
          }
        }

        if (name === "confirmPassword") {
          if (value.length === 0) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: "" }));
            return setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "Confirm password is required",
            });
          }
          setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
          if (passwordDetails.newPassword !== value) {
            setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "Password does not match",
            });
          } else {
            setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "",
            });
          }
        }

        break;

      case "address":
        if (name === "state") {
          setAddressData((prevData) => ({ ...prevData, [name]: value }));
          if (value === "select state") {
            return setAddressErrors({
              ...addressErrors,
              state: "State is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            state: "",
          });
        }
        if (name === "city") {
          setAddressData((prevData) => ({ ...prevData, [name]: value }));
          if (value === "select city") {
            return setAddressErrors({
              ...addressErrors,
              city: "City is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            city: "",
          });
        }
        if (name === "address") {
          setAddressData((prevData) => ({ ...prevData, [name]: value }));
          if (value.length === 0) {
            return setAddressErrors({
              ...addressErrors,
              address: "Address is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            address: "",
          });
        }
        break;

      default:
        break;
    }
  };

  // const [errorMessage, setErrorMessage] = useState("");
  // ************ Integration ***************

  const token = window.localStorage.getItem("token");

  //HARDCODED TOKEN VALUE --- JUST FOR TESTING UI ----

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.error("Token not found in session storage");
          return;
        }
        dispatch({ type: "SET_LOADING", status: true });
        const response = await fetchPatientProfile(token);
        dispatch({ type: "SET_LOADING", status: false });
        setPersonalDetails({
          name: response.data.FullName,
          birthdate: response.data.birthdate,
          email: response.data.email,
          gender: response.data.gender,
          phoneNumber: response.data.phoneNumber,
        });

        setAddressData({
          address: response.data.address,
          city: response.data.city,
          state: response.data.state,
          postalCode: response.data.postalCode,
        });

        const stateElement = stateRef.current;
        const cityElement = cityRef.current;
        while (stateElement.options.length > 0) {
          stateElement.remove(0);
        }
        while (cityElement.options.length > 0) {
          cityElement.remove(0);
        }
        const newStateOption = new Option("select state", "select state");
        const newCityOption = new Option("select city", "select city");
        stateElement.add(newStateOption);
        cityElement.add(newCityOption);
        try {
          const re = await axios.get(
            `https://api.data.gov.in/resource/9115b89c-7a80-4f54-9b06-21086e0f0bd7?api-key=${PINCODE_API}&format=json&limit=600&filters%5Bpincode%5D=${response.data.postalCode}`
          );
          if (re.status === 200 && re?.data?.records.length > 0) {
            const option_list = re?.data?.records;
            var stateArray = [];
            var cityArray = [];
            function isInArray(value, array) {
              return array.indexOf(value) !== -1;
            }

            option_list.forEach(function(data) {
              if (!isInArray(data["statename"].toLowerCase(), stateArray)) {
                stateArray.push(data["statename"].toLowerCase());
              }
              if (
                !isInArray(
                  data["village_locality_name"].toLowerCase(),
                  cityArray
                )
              ) {
                cityArray.push(data["village_locality_name"].toLowerCase());
              }
            });

            while (stateElement.options.length > 0) {
              stateElement.remove(0);
            }
            while (cityElement.options.length > 0) {
              cityElement.remove(0);
            }
            stateArray.forEach((option) => {
              const newOption = new Option(option, option);
              stateElement.add(newOption);
            });
            cityArray.forEach((option) => {
              const newOption = new Option(option, option);
              cityElement.add(newOption);
            });
          } else {
            while (stateElement.options.length > 0) {
              stateElement.remove(0);
            }
            while (cityElement.options.length > 0) {
              cityElement.remove(0);
            }
            const newStateOption = new Option(
              "select pincode first",
              "select pincode first"
            );
            const newCityOption = new Option(
              "select pincode first",
              "select pincode first"
            );
            stateElement.add(newStateOption);
            cityElement.add(newCityOption);
          }
        } catch (err) {
          console.log(err);
        }

        setSocialLinks({
          facebookLink: response.data.facebookLink,
          twitterLink: response.data.twitterLink,
          instagramLink: response.data.instagramLink,
          otherLink: response.data.otherLink,
        });

        setPersonalDetailsRes({
          name: response.data.FullName,
          email: response.data.email,
          birthdate: response.data.birthdate,
          phoneNumber: response.data.phoneNumber,
          gender: response.data.gender,
        });
        setSocialLinksRes({
          facebookLink: response.data.facebookLink,
          twitterLink: response.data.twitterLink,
          instagramLink: response.data.instagramLink,
          otherLink: response.data.otherLink,
        });

        setAddressDataRes({
          address: response.data.address,
          city: response.data.city,
          state: response.data.state,
          postalCode: response.data.postalCode,
        });

        setProfilePic(response.data.profileImage);

        const reStatus = response["status"][0]["Error"];

        if (reStatus === "True") {
          // toast.error(response["status"][0]["Message"]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [token]);

  // handle submits
  const handleSubmit = (e, type) => {
    e.preventDefault();

    const submitData = async () => {
      let res = null,
        reStatus = null;
      switch (type) {
        case "personal":
          dispatch({ type: "SET_LOADING", status: true });
          res = await updatePatientInformation(token, personalDetails);
          dispatch({ type: "SET_LOADING", status: false });

          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setPersonalDetails(personalDetailsRes);
          } else {
            toast.success("Personal Details Updated.");
            setPersonalDetails(res.data);
            setPersonalDetailsRes(res.data);
            setIsEditable({ ...isEditable, personalEdit: false });
            window.localStorage.setItem("token", res?.access_token);
            window.localStorage.setItem("phoneNumber", res?.data?.phoneNumber);
            window.localStorage.setItem("name", res?.data?.name);
            window.localStorage.setItem("email", res?.data?.email);

            // window.localStorage.clear()
          }
          break;

        case "social":
          dispatch({ type: "SET_LOADING", status: true });
          res = await patientSocialLinksUpdate(socialLinks, token);
          dispatch({ type: "SET_LOADING", status: false });
          setIsEditable({ ...isEditable, socialEdit: false });
          if (res.data.status[0].Response === 200) {
            setIsEditable({ ...isEditable, socialEdit: false });
            setSocialLinksRes(socialLinks);
            toast.success("Social links successfully updated");
          } else {
            setSocialLinks(socialLinks);
            toast.error("Something went wrong");
          }
          break;

        case "password":
          if (passwordDetails.newPassword === passwordDetails.confirmPassword) {
            dispatch({ type: "SET_LOADING", status: true });
            res = await changePassword(token, {
              oldPassword: passwordDetails.oldPassword,
              newPassword: passwordDetails.newPassword,
            });
            dispatch({ type: "SET_LOADING", status: false });
            console.log(res);
            reStatus = res["status"][0]["Error"];

            if (reStatus === "True") {
              toast.error(res["status"][0]["Message"]);
            } else {
              toast.success("Password changed successfully");
            }
            setPasswordDetails({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            setIsEditable({ ...isEditable, passwordEdit: false });
          } else {
            toast.error("Re-entered password doesn't match");
          }
          break;

        case "address":
          dispatch({ type: "SET_LOADING", status: true });
          console.log(addressData, "ADDRESS");
          res = await updatePatientAddress(token, addressData);
          dispatch({ type: "SET_LOADING", status: false });
          console.log(res, "RESPONSE");
          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setAddressData(addressDataRes);
          } else {
            toast.success("Address Updated.");
            setIsEditable({ ...isEditable, addressEdit: false });
            setAddressData(addressData);
            setAddressDataRes(addressData);
          }
          break;

        default:
          break;
      }
    };
    submitData();
  };

  // ******************** File Upload *********************

  const handleImageChange = (file) => {
    if (file.target.files && file.target.files[0]) {
      if (
        file.target.files[0].type === "image/x-png" ||
        file.target.files[0].type === "image/png" ||
        file.target.files[0].type === "image/jpeg" ||
        file.target.files[0].type === "image/jpg"
      ) {
        handleFileUpload(file.target.files[0]);
      } else {
        toast.error("Select an image file");
      }
    }
  };

  const handleFileUpload = async (img) => {
    const formData = new FormData();
    formData.append("image", img);
    dispatch({ type: "SET_LOADING", status: true });
    const res = await profileImageUpdate(formData);
    dispatch({ type: "SET_LOADING", status: false });
    const reStatus = res["data"]["status"][0]["Error"];
    if (reStatus === "True") {
      toast.error(res["status"][0]["Message"]);
    } else {
      window.localStorage.setItem("profile", res.data.data.profile);
      toast.success("Profile picture updated");
      setProfilePic(URL.createObjectURL(fileInputRef.current.files[0]));
    }
  };

  // Add value to state and city by checking the pincode is valid or not
  const checkPincode = async (e) => {
    const stateElement = stateRef.current;
    const cityElement = cityRef.current;
    while (stateElement.options.length > 0) {
      stateElement.remove(0);
    }
    while (cityElement.options.length > 0) {
      cityElement.remove(0);
    }
    const newStateOption = new Option("select state", "select state");
    const newCityOption = new Option("select city", "select city");
    stateElement.add(newStateOption);
    cityElement.add(newCityOption);
    setAddressData({
      ...addressData,
      postalCode: e.target.value,
      city: "",
      state: "",
    });
    if (e.target.value.length < 1) {
      return setPincodeErr("Pincode is required");
    }
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(e.target.value)) {
      setPincodeErr("Pincode is invalid");
      return setAddressData({
        ...addressData,
        city: "",
        state: "",
        postalCode: e.target.value,
      });
    } else {
      setPincodeErr("");
      try {
        const response = await axios.get(
          `https://api.data.gov.in/resource/9115b89c-7a80-4f54-9b06-21086e0f0bd7?api-key=${PINCODE_API}&format=json&limit=600&filters%5Bpincode%5D=${e.target.value}`
        );
        if (response.status === 200 && response?.data?.records.length > 0) {
          const option_list = response?.data?.records;

          setAddressData({
            ...addressData,
            tate: response.data.records[0].statename.toLowerCase(),
            city: response.data.records[0].village_locality_name.toLowerCase(),
            postalCode: e.target.value,
          });
          var stateArray = [];
          var cityArray = [];
          function isInArray(value, array) {
            return array.indexOf(value) !== -1;
          }

          option_list.forEach(function(data) {
            if (!isInArray(data["statename"].toLowerCase(), stateArray)) {
              stateArray.push(data["statename"].toLowerCase());
            }
            if (
              !isInArray(data["village_locality_name"].toLowerCase(), cityArray)
            ) {
              cityArray.push(data["village_locality_name"].toLowerCase());
            }
          });
          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          stateArray.forEach((option) => {
            const newOption = new Option(option, option);
            stateElement.add(newOption);
          });
          cityArray.forEach((option) => {
            const newOption = new Option(option, option);
            cityElement.add(newOption);
          });

          return setPincodeErr("");
        } else {
          setAddressData({
            ...addressData,
            postalCode: e.target.value,
            city: "",
            state: "",
          });
          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          const newStateOption = new Option("select state", "select state");
          const newCityOption = new Option("select city", "select city");
          stateElement.add(newStateOption);
          cityElement.add(newCityOption);

          return setPincodeErr("Pincode is invalid");
        }
      } catch (err) {}
    }
  };

  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        {/* {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>} */}
        <div className="outer-row-dash">
          <div className="display-flex first-div">
            <h3 className="edit-profile">Edit Profile</h3>
          </div>
          <div className="row-dash">
            <div
              className="col-1-dash"
              onClick={() => fileInputRef.current.click()}
            >
              <img
                src={profilePic ? profilePic : userProfile}
                alt=""
                className="img-tag"
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
                accept="image/x-png,image/png,image/jpeg"
              />
            </div>
            <div className="col-2-dash">
              <div className="form">
                <div className="display-flex">
                  <h4 className="personal-info">Personal Information</h4>
                  <h5
                    className="Edit-btn"
                    onClick={() => {
                      if (isEditable.personalEdit) {
                        setPersonalDetails(personalDetailsRes);
                        setPersonalErrors({
                          phoneNumber: "",
                          name: "",
                          email: "",
                        });
                      }
                      setIsEditable((prev) => {
                        return { ...prev, personalEdit: !prev.personalEdit };
                      });
                    }}
                  >
                    {isEditable.personalEdit ? "Cancel" : "Edit"}
                  </h5>
                </div>
                <form>
                  <div className="display-flex">
                    <div>
                      <label>Name:</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={personalDetails.name}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit}
                        required
                      />
                      <small className="error">{personalErrors.name}</small>
                    </div>
                    <div>
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={personalDetails.email}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit || isGoogleLogIn}
                      />
                      <small className="error">{personalErrors.email}</small>
                    </div>
                  </div>
                  <div className="display-flex">
                    <div>
                      <label>Birthdate:</label>
                      <input
                        name="birthdate"
                        value={personalDetails.birthdate}
                        className="input"
                        id="birthdate"
                        type="date"
                        disabled={!isEditable.personalEdit}
                        onChange={(e) => handleChange(e, "personal")}
                      />
                    </div>
                    <div>
                      <div>
                        <label>Phone Number:</label>
                        <input
                          type="tel"
                          name="phoneNumber"
                          placeholder="Enter your phone number"
                          value={personalDetails.phoneNumber}
                          onChange={(e) => handleChange(e, "personal")}
                          disabled={!isEditable.personalEdit}
                        />
                        <small className="error">
                          {personalErrors.phoneNumber}
                        </small>
                      </div>
                      <div>
                        <label>Gender:</label>
                        <select
                          className="inp-1 select-inp"
                          name="gender"
                          value={personalDetails.gender}
                          onChange={(e) => handleChange(e, "personal")}
                          disabled={!isEditable.personalEdit}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          {/* <option value="other">Other</option> */}
                        </select>
                      </div>
                      {isEditable.personalEdit &&
                        !personalErrors.name &&
                        !personalErrors.birthdate &&
                        !personalErrors.email &&
                        !personalErrors.phoneNumber && (
                          <button
                            className="save"
                            onClick={(e) => handleSubmit(e, "personal")}
                          >
                            Save
                          </button>
                        )}
                    </div>
                  </div>
                </form>

                {!isGoogleLogIn && (
                  <div className="div-001">
                    <hr className="line" />
                    <div className="display-flex">
                      <h4 className="personal-info">Password</h4>
                      <h5
                        className="Edit-btn"
                        onClick={() => {
                          if (isEditable.passwordEdit) {
                            setPasswordDetails({
                              oldPassword: "",
                              newPassword: "",
                              confirmPassword: "",
                            });
                            setPasswordErrors({
                              confirmPassword: "",
                              newPassword: "",
                              oldPassword: "",
                            });
                          }
                          setIsEditable((prev) => {
                            return {
                              ...prev,
                              passwordEdit: !prev.passwordEdit,
                            };
                          });
                        }}
                      >
                        {isEditable.passwordEdit ? "Cancel" : "Edit"}
                      </h5>
                    </div>
                    <form>
                      <label>Password:</label>
                      <input
                        type="password"
                        name="oldPassword"
                        placeholder="Enter your old password"
                        value={passwordDetails.oldPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.oldPassword}
                      </small>
                      <input
                        type="password"
                        name="newPassword"
                        placeholder="Enter your new password"
                        value={passwordDetails.newPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.newPassword}
                      </small>
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        value={passwordDetails.confirmPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.confirmPassword}
                      </small>
                      {isEditable.passwordEdit &&
                        !passwordErrors.oldPassword &&
                        !passwordErrors.newPassword &&
                        !passwordErrors.confirmPassword && (
                          <button
                            className="save"
                            onClick={(e) => handleSubmit(e, "password")}
                            disabled={
                              !passwordDetails.confirmPassword &&
                              !passwordDetails.newPassword &&
                              !passwordDetails.oldPassword
                            }
                          >
                            Save
                          </button>
                        )}
                    </form>
                  </div>
                )}

                {/* //********************** Social Media Links ******************************** */}

                <div className="div-001">
                  <hr className="line" />
                  <div className="display-flex">
                    <h4>Social Media Profiles</h4>
                    <h5
                      className="Edit-btn"
                      onClick={() => {
                        if (isEditable.socialEdit) {
                          setSocialLinks(socialLinksRes);
                        }
                        setIsEditable((prev) => {
                          return { ...prev, socialEdit: !prev.socialEdit };
                        });
                      }}
                    >
                      {isEditable.socialEdit ? "Cancel" : "Edit"}
                    </h5>
                  </div>
                  <label>Facebook:</label>
                  <div className="input-wrap">
                    <img src={Fb} alt="" />
                    <input
                      type="text"
                      name="facebookLink"
                      placeholder="Enter Facebook link"
                      className="no-style"
                      value={socialLinks.facebookLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />
                  </div>

                  <label>Twitter:</label>
                  <div className="input-wrap">
                    <img src={Li} alt="" />
                    <input
                      type="text"
                      name="twitterLink"
                      placeholder="Enter Twitter link"
                      className="no-style"
                      value={socialLinks.twitterLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />
                  </div>

                  <label>Instagram:</label>
                  <div className="input-wrap">
                    <img src={Ig} alt="" />
                    <input
                      type="text"
                      name="instagramLink"
                      placeholder="Enter Instagram link"
                      value={socialLinks.instagramLink}
                      className="no-style"
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />
                  </div>

                  <label>Other:</label>
                  <div className="input-wrap">
                    <input
                      type="text"
                      name="otherLink"
                      className="no-style"
                      placeholder="Enter any other link"
                      value={socialLinks.otherLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />
                  </div>
                  {isEditable.socialEdit && (
                    <button
                      className="save"
                      onClick={(e) => handleSubmit(e, "social")}
                    >
                      Save
                    </button>
                  )}
                </div>

                <div className="div-001">
                  <hr className="line" />
                  <div className="display-flex">
                    <h4 className="personal-info">Address</h4>
                    <h5
                      className="Edit-btn"
                      onClick={() => {
                        if (isEditable.addressEdit) {
                          setAddressData(addressDataRes);
                          setAddressErrors({
                            address: "",
                          });
                          setPincodeErr("");
                        }
                        setIsEditable((prev) => {
                          return {
                            ...prev,
                            addressEdit: !prev.addressEdit,
                          };
                        });
                      }}
                    >
                      {isEditable.addressEdit ? "Cancel" : "Edit"}
                    </h5>
                  </div>

                  <form>
                    <label>Address:</label>
                    <textarea
                      type="text"
                      name="address"
                      placeholder="Enter your address"
                      value={addressData.address}
                      onChange={(e) => handleChange(e, "address")}
                      disabled={!isEditable.addressEdit}
                    />
                    <small className="error">{addressErrors.address}</small>
                    <div>
                      <input
                        className="inp-1"
                        type="text"
                        name="postalCode"
                        id="postalCode"
                        placeholder="Enter postal code"
                        value={addressData.postalCode}
                        onChange={checkPincode}
                        disabled={!isEditable.addressEdit}
                      />
                      <div>
                        <small className="error">{pincodeErr}</small>
                      </div>
                    </div>
                    <div className="display-flex">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="state"
                        value={addressData.state}
                        ref={stateRef}
                        onChange={(e) => {
                          handleChange(e, "address");
                        }}
                        disabled={!isEditable.addressEdit}
                      >
                        <option selected value="select state">
                          select state
                        </option>
                      </select>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="city"
                        ref={cityRef}
                        value={addressData.city}
                        onChange={(e) => {
                          handleChange(e, "address");
                        }}
                        disabled={!isEditable.addressEdit}
                      >
                        <option selected value="select city">
                          select city
                        </option>
                      </select>
                    </div>

                    {isEditable.addressEdit && addressData.address && (
                      <button
                        className="save"
                        onClick={(e) => handleSubmit(e, "address")}
                        disabled={pincodeErr}
                      >
                        Save
                      </button>
                    )}
                  </form>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditProfile;
