import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import "./calendar.scss";
import { useEffect, useState } from "react";
import { fetchAppointment } from "../../apiCall";
import { useQuery } from "react-query";
import SideNav from "../Dashboard/component/side-nav/side-nav";
import { AppointmentDetailModal } from "../../components/modal/modal";


const convertToEvent = (appointmentData) => {
  const events = [];
  const appoinment = appointmentData?.data ? appointmentData.data : [];
  for (let i = 0; i < appoinment.length; i++) {
    const [year, month, day] = appoinment[i]?.date.split("-");
    const [startTimeHours, startTimeMinutes, startTimeSecond] = appoinment[
      i
    ]?.startTime.split(":");
    const [endTimeHours, endTimeMinutes, endTimeSecond] = appoinment[
      i
    ]?.endTime.split(":");
    const startDateTime = new Date(
      year,
      month - 1,
      day,
      startTimeHours,
      startTimeMinutes
    );
    const endDateTime = new Date(
      year,
      month - 1,
      day,
      endTimeHours,
      endTimeMinutes
    );
    events.push({
      title: appoinment[i].name,
      appointmentDetail: appoinment[i],
      
      start: startDateTime,
      end: endDateTime,
    });
  }
  return events;
};
export default function MyCalendar() {
  const token = window.localStorage.getItem("token");
  const [appointmentModalShow, setAppointmentModalShow] = useState(false);
  const [selectedappointment, setSelectedappointment] = useState("");

  const { isLoading, data } = useQuery({
    queryKey: "appointment-calender",
    queryFn: () => fetchAppointment(token),
  });
  const [events, setEvents] = useState(convertToEvent(data));
  useEffect(() => {
    setEvents(convertToEvent(data));
  }, [data]);
  return (
    <div className="outer-div bgc-white">
      <SideNav />
      <div className="calendar-container">
        <FullCalendar
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          weekends={true}
          events={{
            events,
            // textColor: "#000000",
            // color: "#000000",
            eventTimeFormat: {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            },
          }}
          eventContent={(event)=>renderEventContent(event,setAppointmentModalShow,setSelectedappointment)}
        />
      </div>
      <AppointmentDetailModal
        show={appointmentModalShow}
        appointment={selectedappointment}
        handleClose={() => setAppointmentModalShow(false)}
      />
    </div>
  );
}

// a custom render function
function renderEventContent(eventInfo,setAppointmentModalShow,setSelectedappointment) {
  return (
    <div onClick={()=>{setAppointmentModalShow(true);setSelectedappointment(eventInfo.event.extendedProps.appointmentDetail)}}>
      <b className="event-time">{eventInfo.timeText}</b><br/>
      <i>{eventInfo.event.title}</i>
    </div>
  );
}
