import React from "react";
import "./AppointmentDetail.scss";
import linkdin from "../../Assets/icons/Linkdin.svg";
import facebook from "../../Assets/icons/Facebook.svg";
import instagram from "../../Assets/icons/Instagram.svg";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const AppointmentDetail = ({ appointment, handleClose }) => {
  console.log(appointment);
  return (
    <div className="appointment-detail-modal">
      <div className="heading">Appointment Details </div>
      <div className="profile-img">
        <img src={appointment?.profile_image} alt="" />
      </div>
      <div className="detail">
        <div className="title">{appointment?.name}</div>
        <div className="content">
          {appointment.facebookLink && 
            <Link to={`${appointment.facebookLink}`}>
                <img src={facebook} alt="" />
            </Link>
          }
          {appointment.instagramLink && 
            <Link to={`${appointment.instagramLink}`}>
              <img src={instagram} alt="" />
            </Link>
          }
          {appointment.twitterLink && 
            <Link to={`${appointment.twitterLink}`}>
              <FaTwitter size={20} />
            </Link>
          }
        </div>
      </div>
      <hr />
      <div className="detail">
        <div className="title">When</div>
        <div className="content">
          Day:{appointment?.date}
          <br />
          Time: {appointment?.startTime} - {appointment?.endTime}
        </div>
      </div>
      <hr />

      <div className="detail">
        <div className="title">Where</div>
        <div className="content">{appointment?.city}</div>
      </div>
      <hr />

      <div className="detail">
        <div className="title">Email</div>
        <div className="content">{appointment?.email}</div>
      </div>
      <hr />

      <div className="detail">
        <div className="title">Phone Number</div>
        <div className="content">{appointment?.phoneNumber}</div>
      </div>
      <hr />
    </div>
  );
};

export default AppointmentDetail;
