import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home/Home";
import Header from "./views/Header/Header";
import DashboardHeader from "./views/Header/DashboardHeader";
import Footer from "./views/Footer/Footer";
import Signup from "./views/Signup/Signup";
import Signin from "./views/Signin/Signin";
import DashboardBookAppo from "./views/DashboardHome/DashboardBookAppo";
import Patient from "./views/Patient/Patient";
import DashboardHomeComponent from "./views/Dashboard/home/home";
import SharedFiles from "./views/Dashboard/shared-files/shared-files";
import YourVisits from "./views/Dashboard/your-visits/your-visits";
import EditProfile from "./views/Dashboard/edit-profile/edit-profile";
import MyChatBot from "./views/Chat-Bot/ChatBot";
import { QueryClient, QueryClientProvider } from "react-query";
import { useStateValue } from "./StateProvider";
import DashboardSideHeader from "./views/Header/DashboardSideHeader";
import { Toaster } from "react-hot-toast";
import DashboardDentistHomeComponent from "./views/Dashboard/dentist-home/dentist-home";
import DentistEditProfile from "./views/Dashboard/dentist-edit-profile/dentist-edit-profile";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { Spinner } from "./components/Spinner/Spinner";
import ProtectedRoute from "./Utils/Protected-routes";
import MyCalendar from "./views/Calendar/Calendar";
import EmailVerify from "./views/EmailVerify/EmailVerify";
import { googleClinetId } from "./Constants";
import GoogleSignupForm from "./views/GoogleSignupForm/GoogleSignupForm";
import AboutUs from "./views/AboutUs/AboutUs";
import Faqs from "./views/Faqs/Faqs";
import ContactUs from "./views/ContactUs/ContactUs";
import Membership from "./views/Dashboard/Membership/Membership";
import MembershipPlan from "./views/MembershipPlan/MembershipPlan";
import { fetchAppointment } from "./apiCall";
import ShippingDelivery from "./views/ShippingDelivery/ShippingDelivery";
import TermsCondition from "./views/TermsCondition/TermsCondition";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import ReturnRefund from "./views/ReturnRefund/ReturnRefund";

function App() {
  const queryClient = new QueryClient();
  const [{ isLoading, isLoggedIn }, dispatch] = useStateValue();
  useEffect(() => {
    const getData = async () => {
      if (isLoggedIn) {
        const re = await fetchAppointment();
        console.log(re);
        if (re?.detail === "earer token missing or unknown") {
          localStorage.clear();
          dispatch({
            type: "LOGIN",
            status: false,
          });
        }
      }
    };
    getData();
  }, []);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // return () => {
    // };
  }, [isLoading]);

  return (
    <GoogleOAuthProvider clientId={googleClinetId}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <div className="app">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header /> <Home /> <Footer />
                  </>
                }
              />
              <Route
                path="/about-us"
                element={
                  <>
                    <Header /> <AboutUs /> <Footer />
                  </>
                }
              />
              <Route
                path="/faqs"
                element={
                  <>
                    <Header /> <Faqs /> <Footer />
                  </>
                }
              />
              <Route
                path="/shipping-delivery"
                element={
                  <>
                    <Header /> <ShippingDelivery /> <Footer />
                  </>
                }
              />
              <Route
                path="/terms-condition"
                element={
                  <>
                    <Header /> <TermsCondition /> <Footer />
                  </>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <>
                    <Header /> <PrivacyPolicy /> <Footer />
                  </>
                }
              />
              <Route
                path="/return-refund"
                element={
                  <>
                    <Header /> <ReturnRefund /> <Footer />
                  </>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <>
                    <Header /> <ContactUs /> <Footer />
                  </>
                }
              />
              <Route
                path="/membership-plan"
                element={
                  <>
                    <DashboardSideHeader /> <MembershipPlan />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/chat-bot"
                element={
                  <>
                    <ProtectedRoute>
                      <Header /> <MyChatBot />
                    </ProtectedRoute>
                  </>
                }
              />
              {/* <Route path="/dashboard-home" element={<><DashboardHeader /> <DashboardHome /></>} /> */}
              <Route
                path="/book-appoinment"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardHeader /> <DashboardBookAppo />
                    </ProtectedRoute>
                  </>
                }
              />
              <Route
                path="/sign-up"
                element={
                  <ProtectedRoute>
                    <Header />
                    <Signup />
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/google/sign-up-form"
                element={
                  <ProtectedRoute>
                    <Header />
                    <GoogleSignupForm />
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sign-in"
                element={
                  <>
                    <ProtectedRoute>
                      <Header /> <Signin /> <Footer />
                    </ProtectedRoute>
                  </>
                }
              />
              <Route
                path="/your-visits"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <YourVisits />
                    </ProtectedRoute>{" "}
                  </>
                }
              />
              <Route
                path="/edit-profile"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <EditProfile />
                    </ProtectedRoute>{" "}
                  </>
                }
              />
              <Route
                path="/dashboardhome"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <DashboardHomeComponent />
                    </ProtectedRoute>{" "}
                  </>
                }
              />
              <Route
                path="/membership"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <Membership />
                    </ProtectedRoute>{" "}
                  </>
                }
              />

              <Route
                path="/doctor-edit-profile"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <DentistEditProfile />
                    </ProtectedRoute>{" "}
                  </>
                }
              />
              {/* <Route path="/dashboard-dentist-home" element={<>
                  <DashboardSideHeader /> <DashboardDentistHomeComponent />{" "}</>}/> */}
              <Route
                path="/chat"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <Patient />
                    </ProtectedRoute>
                  </>
                }
              />

              <Route
                path="/sharedfiles"
                element={
                  <>
                    <DashboardSideHeader /> <SharedFiles />{" "}
                  </>
                }
              />

              <Route
                path="/doctor-dashboard-home"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader /> <DashboardDentistHomeComponent />
                    </ProtectedRoute>{" "}
                  </>
                }
              />
              <Route
                path="/calendar"
                element={
                  <>
                    <ProtectedRoute>
                      <DashboardSideHeader />
                      <MyCalendar />
                      {/* <Footer /> */}
                    </ProtectedRoute>
                  </>
                }
              />
              <Route
                path="/email-verify/:token"
                element={
                  <>
                    <Header />
                    <EmailVerify />
                    <Footer />
                  </>
                }
              />
            </Routes>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          {isLoading && <Spinner />}
        </Router>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
