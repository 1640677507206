import React, { useEffect } from "react";
import "./TermsCondition.scss";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="terms-condition">
      <div className="title">Terms & Condition</div>
      <div className="sub-title">
        <span>
          While GentleDental.ai is a conceptual digital dental care solution
          focusing on leveraging AI for improved dental health outcomes, it's
          essential for any digital health platform or product, such as a
          hypothetical Gentle AI Smart Toothbrush, to have clear Terms &
          Conditions (T&C) to ensure users understand their rights and
          obligations. Below is an illustrative example of what the Terms &
          Conditions might cover:
        </span>
        <strong>
          Terms & Conditions for GentleDental.ai and the Gentle AI Smart
          Toothbrush
        </strong>
      </div>
      <div className="contant">
        <div className="list">
          <span>
            <strong>1. Acceptance of Terms: </strong>
            By using the GentleDental.ai platform or the Gentle AI Smart
            Toothbrush, you agree to be bound by these Terms & Conditions and
            our Privacy Policy. If you do not agree to these terms, please do
            not use our services or products.
          </span>
          <span>
            <strong>2. User Eligibility: </strong>
            Services and products are intended for use by individuals 18 years
            of age and older. Users under 18 should have parental or guardian
            consent.
          </span>
          <span>
            <strong>3. Account Registration and Security: </strong>
            Users may be required to register for an account to access certain
            features. You agree to provide accurate information and keep your
            account secure from unauthorized use.
          </span>
          <span>
            <strong>4. Intellectual Property Rights: </strong>
            All content, including text, graphics, and software, provided by
            GentleDental.ai is owned by or licensed to us and is protected by
            copyright and other intellectual property laws. You may not use any
            content without permission.
          </span>
          <span>
            <strong>5. Use of Services and Products: </strong>
            GentleDental.ai services and the Gentle AI Smart Toothbrush are
            provided for your personal, non-commercial use. You agree to use
            these products and services according to the instructions and not
            for any unlawful purpose.
          </span>
          <span>
            <strong>6. Privacy:</strong>
            Your privacy is important to us. Our Privacy Policy outlines how we
            collect, use, and protect your information.
          </span>
          <span>
            <strong>7. Limitation of Liability: </strong>
            GentleDental.ai and its affiliates will not be liable for any
            direct, indirect, incidental, or consequential damages arising out
            of your use of the services or products.
          </span>
          <span>
            <strong>8. Modifications to Terms: </strong>
            GentleDental.ai reserves the right to modify these Terms &
            Conditions at any time. Your continued use of the services or
            products after any changes indicates your acceptance of the new
            terms.
          </span>
          <span>
            <strong>9. Health-Related Information: </strong>
            Information provided through GentleDental.ai or by using the Gentle
            AI Smart Toothbrush is for educational purposes only and is not a
            substitute for professional medical advice, diagnosis, or treatment.
          </span>
          <span>
            <strong>10. Product Use and Safety: </strong>
            The Gentle AI Smart Toothbrush is designed to enhance your dental
            care routine. Always follow the product instructions and consult
            with a dental professional if you have concerns about your oral
            health.
          </span>
          <span>
            <strong>11. Cancellation and Refund Policy: </strong>
            Details regarding the cancellation of services or return/refund of
            products will be provided at the time of purchase.
          </span>
          <span>
            <strong>12. Governing Law: </strong>
            These Terms & Conditions shall be governed by the laws of the
            jurisdiction in which GentleDental.ai operates, without regard to
            its conflict of law provisions.
          </span>
        </div>

        <span>
        <h5>Contact Information:</h5>
          For any questions or concerns regarding these Terms & Conditions,
          please contact us at support@gentledental.ai
        </span>
      </div>
    </div>
  );
};

export default TermsCondition;
