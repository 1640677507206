import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import linkdin from "../../Assets/icons/Linkdin.svg";
import facebook from "../../Assets/icons/Facebook.svg";
import instagram from "../../Assets/icons/Instagram.svg";
import google from "../../Assets/icons/Google-+.svg";
import vector from "../../Assets/icons/@.svg";
import logo from "../../Assets/image/logo.png";


const Footer = () => {
  return (
    <section className="section-footer">
      <div className="comman-container">
        <div className="inner-container">
          <div className="content-wrapper">
            <div className="link">
              <ul>
                <Link to="/about-us">
                  <li>About us</li>
                </Link>
                <Link to="/membership-plan">
                  {" "}
                  <li> Membership Plan</li>{" "}
                </Link>

                <Link to="/contact-us">
                  {" "}
                  <li>Contact Us</li>{" "}
                </Link>
                <Link to="/faqs">
                  {" "}
                  <li>FAQ’s</li>{" "}
                </Link>
                <Link to="/shipping-delivery">
                  {" "}
                  <li>Shipping & Delivery</li>{" "}
                </Link>
                <Link to="/return-refund">
                  {" "}
                  <li>Return & Refund</li>{" "}
                </Link>
                <Link to="/terms-condition">
                  <li>Terms & Conditions</li>
                </Link>

                <Link to="/privacy-policy">
                  <li>Privacy Policy</li>
                </Link>
              </ul>
            </div>
            <div className="company-info">
              <div className="heading" >
                <img src={logo} alt="" />
              </div>
              {/* <div className="content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div> */}
              <div className="input-container">
                <div className="email-input-wrapper">
                  <input type="email" placeholder="Your Email" />
                  <img src={vector} alt="" />
                </div>

                <button>Subscribe</button>
              </div>
              <div className="social-link">
                <img src={linkdin} alt="" />
                <img src={facebook} alt="" />
                <img src={google} alt="" />
                <img src={instagram} alt="" />
              </div>
              <div className="toll-free-number">Toll Free : 18XX-XXX-XXXX</div>
            </div>
          </div>
          <div className="terms">
            <div className="terms-wrapper">
              Copyright @2024 All Rights Reserved - NEORDENT
              HEALTHCARE SOLUTIONS PVT LTD
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
