import React, { useState } from "react";
import "./Signup.scss";

import ChooseOne from "./SignupComponent/ChooseOne/ChooseOne";
import Name from "./SignupComponent/Name/Name";
import RegistreationNo from "./SignupComponent/RegistreationNo/RegistreationNo";
import PhoneNumber from "./SignupComponent/PhoneNumber/PhoneNumber";
import Email from "./SignupComponent/Email/Email";
import Password from "./SignupComponent/Password/Password";
import Birthdate from "./SignupComponent/Birthdate/Birthdate";
import Experience from "./SignupComponent/Experience/Experience";
import ClinicDetails from "./SignupComponent/ClinicDetails/ClinicDetails";
import SocialLink from "./SignupComponent/SocialLink/SocialLink";
import OtherLink from "./SignupComponent/OtherLink/OtherLink";
import Fees from "./SignupComponent/Fees/Fees";
import Hours from "./SignupComponent/Hours/Hours";
import Expertise from "./SignupComponent/Expertise/Expertise";
import Specialty from "./SignupComponent/Specialty/Specialty";
import ThankYou from "./SignupComponent/ThankYou/ThankYou";
import Address from "./SignupComponent/Address/Address";
import { dentistSignup, patientSignup } from "../../apiCall";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

function Signup() {
  const initialValues = {
    step: 1,
    name: "",
    registrationNumber: "",
    phoneNumber: "",
    email: "",
    password: "",
    birthdate: "",
    gender: "male",
    workExperience: "",
    clinicName: "",
    clinicAddress: "",
    address: "",
    postalCode: "",
    state: "",
    city: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    otherSocialLink: "",
    otherLink: "",
    personalWebsite: "",
    checkupPrice: "",
    appointmentSlot:[{ from: "", to: "" }],
    expertise: [],
    specialty: [],
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const [userType, setUserType] = useState("dentist");
  const [, dispatch] = useStateValue();
  const setUser = (Type) => {
    setFormData(initialValues);
    setUserType(Type);
    nextStep();
  };
  const prevStep = () => {
    const value = formData.step - 1;
    setFormData({ ...formData, step: value });
  };
  const nextStep = async () => {
    if (formData.step === 15 && userType === "dentist") {
      formData.specialty = formData.specialty.map((data) => data.id);
      formData.expertise = formData.expertise.map((data) => data.id);

      window.scrollTo(0, 0);
      dispatch({ type: "SET_LOADING", status: true });
      const signup = await dentistSignup(formData);
      dispatch({ type: "SET_LOADING", status: false });
      const reMessage = signup["status"][0]["Error"];
      if (reMessage === "False") {
        toast.success(signup["status"][0]["Message"]);
        const value = formData.step + 1;
        setFormData({ ...formData, step: value });

      } else {
        toast.error(signup["status"][0]["Message"]);
        setFormData(initialValues);
      }
    } else if (formData.step === 8 && userType === "patient") {
      // toast.warning("Wait! we will proceed your request.");
      window.scrollTo(0, 0);
      dispatch({ type: "SET_LOADING", status: true });
      const signup = await patientSignup(formData);
      dispatch({ type: "SET_LOADING", status: false });
      const reMessage = signup["status"][0]["Error"];
      if (reMessage === "False") {
        toast.success(signup["status"][0]["Message"]);
        const value = formData.step + 1;
        setFormData({ ...formData, step: value });
        
      } else {
        toast.error(signup["status"][0]["Message"]);
        setFormData(initialValues);
      }
    } else {
      const value = formData.step + 1;
      setFormData({ ...formData, step: value });
    }
  };
  if (userType === "dentist") {
    switch (formData.step) {
      case 1:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ChooseOne
                  setUser={setUser}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </div>
          </section>
        );
      case 2:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Name
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );
      case 3:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <RegistreationNo
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={20}
                />
              </div>
            </div>
          </section>
        );
      case 4:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <PhoneNumber
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={30}
                />
              </div>
            </div>
          </section>
        );
      case 5:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Email
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={40}
                />
              </div>
            </div>
          </section>
        );
      case 6:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Password
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={50}
                />
              </div>
            </div>
          </section>
        );
      case 7:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Birthdate
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={60}
                />
              </div>
            </div>
          </section>
        );
      case 8:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Experience
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      case 9:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ClinicDetails
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      case 10:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <SocialLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={90}
                />
              </div>
            </div>
          </section>
        );
      case 11:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <OtherLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={100}
                />
              </div>
            </div>
          </section>
        );
      case 12:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Fees
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={110}
                />
              </div>
            </div>
          </section>
        );
      case 13:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Hours
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={120}
                />
              </div>
            </div>
          </section>
        );
      case 14:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Expertise
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={130}
                />
              </div>
            </div>
          </section>
        );
      case 15:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Specialty
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={140}
                />
              </div>
            </div>
          </section>
        );
      case 16:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ThankYou
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={140}
                  progresBarValue={140}
                />
              </div>
            </div>
          </section>
        );
      default:

      // do nothing
    }
  } else {
    switch (formData.step) {
      case 1:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ChooseOne
                  setUser={setUser}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );
      case 2:
        // <UserDetails />
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Name
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={10}
                />
              </div>
            </div>
          </section>
        );

      case 3:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <PhoneNumber
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={20}
                />
              </div>
            </div>
          </section>
        );
      case 4:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Email
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={30}
                />
              </div>
            </div>
          </section>
        );
      case 5:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Password
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={40}
                />
              </div>
            </div>
          </section>
        );
      case 6:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Birthdate
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={50}
                />
              </div>
            </div>
          </section>
        );

      case 7:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <SocialLink
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={60}
                />
              </div>
            </div>
          </section>
        );
      case 8:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <Address
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );

      case 9:
        return (
          <section className="section-signup">
            <div className="comman-container">
              <div className="inner-container">
                <ThankYou
                  nextSteps={nextStep}
                  prevStep={prevStep}
                  formData={formData}
                  setFormData={setFormData}
                  progresBarMaxValue={70}
                  progresBarValue={70}
                />
              </div>
            </div>
          </section>
        );
      default:

      // do nothing
    }
  }
}

export default Signup;
