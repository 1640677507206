import React from "react";
import { Link } from "react-router-dom";
import "./DashboardHeader.scss";
import notification from "../../Assets/icons/Notification.svg";
import menu from "../../Assets/icons/Menu.svg";
import logo from "../../Assets/image/logo.png";

function DashboardHeader() {
  return (
    <header className="section-header">
      <div className="comman-container">
        <div className="dashboard-header-inner-container">
          <img src={menu} alt="" className="menu" />
          <Link to="/">
            <div className="log">
              {" "}
              <img src={logo} alt="" />
            </div>
          </Link>
          <div className="divider"></div>
          <div className="nav-link-wrapper">
            <div className="nav-link">Dashboard</div>
            <div className="notification">
              <img src={notification} alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
