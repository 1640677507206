import React, { useEffect, useRef } from "react";
import SideNav from "../component/side-nav/side-nav";
import "./dentist-edit-profile.scss";
import { useState } from "react";
import "../edit-profile/edit-profile.scss";
import {
  changePassword,
  fetchDoctorProfile,
  postProfileImage,
  updateDoctorAddress,
  updateDoctorDentist,
  updateDoctorInformation,
  updateDoctorSocial,
  updateDoctorWebsite,
} from "../../../apiCall";
import { toast } from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import { getExperties, getSpecialities } from "../../../apiCall";
import userProfile from "../../../Assets/image/user-profile.png";
import axios from "axios";
import { useStateValue } from "../../../StateProvider";
import { PINCODE_API } from "../../../Constants";

function DentistEditProfile() {
  const fileInputRef = useRef();
  const [profilePic, setProfilePic] = useState("");
  const [pincodeErr, setPincodeErr] = useState("");
  const [{ isGoogleLogIn }, dispatch] = useStateValue();
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    birthdate: "",
    phoneNumber: "",
    registrationNumber: "",
    gender: "",
  });
  const [socialDetails, setSocialDetails] = useState({
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    otherLink: "",
  });
  const [personalWebsite, setPersonalWebsite] = useState({
    personalWebsite: "",
  });
  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [dentistDetails, setDentistDetails] = useState({
    workExperience: 0,
    checkupPrice: 0,
    meetingDurations: 0,
    restTime: 0,
    expertise: [],
    specialty: [],
    customSlot: [],
  });
  const [addressDetails, setAddressDetails] = useState({
    clinicName: "",
    clinicAddress: "",
    city: "",
    state: "",
    // country: "",
    postalCode: "",
  });

  const [personalErrors, setPersonalErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    regNo: "",
    gender: "",
  });
  const [personalWebsiteErrors, setPersonalWebsiteErrors] = useState({
    website: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [dentistDetailsErrors, setDentistDetailsErrors] = useState({
    experience: "",
    fees: "",
  });

  const [addressErrors, setAddressErrors] = useState({
    clinicName: "",
    clinicAddress: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const [errors, setErrors] = useState([]);
  const timeIsAfter = (time1, time2) => {
    const [hour1, minute1] = time1.split(":");
    const [hour2, minute2] = time2.split(":");

    return hour1 > hour2 || (hour1 === hour2 && minute1 > minute2);
  };
  const isTimeDurationValide = (time1, time2) => {
    const [hour1, minute1] = time1.split(":");
    const [hour2, minute2] = time2.split(":");
    const totalMinutes1 = parseInt(hour1) * 60 + parseInt(minute1);
    const totalMinutes2 = parseInt(hour2) * 60 + parseInt(minute2);
    return totalMinutes1 >= totalMinutes2 + 60;
  };
  const isSlotValide = (pair) => {
    if (!timeIsAfter(pair.to, pair.from)) {
      return ({errorStatus:true,errorMessage:" End time must be after start time."})
    }
    if (!isTimeDurationValide(pair.to, pair.from)){
      return ({errorStatus:true,errorMessage:" The duration of solt is greater than or equal to 60 minutes."})
    }
    return ({errorStatus:false,errorMessage:""})
  };

  const checkSlot = (e, type) => {
    e.preventDefault();

    const newErrors = dentistDetails.customSlot.map(
      (pair) => isSlotValide(pair)
    );
    setErrors(newErrors);

    if (newErrors.every((err) => !err.errorStatus)) {
      setErrors([]);
      handleSubmit(e, type);
      console.log(dentistDetails);
    }
  };
  

  const handleInputChange = (index, type, value) => {
    const newInputPairs = [...dentistDetails.customSlot];
    newInputPairs[index][type] = value;
    setDentistDetails({ ...dentistDetails, customSlot: newInputPairs });
  };

  const addInputPair = () => {
    setDentistDetails({
      ...dentistDetails,
      customSlot: [...dentistDetails.customSlot, { from: "", to: "" }],
    });
    setErrors((prevErrors) => [...prevErrors, false]);
  };

  const removeInputPair = (index) => {
    setDentistDetails({
      ...dentistDetails,
      customSlot: dentistDetails.customSlot.filter((_, i) => i !== index),
    });
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  // const [customSlotOption, setCustomSlotOption] = useState(initSlote);
  const [personalDetailsRes, setPersonalDetailsRes] = useState({});
  const [socialDetailsRes, setSocialDetailsRes] = useState({});
  const [personalWebsiteRes, setPersonalWebsiteRes] = useState({});
  const [dentistDetailsRes, setDentistDetailsRes] = useState({});
  const [addressDetailsRes, setAddressDetailsRes] = useState({});

  const [expertiesOption, setExpertiesOption] = useState([]);
  const [specialityOption, setSpecialityOption] = useState([]);

  const [isEditable, setIsEditable] = useState({
    personalEdit: false,
    socialEdit: false,
    websiteEdit: false,
    passwordEdit: false,
    dentistEdit: false,
    addressEdit: false,
  });
  useEffect(() => {
    const getExpertiesData = async () => {
      dispatch({ type: "SET_LOADING", status: true });
      const res = await getExperties();
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setExpertiesOption(res.data);
      }
    };

    const getSpecialitiesData = async () => {
      const res = await getSpecialities();
      dispatch({ type: "SET_LOADING", status: false });
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setSpecialityOption(res.data);
        // toast.success("Data successfully");
      }
    };

    getExpertiesData();
    getSpecialitiesData();
  }, []);

  const handleImageChange = (file) => {
    if (file.target.files && file.target.files[0]) {
      if (
        file.target.files[0].type === "image/x-png" ||
        file.target.files[0].type === "image/png" ||
        file.target.files[0].type === "image/jpeg" ||
        file.target.files[0].type === "image/jpg"
      ) {
        handleFileUpload(file.target.files[0]);
      } else {
        toast.error("Select an image file");
      }
    }
  };

  const handleFileUpload = async (img) => {
    const formData = new FormData();
    formData.append("image", img);
    dispatch({ type: "SET_LOADING", status: true });
    const res = await postProfileImage(formData);
    dispatch({ type: "SET_LOADING", status: false });
    const reStatus = res["data"]["status"][0]["Error"];
    if (reStatus === "True") {
      toast.error(res["status"][0]["Message"]);
    } else {
      window.localStorage.setItem("profile",res.data.data.profile)
      toast.success("Profile picture updated");
      setProfilePic(URL.createObjectURL(fileInputRef.current.files[0]));
    }
  };

  const handleChange = (e, type) => {
    const { name, value } = e.target;

    switch (type) {
      case "personal":
        // setPersonalDetails((prevData) => ({ ...prevData, [name]: value }));
        if (name === "birthdate") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              name: "birthdate is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
        }
        if (name === "gender") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              name: "gender is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
        }
        if (name === "name") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            setPersonalErrors({ ...personalErrors, name: "Name is required" });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          setPersonalErrors({ ...personalErrors, name: "" });
          if (/^[^-\s][a-zA-Z0-9_\s-]+$/.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }

        if (name === "email") {
          if (value.length === 0) {
            setPersonalErrors({
              ...personalErrors,
              email: "Email is required",
            });
            return setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }
          if (!/^[\w.]+@([\w-]+\.)+[\w-]{2,4}/.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({
              ...personalErrors,
              email: "Email is not valid",
            });
          } else {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({ ...personalErrors, email: "" });
          }
        }

        if (name === "phoneNumber") {
          const mobile_number_regex = /^[6-9]\d{9}$/i;
          if (value.length === 0) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
            return setPersonalErrors({
              ...personalErrors,
              phoneNumber: "Phone number is required",
            });
          }
          if (!mobile_number_regex.test(value)) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setPersonalErrors({
              ...personalErrors,
              phoneNumber: "Phone number is not valid",
            });
          } else {
            setPersonalErrors({ ...personalErrors, phoneNumber: "" });
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }

        if (name === "registrationNumber") {
          setPersonalDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          if (value.length === 0) {
            return setPersonalErrors({
              ...personalErrors,
              regNo: "Registration number is required",
            });
          }
          return setPersonalErrors({
            ...personalErrors,
            regNo: "",
          });
        }

        if (name === "gender") {
          if (value.length === 0) {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
            return setPersonalErrors({
              ...personalErrors,
              gender: "Gender is required",
            });
          }
          if (
            value === "Male" ||
            value === "Female" ||
            value === "male" ||
            value === "female"
          ) {
            if (value === "Male") {
              setPersonalDetails((prevData) => ({
                ...prevData,
                [name]: "male",
              }));
            } else if (value === "female") {
              setPersonalDetails((prevData) => ({
                ...prevData,
                [name]: "female",
              }));
            } else {
              setPersonalDetails((prevData) => ({
                ...prevData,
                [name]: value,
              }));
            }
            return setPersonalErrors({
              ...personalErrors,
              gender: "",
            });
          } else {
            setPersonalDetails((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            return setPersonalErrors({
              ...personalErrors,
              gender: "Type either Male or Female",
            });
          }
        }

        break;

      case "social":
        setSocialDetails((prevData) => ({ ...prevData, [name]: value }));
        break;

      case "personal-site":
        const site_regex = /^(?!:\/\/)(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
        setPersonalWebsite({ [name]: value });
        if (!site_regex.test(value)) {
          setPersonalWebsiteErrors({
            ...personalWebsite,
            website: "Invalid Website Formate.",
          });
        } else {
          setPersonalWebsiteErrors({
            ...personalWebsite,
            website: "",
          });
        }
        break;

      case "password":
        const pass_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}/;
        if (name === "oldPassword") {
          if (value.length === 0) {
            setPasswordErrors({
              ...passwordErrors,
              oldPassword: "Old password is required",
            });
            return setPasswordDetails((prevData) => ({
              ...prevData,
              [name]: "",
            }));
          }

          if (value.length < 8 || value.length > 16) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            return setPasswordErrors({
              ...passwordErrors,
              oldPassword:
                "Old password must have at least 8 characters and maximum 16 characters",
            });
          } else {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({ ...passwordErrors, oldPassword: "" });
          }
        }

        if (name === "newPassword") {
          if (value.length === 0) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: "" }));
            return setPasswordErrors({
              ...passwordErrors,
              newPassword: "New password is required",
            });
          }
          if (pass_regex.test(value)) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({ ...passwordErrors, newPassword: "" });
          } else {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
            setPasswordErrors({
              ...passwordErrors,
              newPassword:
                "Password must contain uppercases, lowercases, numbers and special characters",
            });
          }
        }

        if (name === "confirmPassword") {
          if (value.length === 0) {
            setPasswordDetails((prevData) => ({ ...prevData, [name]: "" }));
            return setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "Confirm password is required",
            });
          }
          setPasswordDetails((prevData) => ({ ...prevData, [name]: value }));
          if (passwordDetails.newPassword !== value) {
            setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "Password does not match",
            });
          } else {
            setPasswordErrors({
              ...passwordErrors,
              confirmPassword: "",
            });
          }
        }
        break;

      case "dentist":
        if (name === "workExperience") {
          setDentistDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value.length === 0) {
            return setDentistDetailsErrors({
              ...dentistDetailsErrors,
              experience: "Work experience is required",
            });
          }
          setDentistDetailsErrors({
            ...dentistDetailsErrors,
            experience: "",
          });
        }
        if (name === "checkupPrice") {
          setDentistDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value.length === 0) {
            return setDentistDetailsErrors({
              ...dentistDetailsErrors,
              fees: "Fees is required",
            });
          }
          setDentistDetailsErrors({
            ...dentistDetailsErrors,
            fees: "",
          });
        }
        if (name === "restTime") {
          setDentistDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value.length === 0) {
            return setDentistDetailsErrors({
              ...dentistDetailsErrors,
              restTime: "rest timeis required",
            });
          }
          setDentistDetailsErrors({
            ...dentistDetailsErrors,
            restTime: "",
          });
        }
        if (name === "meetingDurations") {
          setDentistDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value.length === 0) {
            return setDentistDetailsErrors({
              ...dentistDetailsErrors,
              meetingDurations: "meeting durations is required",
            });
          }
          setDentistDetailsErrors({
            ...dentistDetailsErrors,
            meetingDurations: "",
          });
        }
        break;

      case "address":
        if (name === "state") {
          setAddressDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value === "select state") {
            return setAddressErrors({
              ...addressErrors,
              state: "State is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            state: "",
          });
        }
        if (name === "city") {
          setAddressDetails((prevData) => ({ ...prevData, [name]: value }));
          if (value === "select city") {
            return setAddressErrors({
              ...addressErrors,
              city: "City is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            city: "",
          });
        }
        setAddressDetails((prevData) => ({ ...prevData, [name]: value }));
        if (name === "clinicName") {
          if (value.length === 0) {
            return setAddressErrors({
              ...addressErrors,
              clinicName: "Clinic name is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            clinicName: "",
          });
        }
        if (name === "clinicAddress") {
          if (value.length === 0) {
            return setAddressErrors({
              ...addressErrors,
              clinicAddress: "Clinic address is required",
            });
          }
          setAddressErrors({
            ...addressErrors,
            clinicAddress: "",
          });
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    const submitData = async () => {
      let res = null,
        reStatus = null;
      switch (type) {
        case "personal":
          const gender = document.getElementById("gender").value;
          const dob = document.getElementById("birthdate").value;
          const regNo = document.getElementById("registrationNumber").value;
          const name = document.getElementById("name").value;
          const email = document.getElementById("email").value;

          setPersonalDetails({
            ...personalDetails,
            birthdate: dob,
            registrationNumber: regNo,
            gender: gender,
            email: email,
            name: name,
          });

          dispatch({ type: "SET_LOADING", status: true });
          res = await updateDoctorInformation(token, personalDetails);
          dispatch({ type: "SET_LOADING", status: false });
          console.log("RES", res);
          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setPersonalDetails(personalDetailsRes);
          } else {
            toast.success("Personal Details Updated.");
            setPersonalDetails(res.data);
            setPersonalDetailsRes(res.data);
            setIsEditable({ ...isEditable, personalEdit: false });
            window.localStorage.setItem("token", res?.access_token);
            window.localStorage.setItem("phoneNumber", res?.data?.phoneNumber);
            window.localStorage.setItem("name", res?.data?.FullName);
            window.localStorage.setItem("email", res?.data?.email);

            // window.localStorage.clear();
          }
          break;

        case "social":
          dispatch({ type: "SET_LOADING", status: true });
          res = await updateDoctorSocial(token, socialDetails);
          dispatch({ type: "SET_LOADING", status: false });
          console.log(res);
          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setSocialDetails(socialDetailsRes);
          } else {
            toast.success("Personal Details Updated.");
            setIsEditable({ ...isEditable, socialEdit: false });
            setSocialDetails(res.data);
            setSocialDetailsRes(res.data);
          }
          break;

        case "personal-site":
          const site_regex = /^(?!:\/\/)(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

          if (!site_regex.test(personalWebsite?.personalwebsite)) {
            setPersonalWebsiteErrors({
              ...personalWebsite,
              website: "Invalid Website Formate.",
            });
          } else {
            setPersonalWebsiteErrors({
              ...personalWebsite,
              website: "",
            });
            dispatch({ type: "SET_LOADING", status: true });
            res = await updateDoctorWebsite(token, {
              personalWebsite: personalWebsite?.personalwebsite,
            });
            dispatch({ type: "SET_LOADING", status: false });
            console.log(res);
            reStatus = res["status"][0]["Error"];

            if (reStatus === "True") {
              toast.error(res["status"][0]["Message"]);
              setPersonalWebsite(personalWebsiteRes);
            } else {
              toast.success("Personal Details Updated.");
              setIsEditable({ ...isEditable, websiteEdit: false });
              setPersonalWebsite(res.data);
              setPersonalWebsiteRes(res.data);
            }
          }
          break;

        case "password":
          if (passwordDetails.newPassword === passwordDetails.confirmPassword) {
            dispatch({ type: "SET_LOADING", status: true });
            res = await changePassword(token, {
              oldPassword: passwordDetails.oldPassword,
              newPassword: passwordDetails.newPassword,
            });
            dispatch({ type: "SET_LOADING", status: false });
            console.log(res);
            reStatus = res["status"][0]["Error"];

            if (reStatus === "True") {
              toast.error(res["status"][0]["Message"]);
            } else {
              toast.success("Personal Details Updated.");
            }
            setPasswordDetails({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            setIsEditable({ ...isEditable, passwordEdit: false });
          } else {
            toast.error("Re-entered password doesn't match");
          }
          break;

        case "dentist":
          const dentistData = { ...dentistDetails };

          dentistData.expertise = dentistDetails.expertise.map(
            (data) => data.id
          );
          dentistData.specialty = dentistDetails.specialty.map(
            (data) => data.id
          );

          dispatch({ type: "SET_LOADING", status: true });
          res = await updateDoctorDentist(token, dentistData);
          dispatch({ type: "SET_LOADING", status: false });
          console.log(res);
          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setDentistDetails(dentistDetailsRes);
          } else {
            toast.success("Personal Details Updated.");
            // setDentistDetails(res.data);
            // setDentistDetailsRes(res.data);
            setIsEditable({ ...isEditable, dentistEdit: false });
          }
          break;

        case "address":
          dispatch({ type: "SET_LOADING", status: true });
          res = await updateDoctorAddress(token, addressDetails);
          dispatch({ type: "SET_LOADING", status: false });
          console.log(res);
          reStatus = res["status"][0]["Error"];

          if (reStatus === "True") {
            toast.error(res["status"][0]["Message"]);
            setAddressDetails(addressDetailsRes);
          } else {
            toast.success("Personal Details Updated.");
            setIsEditable({ ...isEditable, addressEdit: false });
            console.log(res.data);
            setAddressDetails(res.data);
            setAddressDetailsRes(res.data);
          }
          break;

        default:
          break;
      }
    };
    submitData();
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");
      dispatch({ type: "SET_LOADING", status: true });
      const res = await fetchDoctorProfile(token);
      dispatch({ type: "SET_LOADING", status: false });
      console.log(res, "response!!!");
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setProfilePic(res.data.profileImage);
        setPersonalDetails({
          name: res.data.FullName,
          email: res.data.email,
          birthdate: res.data.birthdate,
          phoneNumber: res.data.phoneNumber,
          registrationNumber: res.data.registrationNumber,
          gender: res.data.gender,
        });
        setSocialDetails({
          facebookLink: res.data.facebookLink,
          twitterLink: res.data.twitterLink,
          instagramLink: res.data.instagramLink,
          otherLink: res.data.otherLink,
        });
        setPersonalWebsite({ personalWebsite: res.data.personalWebsite });
        setDentistDetails({
          workExperience: res.data.workExperience,
          checkupPrice: res.data.checkupPrice,
          restTime: res.data.restTime,
          meetingDurations: res.data.meetingDurations,
          expertise: res.data.expertise,
          specialty: res.data.specialty,
          customSlot: res.data.slot,
        });
        setAddressDetails({
          clinicName: res.data.clinicName,
          clinicAddress: res.data.clinicAddress,
          city: res.data.city,
          state: res.data.state,
          postalCode: res.data.postalCode,
        });

        const stateElement = stateRef.current;
        const cityElement = cityRef.current;
        while (stateElement.options.length > 0) {
          stateElement.remove(0);
        }
        while (cityElement.options.length > 0) {
          cityElement.remove(0);
        }
        const newStateOption = new Option("select state", "select state");
        const newCityOption = new Option("select city", "select city");
        stateElement.add(newStateOption);
        cityElement.add(newCityOption);
        try {
          const re = await axios.get(
            `https://api.data.gov.in/resource/9115b89c-7a80-4f54-9b06-21086e0f0bd7?api-key=${PINCODE_API}&format=json&limit=600&filters%5Bpincode%5D=${res.data.postalCode}`
          );
          if (re.status === 200 && re?.data?.records.length > 0) {
            const option_list = re?.data?.records;
            var stateArray = [];
            var cityArray = [];
            function isInArray(value, array) {
              return array.indexOf(value) !== -1;
            }

            option_list.forEach(function(data) {
              if (!isInArray(data["statename"].toLowerCase(), stateArray)) {
                stateArray.push(data["statename"].toLowerCase());
              }
              if (
                !isInArray(
                  data["village_locality_name"].toLowerCase(),
                  cityArray
                )
              ) {
                cityArray.push(data["village_locality_name"].toLowerCase());
              }
            });

            while (stateElement.options.length > 0) {
              stateElement.remove(0);
            }
            while (cityElement.options.length > 0) {
              cityElement.remove(0);
            }
            stateArray.forEach((option) => {
              const newOption = new Option(option, option);
              stateElement.add(newOption);
            });
            cityArray.forEach((option) => {
              const newOption = new Option(option, option);
              cityElement.add(newOption);
            });
          } else {
            while (stateElement.options.length > 0) {
              stateElement.remove(0);
            }
            while (cityElement.options.length > 0) {
              cityElement.remove(0);
            }
            const newStateOption = new Option(
              "select pincode first",
              "select pincode first"
            );
            const newCityOption = new Option(
              "select pincode first",
              "select pincode first"
            );
            stateElement.add(newStateOption);
            cityElement.add(newCityOption);
          }
        } catch (err) {
          console.log(err);
        }

        setPersonalDetailsRes({
          name: res.data.name,
          email: res.data.email,
          birthdate: res.data.birthdate,
          phoneNumber: res.data.phoneNumber,
          registrationNumber: res.data.registrationNumber,
          gender: res.data.gender,
        });
        setSocialDetailsRes({
          facebookLink: res.data.facebookLink,
          twitterLink: res.data.twitterLink,
          instagramLink: res.data.instagramLink,
          otherLink: res.data.otherLink,
        });
        setPersonalWebsiteRes({ personalWebsite: res.data.personalWebsite });
        setDentistDetailsRes({
          workExperience: res.data.workExperience,
          checkupPrice: res.data.checkupPrice,
          restTime: res.data.restTime,
          meetingDurations: res.data.meetingDurations,
          expertise: res.data.expertise,
          specialty: res.data.specialty,
          customSlot: res.data.slot,
        });
        setAddressDetailsRes({
          clinicName: res.data.clinicName,
          clinicAddress: res.data.clinicAddress,
          city: res.data.city,
          state: res.data.state,
          postalCode: res.data.postalCode,
        });
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {}, [isEditable]);

  // Add value to state and city by checking the pincode is valid or not
  const checkPincode = async (e) => {
    const stateElement = stateRef.current;
    const cityElement = cityRef.current;
    while (stateElement.options.length > 0) {
      stateElement.remove(0);
    }
    while (cityElement.options.length > 0) {
      cityElement.remove(0);
    }
    const newStateOption = new Option("select state", "select state");
    const newCityOption = new Option("select city", "select city");
    stateElement.add(newStateOption);
    cityElement.add(newCityOption);
    setAddressDetails({
      ...addressDetails,
      postalCode: e.target.value,
      city: "",
      state: "",
    });
    if (e.target.value.length < 1) {
      return setPincodeErr("Pincode is required");
    }
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(e.target.value)) {
      setPincodeErr("Pincode is invalid");
      return setAddressDetails({
        ...addressDetails,
        city: "",
        state: "",
        postalCode: e.target.value,
      });
    } else {
      setPincodeErr("");
      try {
        const response = await axios.get(
          `https://api.data.gov.in/resource/9115b89c-7a80-4f54-9b06-21086e0f0bd7?api-key=${PINCODE_API}&format=json&limit=600&filters%5Bpincode%5D=${e.target.value}`
        );
        if (response.status === 200 && response?.data?.records.length > 0) {
          const option_list = response?.data?.records;

          setAddressDetails({
            ...addressDetails,
            tate: response.data.records[0].statename.toLowerCase(),
            city: response.data.records[0].village_locality_name.toLowerCase(),
            postalCode: e.target.value,
          });
          var stateArray = [];
          var cityArray = [];
          function isInArray(value, array) {
            return array.indexOf(value) !== -1;
          }

          option_list.forEach(function(data) {
            if (!isInArray(data["statename"].toLowerCase(), stateArray)) {
              stateArray.push(data["statename"].toLowerCase());
            }
            if (
              !isInArray(data["village_locality_name"].toLowerCase(), cityArray)
            ) {
              cityArray.push(data["village_locality_name"].toLowerCase());
            }
          });
          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          stateArray.forEach((option) => {
            const newOption = new Option(option, option);
            stateElement.add(newOption);
          });
          cityArray.forEach((option) => {
            const newOption = new Option(option, option);
            cityElement.add(newOption);
          });

          return setPincodeErr("");
        } else {
          setAddressDetails({
            ...addressDetails,
            postalCode: e.target.value,
            city: "",
            state: "",
          });
          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          const newStateOption = new Option("select state", "select state");
          const newCityOption = new Option("select city", "select city");
          stateElement.add(newStateOption);
          cityElement.add(newCityOption);

          return setPincodeErr("Pincode is invalid");
        }
      } catch (err) {}
    }
  };
  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <div className="outer-row-dash">
          <h3 className="edit-profile">Edit Profile</h3>
          <div className="row-dash">
            <div
              className="col-1-dash"
              onClick={() => fileInputRef.current.click()}
            >
              <img
                src={profilePic ? profilePic : userProfile}
                alt=""
                className="img-tag"
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
                accept="image/x-png,image/png,image/jpeg"
              />
            </div>
            <div className="col-2-dash">
              <div className="form">
                <h3 className="personal-info">
                  Personal Information{" "}
                  <span
                    onClick={() => {
                      if (isEditable.personalEdit) {
                        setPersonalDetails(personalDetailsRes);
                        setPersonalErrors({
                          email: "",
                          gender: "",
                          name: "",
                          phoneNumber: "",
                          regNo: "",
                        });
                      }
                      setIsEditable((prev) => {
                        return { ...prev, personalEdit: !prev.personalEdit };
                      });
                    }}
                  >
                    {isEditable.personalEdit ? "Cancel" : "Edit"}
                  </span>
                </h3>
                <form>
                  <div className="display-flex" style={{ gap: "20px" }}>
                    <div>
                      <label>Name:</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter your name"
                        value={personalDetails.name}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit}
                      />
                      <small className="error">{personalErrors.name}</small>
                    </div>
                    <div>
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={personalDetails.email}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit || isGoogleLogIn}
                      />
                      <small className="error">{personalErrors.email}</small>
                    </div>
                    <div>
                      <label>Birthdate:</label>
                      <input
                        name="birthdate"
                        value={personalDetails.birthdate}
                        className="input"
                        id="birthdate"
                        type="date"
                        disabled={!isEditable.personalEdit}
                        onChange={(e) => handleChange(e, "personal")}
                      />
                    </div>
                    <div>
                      <label>Registration Number:</label>
                      <input
                        type="text"
                        name="registrationNumber"
                        id="registrationNumber"
                        placeholder="Enter your registration number"
                        value={personalDetails.registrationNumber}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit}
                      />
                      <small className="error">{personalErrors.regNo}</small>
                    </div>
                    <div>
                      <label>Phone Number:</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        value={personalDetails.phoneNumber}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit}
                      />
                      <small className="error">
                        {personalErrors.phoneNumber}
                      </small>
                    </div>
                    <div>
                      <label>Gender:</label>

                      <select
                        className="inp-1 select-inp"
                        name="gender"
                        id="gender"
                        value={personalDetails.gender}
                        onChange={(e) => handleChange(e, "personal")}
                        disabled={!isEditable.personalEdit}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        {/* <option value="other">Other</option> */}
                      </select>
                      <small className="error">{personalErrors.gender}</small>
                    </div>
                    {/* <div>
                        <label>Gender:</label>
                        <select
                          className="inp-1 select-inp"
                          name="gender"
                          value={personalDetails.gender}
                          onChange={(e) => handleChange(e, "personal")}
                          disabled={!isEditable.personalEdit}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div> */}
                  </div>
                  {isEditable.personalEdit &&
                    !personalErrors.phoneNumber &&
                    !personalErrors.email &&
                    !personalErrors.name &&
                    !personalErrors.gender &&
                    !personalErrors.regNo && (
                      <button
                        className="save"
                        onClick={(e) => handleSubmit(e, "personal")}
                      >
                        Save
                      </button>
                    )}

                  <div className="div-001">
                    <hr className="line" />
                    <h3>
                      Social Media Profiles{" "}
                      <span
                        onClick={() => {
                          if (isEditable.socialEdit) {
                            setSocialDetails(socialDetailsRes);
                          }
                          setIsEditable((prev) => {
                            return { ...prev, socialEdit: !prev.socialEdit };
                          });
                        }}
                      >
                        {isEditable.socialEdit ? "Cancel" : "Edit"}
                      </span>
                    </h3>
                    <label>Facebook:</label>
                    <input
                      type="text"
                      name="facebookLink"
                      placeholder="Enter Facebook link"
                      value={socialDetails.facebookLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />

                    <label>Twitter:</label>
                    <input
                      type="text"
                      name="twitterLink"
                      placeholder="Enter Twitter link"
                      value={socialDetails.twitterLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />

                    <label>Instagram:</label>
                    <input
                      type="text"
                      name="instagramLink"
                      placeholder="Enter Instagram link"
                      value={socialDetails.instagramLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />

                    <label>Other:</label>
                    <input
                      type="text"
                      name="otherLink"
                      placeholder="Enter any other link"
                      value={socialDetails.otherLink}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "social")}
                      disabled={!isEditable.socialEdit}
                    />
                    {isEditable.socialEdit && (
                      <button
                        className="save"
                        onClick={(e) => handleSubmit(e, "social")}
                      >
                        Save
                      </button>
                    )}
                    <div className="div-001">
                      <hr className="line" />
                      <h3>
                        Personal Website{" "}
                        <span
                          onClick={() => {
                            if (isEditable.websiteEdit) {
                              setPersonalWebsite(personalWebsiteRes);
                            }
                            setPersonalWebsiteErrors({website:""})
                            setIsEditable((prev) => {
                              return {
                                ...prev,
                                websiteEdit: !prev.websiteEdit,
                              };
                            });
                          }}
                        >
                          {isEditable.websiteEdit ? "Cancel" : "Edit"}
                        </span>
                      </h3>
                      <label>Personal Website:</label>
                      <input
                        type="text"
                        name="personalwebsite"
                        placeholder="Enter personal website link"
                        value={personalWebsite.personalWebsite}
                        onChange={(e) => handleChange(e, "personal-site")}
                        disabled={!isEditable.websiteEdit}
                      />
                    </div>
                    <small className="error">
                      {personalWebsiteErrors.website}
                    </small>
                    {isEditable.websiteEdit && (
                      <button
                        className="save"
                        onClick={(e) => handleSubmit(e, "personal-site")}
                      >
                        Save
                      </button>
                    )}
                  </div>

                  {!isGoogleLogIn && (
                    <div
                      className="div-001"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <hr className="line" />
                      <h3>
                        Password
                        <span
                          onClick={() => {
                            if (isEditable.passwordEdit) {
                              setPasswordDetails({
                                oldPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                              });
                              setPasswordErrors({
                                confirmPassword: "",
                                newPassword: "",
                                oldPassword: "",
                              });
                            }

                            setIsEditable((prev) => {
                              return {
                                ...prev,
                                passwordEdit: !prev.passwordEdit,
                              };
                            });
                          }}
                        >
                          {isEditable.passwordEdit ? "Cancel" : "Edit"}
                        </span>
                      </h3>
                      <label>Old Password:</label>
                      <input
                        type="password"
                        name="oldPassword"
                        placeholder="Enter your old password"
                        value={passwordDetails.oldPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.oldPassword}
                      </small>
                      <label>New Password:</label>
                      <input
                        type="password"
                        name="newPassword"
                        placeholder="Enter your new password"
                        value={passwordDetails.newPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.newPassword}
                      </small>
                      <label>Password:</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        value={passwordDetails.confirmPassword}
                        onChange={(e) => handleChange(e, "password")}
                        disabled={!isEditable.passwordEdit}
                      />
                      <small className="error">
                        {passwordErrors.confirmPassword}
                      </small>
                      {isEditable.passwordEdit &&
                        !passwordErrors.confirmPassword &&
                        !passwordErrors.newPassword &&
                        !passwordErrors.oldPassword && (
                          <button
                            className="save"
                            onClick={(e) => handleSubmit(e, "password")}
                          >
                            Save
                          </button>
                        )}
                    </div>
                  )}

                  <div className="div-001">
                    <hr className="line" />
                    <h3>
                      Dentist
                      <span
                        onClick={() => {
                          if (isEditable.dentistEdit) {
                            setDentistDetails(dentistDetailsRes);
                            setDentistDetailsErrors({
                              experience: "",
                              fees: "",
                            });
                          }
                          setIsEditable((prev) => {
                            return { ...prev, dentistEdit: !prev.dentistEdit };
                          });
                        }}
                      >
                        {isEditable.dentistEdit ? "Cancel" : "Edit"}
                      </span>
                    </h3>
                    <div className="display-flex" style={{ gap: "20px" }}>
                      <div>
                        <label>Practising years</label>
                        <input
                          type="number"
                          name="workExperience"
                          placeholder="Enter your work experience"
                          value={dentistDetails.workExperience}
                          onChange={(e) => handleChange(e, "dentist")}
                          disabled={!isEditable.dentistEdit}
                        />
                        <small className="error">
                          {dentistDetailsErrors.experience}
                        </small>
                      </div>
                      <div>
                        <label>Fees</label>
                        <input
                          type="number"
                          name="checkupPrice"
                          placeholder="Enter your checkup price"
                          value={dentistDetails.checkupPrice}
                          onChange={(e) => handleChange(e, "dentist")}
                          disabled={!isEditable.dentistEdit}
                        />
                        <small className="error">
                          {dentistDetailsErrors.fees}
                        </small>
                      </div>
                    </div>

                    <div>
                      <label>Your Expertises </label>
                      <Multiselect
                        id="expertises"
                        options={expertiesOption}
                        onRemove={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            expertise: selectedList,
                          })
                        }
                        onSelect={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            expertise: selectedList,
                          })
                        }
                        selectedValues={dentistDetails.expertise}
                        displayValue="title"
                        onChange={(e) => {
                          console.log(e);
                          return setDentistDetails({
                            ...dentistDetails,
                            expertise: e.target.value,
                          });
                        }}
                        disable={!isEditable.dentistEdit}
                      />
                    </div>
                    <div>
                      <label>Your Specialty </label>
                      <Multiselect
                        id="specialities"
                        options={specialityOption}
                        onRemove={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            specialty: selectedList,
                          })
                        }
                        onSelect={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            specialty: selectedList,
                          })
                        }
                        selectedValues={dentistDetails.specialty}
                        displayValue="title"
                        disable={!isEditable.dentistEdit}
                        onChange={(e) => {
                          console.log(e);
                          return setDentistDetails({
                            ...dentistDetails,
                            specialty: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="display-flex" style={{ gap: "20px" }}>
                      <div>
                        <label>Rest Time</label>
                        <input
                          type="number"
                          name="restTime"
                          placeholder="Enter your rest time"
                          value={dentistDetails.restTime}
                          onChange={(e) => handleChange(e, "dentist")}
                          disabled={!isEditable.dentistEdit}
                        />
                        <small className="error">
                          {dentistDetailsErrors.restTime}
                        </small>
                      </div>
                      <div>
                        <label>Meeting Duration</label>
                        <input
                          type="number"
                          name="meetingDurations"
                          placeholder="Enter your meeting duration"
                          value={dentistDetails.meetingDurations}
                          onChange={(e) => handleChange(e, "dentist")}
                          disabled={!isEditable.dentistEdit}
                        />
                        <small className="error">
                          {dentistDetailsErrors.meetingDurations}
                        </small>
                      </div>
                    </div>

                    <div className="custome-slot">
                      <label>Preffered Hours of consultation </label>
                      <div className="input-container">
                        {dentistDetails.customSlot.map((pair, index) => (
                          <div className="input-wrapper">
                            <div className="input-inner-container">
                              <div className="md-wrapper">
                                {index === 0 && <span>From</span>}
                                <MDBInput
                                  name="birthdate"
                                  size="sm"
                                  className="input"
                                  id="form1"
                                  type="time"
                                  value={pair.from}
                                  disabled={!isEditable.dentistEdit}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "from",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="md-wrapper">
                                {index === 0 && <span>To</span>}

                                <MDBInput
                                  name="birthdate"
                                  size="sm"
                                  className="input"
                                  id="form1"
                                  type="time"
                                  value={pair.to}
                                  disabled={!isEditable.dentistEdit}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "to",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              {dentistDetails.customSlot.length > 1 &&
                                isEditable.dentistEdit && (
                                  <div className="btn-wrapper">
                                    <button
                                      className="disable-button rm-btn"
                                      onClick={() => removeInputPair(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                            </div>
                            {errors[index]?.errorStatus && (
                              <span className="input-error">
                               {errors[index].errorMessage}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                      {isEditable.dentistEdit && (
                        <div className="add-slot">
                          <button
                            className=" disable-button"
                            onClick={addInputPair}
                            type="button"
                          >
                            Add Slot
                          </button>
                        </div>
                      )}

                      {/* <Multiselect
                        id="customSlot"
                        options={customSlotOption}
                        onRemove={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            customSlot: selectedList,
                          })
                        }
                        onSelect={(selectedList) =>
                          setDentistDetails({
                            ...dentistDetails,
                            customSlot: selectedList,
                          })
                        }
                        selectedValues={dentistDetails.customSlot}
                        displayValue="title"
                        disable={!isEditable.dentistEdit}
                        onChange={(e) => {
                          return setDentistDetails({
                            ...dentistDetails,
                            customSlot: e.target.value,
                          });
                        }}
                      /> */}
                    </div>

                    {isEditable.dentistEdit &&
                      !dentistDetailsErrors.experience &&
                      !dentistDetailsErrors.fees && (
                        <button
                          className="save"
                          onClick={(e) => checkSlot(e, "dentist")}
                        >
                          Save
                        </button>
                      )}
                  </div>

                  <div
                    className="div-001"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <hr className="line" />
                    <h3>
                      Address
                      <span
                        onClick={() => {
                          if (isEditable.addressEdit) {
                            setAddressDetails(addressDetailsRes);
                            setAddressErrors({
                              clinicAddress: "",
                              clinicName: "",
                            });
                            setPincodeErr("");
                          }
                          setIsEditable((prev) => {
                            return { ...prev, addressEdit: !prev.addressEdit };
                          });
                        }}
                      >
                        {isEditable.addressEdit ? "Cancel" : "Edit"}
                      </span>
                    </h3>
                    <label>Address:</label>
                    <input
                      className="inp-1"
                      type="text"
                      name="clinicName"
                      id="clinicName"
                      placeholder="Enter clinic name"
                      value={addressDetails.clinicName}
                      onChange={(e) => handleChange(e, "address")}
                      disabled={!isEditable.addressEdit}
                    />
                    <small className="error">{addressErrors.clinicName}</small>
                    <textarea
                      type="text"
                      name="clinicAddress"
                      placeholder="Enter your address"
                      value={addressDetails.clinicAddress}
                      onChange={(e) => handleChange(e, "address")}
                      disabled={!isEditable.addressEdit}
                    />
                    <small className="error">
                      {addressErrors.clinicAddress}
                    </small>
                    <div
                      className="display-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <input
                        className="inp-1"
                        type="text"
                        name="postalCode"
                        placeholder="Enter postal code"
                        value={addressDetails.postalCode}
                        onChange={checkPincode}
                        disabled={!isEditable.addressEdit}
                      />
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="state"
                        value={addressDetails.state}
                        ref={stateRef}
                        onChange={(e) => {
                          handleChange(e, "address");
                        }}
                        disabled={!isEditable.addressEdit}
                      >
                        <option selected value="select state">
                          select state
                        </option>
                      </select>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="city"
                        ref={cityRef}
                        value={addressDetails.city}
                        onChange={(e) => {
                          handleChange(e, "address");
                        }}
                        disabled={!isEditable.addressEdit}
                      >
                        <option selected value="select city">
                          select city
                        </option>
                      </select>
                      <small className="error">{pincodeErr}</small>
                    </div>
                    {isEditable.addressEdit &&
                      !addressErrors.clinicAddress &&
                      !addressErrors.clinicName &&
                      !pincodeErr && (
                        <button
                          className="save"
                          onClick={(e) => handleSubmit(e, "address")}
                        >
                          Save
                        </button>
                      )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DentistEditProfile;
