import React, { useState } from "react";
import { useQuery } from "react-query";
import "../home/home.scss";
import Search from "../../../Assets/icons/Search.svg";
import SideNav from "../component/side-nav/side-nav";
import { fetchAppointment } from "../../../apiCall";
import ShowMoreText from "../../../components/ShowMoreText/showMoreText";

function DashboardDentistHomeComponent() {
  const dentistName = window.localStorage.getItem("name");
  const { data: appointments, isLoading } = useQuery(
    "appointments",
    fetchAppointment,
    {
      onSuccess: (data) => {
        if (data.status[0].Response === 200) {
          setSelectedOption("Upcoming")

          setAppointmentsDetail(
            shortAppointment(
              data?.data.filter(
                (element) =>
                  isDateTimeOlder(element) &
                  (element.appointmentStatus !== "Cancelled")
              )
            )
          );
        }
      },
    }
  );
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const [selectedOption, setSelectedOption] = useState("Upcoming");
  const [appointmentsDetail, setAppointmentsDetail] = useState([]);

  const shortAppointment = (data) => {
    const compareDateTimes = (appointment1, appointment2) => {
      const date1 = new Date(
        `${appointment1.date} ${appointment1.startTime.slice(0, 8)}`
      );
      const date2 = new Date(
        `${appointment2.date} ${appointment2.startTime.slice(0, 8)}`
      );

      // Compare dates
      if (date1 < date2) {
        return -1;
      } else if (date1 > date2) {
        return 1;
      }

      // If dates are equal, compare times
      const time1 = date1.getTime();
      const time2 = date2.getTime();

      if (time1 < time2) {
        return -1;
      } else if (time1 > time2) {
        return 1;
      }

      // If both dates and times are equal
      return 0;
    };
    return data.sort(compareDateTimes);
  };


  const isDateTimeOlder = (data) => {
    // Given date and time
    const givenDateTime = new Date(
      `${data.date}T${data.startTime.slice(0, 8)}`
    );

    // Current date and time
    const currentDateTime = new Date();

    // Compare the two dates
    if (givenDateTime > currentDateTime) {
      return true;
    }
    return false;
  };
  const handleFilterOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "All") {
      setAppointmentsDetail(shortAppointment(appointments?.data).reverse());
    } else if (event.target.value === "Upcoming") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter(
            (element) =>
              isDateTimeOlder(element) &
              (element.appointmentStatus !== "Cancelled")
          )
        )
      );
    } else if (event.target.value === "Past Appointment") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter((element) => !isDateTimeOlder(element))
        ).reverse()
      );
    } else if (event.target.value === "Cancelled") {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter(
            (element) => element.appointmentStatus === event.target.value
          )
        ).reverse()
      );
    }
  };
  const handleSearchInput = (event) => {
    console.log(event.target.value);
    if (event.target.value === "") {
      setAppointmentsDetail(shortAppointment(appointments?.data).reverse());
    } else {
      setAppointmentsDetail(
        shortAppointment(
          appointments?.data.filter((element) =>
            element.name.includes(event.target.value)
          )
        ).reverse()
      );
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <h2 className="txt-01">Hello! Dr. {dentistName}</h2>
        <p className="txt-02">What would you like to do today?</p>
        <div className="ap-001">
          <div className="appointment-div">
            <div className="a-01">
              <div className="app-div-01">
                <h3>Appointments</h3>
                <select
                  value={selectedOption}
                  onChange={handleFilterOptionChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Upcoming">Upcoming</option>
                  <option value="Past Appointment">Past Appointment</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div className="search-div">
                <input
                  type="text"
                  placeholder="Search here"
                  onChange={handleSearchInput}
                />
                <img src={Search} alt="" />
              </div>
            </div>
          </div>
          <div>
            <table className="hide-mobile">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Appointment Status</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Diagnosis</th>
                </tr>
              </thead>
              <tbody>
                {appointmentsDetail.length > 0 ? (
                  appointmentsDetail.map((appointment, index) => (
                    <tr key={index}>
                      <td>
                        <div className="img-001">
                          <img src={appointment.profile_image} alt="" />
                          <span>{appointment.name}</span>
                        </div>
                      </td>
                      <td>{appointment?.appointmentStatus}</td>
                      <td>{appointment?.date}</td>
                      <td>{appointment?.startTime.slice(0, 8)}</td>
                      <td>
                        <ShowMoreText
                          text={appointment?.summary}
                          maxWords={20}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No appointments found.</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="appointment-list hide-web">
              <p className="p-tag">Profile</p>
              <hr className="line" />
              {appointmentsDetail.length > 0 ? (
                appointmentsDetail.map((data, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="profile-div"
                      onClick={() => handleCollapse(index)}
                    >
                      <img
                        src={data.profile_image}
                        alt=""
                        className="profile-img"
                      />
                      <p className="txt-01">{data?.name}</p>
                    </div>
                    <div
                      className={`info-div collapse${
                        expandedIndex === index ? "show" : ""
                      }`}
                    >
                      <p className="info-heading">Appointment Details</p>
                      <hr className="info-divider" />
                      <p className="info-data">
                        <strong>Date:</strong> {data.date}
                      </p>
                      <p className="info-data">
                        {" "}
                        <strong>Time:</strong> {data?.startTime.slice(0, 8)}
                      </p>
                      {/* <p className="info-data">
                        <strong>Service:</strong> {data.service}
                      </p> */}
                      <p className="info-data">
                        <strong>Diagnosis:</strong> {data.summary}
                      </p>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <p>No appointments found.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardDentistHomeComponent;
