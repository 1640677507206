import React, { useEffect, useState } from "react";
import "./BookAppointment.scss";
import { getDentistAppointments, updateAppointment } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";
function convertToAmPm(timeString) {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);

  // Determine the suffix and adjust hours if necessary
  const suffix = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

  // Format the time string with leading zeros for minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

  // Construct the final time string
  return `${adjustedHours}:${formattedMinutes} ${suffix}`;
}
function convertTo24HourTime(timeString) {
  // Create a Date object with the time string
  const date = new Date("1970-01-01 " + timeString);

  // Function to add leading zeros if necessary
  function pad(number) {
    return number < 10 ? "0" + number : number;
  }

  // Get hours and minutes, and format them as desired
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = "00"; // Since seconds are not provided in the input, default to '00'

  // Concatenate the parts to get the full time string
  return `${hours}:${minutes}:${seconds}`;
}
function isSlotAvailable(
  userBookedAppointment,
  slotStart,
  slotEnd,
  bookedSlots,
  dateToCheck,
  setSelectedSlot
) {
  // Convert slotStart and slotEnd to Date objects
  const slotStartTime = new Date(`${dateToCheck}T${slotStart}`);
  const slotEndTime = new Date(`${dateToCheck}T${slotEnd}`);
  const currentDateTime = new Date();
  const userBookedslotStartTime = new Date(
    `${userBookedAppointment.date}T${userBookedAppointment.startTime}`
  );
  const userBookedslotEndTime = new Date(
    `${userBookedAppointment.date}T${userBookedAppointment.endTime}`
  );

  // Check each booked slot to see if there is an overlap
  for (let i = 0; i < bookedSlots.length; i++) {
    const bookedStart = new Date(
      `${bookedSlots[i].appointment_date}T${bookedSlots[i].appointment_start_time}`
    );
    const bookedEnd = new Date(
      `${bookedSlots[i].appointment_date}T${bookedSlots[i].appointment_end_time}`
    );

    // Check for overlap
    if (slotStartTime < bookedEnd && slotEndTime > bookedStart) {
      if (
        slotStartTime > currentDateTime &&
        slotStartTime <= userBookedslotEndTime &&
        slotEndTime >= userBookedslotStartTime &&
        dateToCheck === userBookedAppointment.date
      ) {
        setSelectedSlot(
          `${convertToAmPm(slotStart)} to ${convertToAmPm(slotEnd)}`
        );
        return true;
      }
      return false;
    }
  }

  // No overlaps found, so the slot is available
  if (slotStartTime > currentDateTime) {
    return true;
  } else {
    return false;
  }
}

function createTimeSlots(
  userBookedAppointment,
  slots,
  slotDuration,
  restDuration,
  bookedSlots,
  currentDate,
  setSelectedSlot
) {
  let result = [];
  setSelectedSlot("");

  slots.forEach((slot) => {
    const [startTime, endTime] = slot.split("-");
    let current = new Date(`${currentDate}T${startTime}Z`);
    const end = new Date(`${currentDate}T${endTime}Z`);
    const minimumDuration = slotDuration * 0.8; // 80% of slotDuration

    while (current < end) {
      let slotEndTime = new Date(current.getTime() + slotDuration * 60000);
      if (slotEndTime > end) {
        const remainingTime = (end.getTime() - current.getTime()) / 60000; // Convert to minutes
        if (remainingTime < minimumDuration) {
          break;
        }
        slotEndTime = end;
      }
      // Add the slot to results
      let slotStart = current.toISOString().substring(11, 16);
      let slotEnd = slotEndTime.toISOString().substring(11, 16);
      if (
        isSlotAvailable(
          userBookedAppointment,
          slotStart,
          slotEnd,
          bookedSlots,
          currentDate,
          setSelectedSlot
        )
      ) {
        result.push(`${convertToAmPm(slotStart)} to ${convertToAmPm(slotEnd)}`);
      }

      // Add rest time
      current = new Date(slotEndTime.getTime() + restDuration * 60000);
    }
  });
  return result;
}

function isDateGreaterOrEqualToToday(dateString) {
  // Create a Date object from the date string
  const inputDate = new Date(dateString);

  // Reset the time to midnight for the input date for a fair comparison
  inputDate.setHours(0, 0, 0, 0);

  // Get the current date and reset the time to midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Check if the input date is greater than or equal to today
  return inputDate >= today;
}

const BookAppointment = ({
  title,
  successResponseMessage,
  appointment,
  handleClose,
}) => {
  const [, dispatch] = useStateValue();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [slotData, setSlotData] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().substring(0, 10);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    let slots = createTimeSlots(
      appointment,
      slotData.appointmentSlots,
      slotData.slotTime,
      slotData.restTime,
      slotData.bookedSlots,
      newDate,
      setSelectedSlot
    );
    setAvailableSlots(slots);
  };

  const bookAppointment = async () => {
    dispatch({ type: "SET_LOADING", status: true });
    try {
      if (selectedSlot === "") {
        toast.error("Please choose any time slot.");
      } else {
        const [newStartTime, newEndTime] = selectedSlot.split(" to ");
        console.log(appointment.date === selectedDate ,
          appointment.startTime.replace(':00', "") ,
            convertTo24HourTime(newStartTime).replace(':00', "") ,
          appointment.endTime.replace(":00", "") ===
            convertTo24HourTime(newEndTime).replace(":00", ""))
        
        if (
          appointment.date === selectedDate &&
          appointment.startTime.replace(":00", "") ===
            convertTo24HourTime(newStartTime).replace(":00", "") &&
          appointment.endTime.replace(":00", "") ===
            convertTo24HourTime(newEndTime).replace(":00", "")
        ) {
          handleClose();
        } else {
          const data = {
            appointmentId: appointment.id,
            newAppointmentDate: selectedDate,
            newAppointmentTime: selectedSlot,
          };
          const re = await updateAppointment(data);
          if (re.status[0].Response === 200) {
            toast.success(successResponseMessage);
            appointment.date = selectedDate;
            const [newStartTime, newEndTime] = selectedSlot.split(" to ");
            console.log(newStartTime, newEndTime);
            appointment.startTime = convertTo24HourTime(newStartTime);
            appointment.endTime = convertTo24HourTime(newEndTime);
            handleClose();
          }
        }
      }
    } catch (error) {}
    dispatch({ type: "SET_LOADING", status: false });
  };
  useEffect(() => {
    const fetchData = async () => {
      const re = await getDentistAppointments(appointment.email);
      if (re.status[0].Response === 200) {
        setSlotData(re.data);
        if (isDateGreaterOrEqualToToday(appointment.date)) {
          let slots = createTimeSlots(
            appointment,
            re.data.appointmentSlots,
            re.data.slotTime,
            re.data.restTime,
            re.data.bookedSlots,
            appointment.date,
            setSelectedSlot
          );
          setAvailableSlots(slots);
          setSelectedDate(appointment.date);
        }
      } else {
        toast.error("something went wrong!");
      }
    };

    fetchData();
  }, [appointment]);

  return (
    <div className="book-appointment">
      <div className="heading"> {title} </div>
      <div className="appointment-date">
        <label>Select a date for your appointment:</label>
        <input
          type="date"
          value={selectedDate}
          min={formattedDate}
          onChange={handleDateChange}
        />
      </div>
      {selectedDate && (
        <div className="appointment-slots-wrapper">
          <label>Select an available time slot:</label>
          <div className="appointment-slots">
            {availableSlots.map((slot, index) => (
              <div
                key={index}
                className={
                  selectedSlot !== slot
                    ? "btn-book outline-button"
                    : "btn-book outline-button-active"
                }
                onClick={() => {
                  setSelectedSlot(slot);
                }}
              >
                {console.log(slot, selectedSlot)}
                {slot}
              </div>
            ))}
          </div>
          <button className="primary-button" onClick={bookAppointment}>
            Book Appointment
          </button>
        </div>
      )}
    </div>
  );
};

export default BookAppointment;
