import React, { useState } from 'react';
import "./showMoreText.scss";


const ShowMoreText = ({ text, maxWords }) => {
  const [showFullText, setShowFullText] = useState(false);

  const words = text.split(' ');
  const truncatedText = showFullText ? text : words.slice(0, maxWords).join(' ');
  const buttonText = showFullText ? 'Show Less' : 'Show More';

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className='show-more-text'>
      <div>{truncatedText}</div>
      {words.length > maxWords && (
        <button className='primary-button' onClick={handleToggleText}>{buttonText}</button>
      )}
    </div>
  );
};

export default ShowMoreText;
