import React, { useState, useEffect, useRef } from "react";
import "./chatapp-mobile.scss";
import { IoIosArrowBack } from "react-icons/io";
import { ReactComponent as Search } from "../../../../Assets/icons/Search.svg";
import SendMsg from "../../../../Assets/icons/send.png";
import {
  getGroupLMessages,
  postGroupMessage,
  postGroupMessageFile,
} from "../../../../apiCall";
import { ReactComponent as AttachmentIcon } from "../../../../Assets/icons/attachment.svg";
import Bot from "../../../../Assets/icons/chatbot.png";

import "aos/dist/aos.css";
import { toast } from "react-hot-toast";
import FileView from "../ChatBotDiv/FileView";
import { useQuery } from "react-query";
import { CHAT_REFRESH_TIME } from "../../../../Constants";
import Call from "../../../../Assets/icons/call.svg";
import Upload from "../../../../Assets/icons/upload.svg";
import Message from "../../../../Assets/icons/ic_round-mail-outline.png";
import File from "../../../../Assets/icons/File.svg";
import Delete from "../../../../Assets/icons/delete.svg";
import ProfileDemo from "../../../../Assets/image/user-profile.png";

const calculateAge = (birthdate) =>
  Math.floor((new Date() - new Date(birthdate)) / 31557600000);

const ChatApp = ({ mypatients, handleSearchInput }) => {
  const userName = window.localStorage.getItem("name");
  const userProfilePic = window.localStorage.getItem("profile");
  const [view, setView] = useState("chatList");
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  const chatBoxRef = useRef();
  const inputRef = useRef();
  const fileInputRef = useRef();
  const submitRef = useRef();

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  // if (selectedChat) {
  const [selectProfile, setSelectProfile] = useState(null);
  const token = localStorage.getItem("token");
  const { data, error } = useQuery(
    "myData",
    () =>
      getGroupLMessages(
        token,
        selectedChat?.groupId ? selectedChat.groupId : -1
      ),
    {
      refetchInterval: CHAT_REFRESH_TIME,
    }
  );

  // }
  useEffect(() => {
    if (data) {
      const reStatus = data["status"][0]["Error"];

      if (reStatus === "True" || error) {
        if (error) {
          toast.error(error);
        } else {
          toast.error(data["status"][0]["Message"]);
        }
      } else {
        setChatHistory(data.data);
      }
    }
  }, [data, error]);

  useEffect(() => {
    const getData = async () => {
      const token = localStorage.getItem("token");
      const res = await getGroupLMessages(token, selectedChat.groupId);
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setChatHistory(res.data);
      }
    };
    if (selectedChat) {
      getData();
    }
  }, [selectedChat]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setView("chatMessages");
  };

  const handleBackClick = () => {
    setSelectedChat(null);
    setView("chatList");
  };

  const handleProfileClick = (profId) => {
    setSelectProfile(profId);
    setView("profile");
  };

  const handleFileUpload = async () => {
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const res = await postGroupMessageFile(selectedChat.groupId, formData);
    const reStatus = res["data"]["status"][0]["Error"];

    if (reStatus === "True") {
      toast.error(res["status"][0]["Message"]);
    } else {
      toast.success("File uploaded.");
      setChatHistory((prevMessages) => {
        return [
          ...prevMessages,
          {
            text: "",
            sender: userName,
            is_chat_bot: false,
            is_text: false,
            created_at: new Date(),
            file: res.data.data.fileUrl,
          },
        ];
      });
    }
  };

  const handleUploadFileChange = (event) => {
    handleFileUpload();
  };
  const isChatImage = (url) => {
    if (url) {
      if (url.includes("/uploads/group_message")) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleUserMessage = async () => {
    if (inputRef.current.value !== "") {
      const token = localStorage.getItem("token");
      const myMessage = inputRef.current.value;

      const res = await postGroupMessage(token, {
        message: myMessage,
        groupId: selectedChat.groupId,
      });
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setChatHistory((prevMessages) => {
          inputRef.current.value = "";
          return [
            ...prevMessages,
            {
              text: myMessage,
              sender: userName,
              is_chat_bot: false,
              is_text: true,
              created_at: new Date(),
              file: "",
            },
          ];
        });
      }
    }
  };

  // useEffect(() => {
  //   window.addEventListener("keypress", (e) => {
  //     if (e.code === "Enter") {
  //       // handleUserMessage()
  //       submitRef.current.click();
  //     }
  //   });
  // }, []);

  switch (view) {
    case "chatList":
      return (
        <div className="outermost-mobile-div">
          <div className="head-div">
            <h3 className="patients">{userName}</h3>
          </div>
          <div className="main-patient-section-2">
            <div className="search-div-2 display-flex">
              <input
                type="text"
                placeholder="Search "
                onChange={(event) => handleSearchInput(event)}
              />
              <Search />
            </div>
            <div className="patient-list">
              {mypatients.map((chat) => {
                // const patient = patients.find(
                //   (patient) => patient.id === chat.patientId
                // );
                return (
                  <div key={chat} className="patient">
                    <div className="profile-sec">
                      <img
                        src={chat.groupMembers[0]?.profile_image}
                        alt=""
                        className="patient-pic"
                        onError={(e) => {
                          e.target.src = ProfileDemo;
                        }}
                        onClick={() => handleProfileClick(chat)}
                      />
                      <div
                        className="text"
                        onClick={() => handleChatClick(chat)}
                      >
                        <p className="name">
                          {chat.groupMembers[0]?.name}
                          <span className="sex">{` ${chat.groupMembers[0]?.gender}`}</span>
                          <span className="age">{` ${new Date().getFullYear() -
                            new Date(
                              chat.groupMembers[0]?.birthdate
                            ).getFullYear()} years`}</span>
                        </p>
                        <p className="problem">
                          {chat.groupMembers[0]?.dentalProblem &&
                            chat.groupMembers[0]?.dentalProblem
                              .split(" ")
                              .slice(0, 3)
                              .join(" ")}{" "}
                          | <span className="age"> New</span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );

    case "chatMessages":
      // const selectedChatObj = chats.find((chat) => chat.id === selectedChat);
      // const selectedPatient = patients.find(
      //   (patient) => patient.id === selectedChatObj.patientId
      // );
      return (
        <div className="patient mobile-container">
          <div className="profile-01">
            <span onClick={handleBackClick}>
              <IoIosArrowBack />
            </span>
            {selectedChat &&
              selectedChat.groupMembers &&
              selectedChat.groupMembers.length > 0 && (
                <div className="profile">
                  <img
                    src={selectedChat.groupMembers[0]?.profile_image}
                    alt="Profile"
                    className="profile-picture"
                    onError={(e) => {
                      e.target.src = ProfileDemo;
                    }}
                  />
                  <h3>{selectedChat.groupMembers[0]?.name}</h3>
                  <p>{selectedChat.groupMembers[0]?.dentalProblem}</p>
                </div>
              )}
          </div>
          <div className="chatMessages" ref={chatBoxRef}>
            {chatHistory.map((message, index) => {
              return (
                <>
                  {
                    <>
                      {message.is_chat_bot ? (
                        <div className="bot-mobile">
                          <div className="user-detail">
                            <img src={Bot} alt="" />
                            <span className="name">GentleDentle Bot</span>
                            <span className="time-stamp">
                              {new Date(message.created_at).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                          </div>
                          <span className="message">{message.text}</span>
                        </div>
                      ) : // Conditional rendering based on sender
                      message.sender === userName ? (
                        <div className="user-self-mobile">
                          <div className="user-detail">
                            <span>
                              {console.log(selectedChat)}
                              <img
                                src={userProfilePic}
                                alt=""
                                onError={(e) => {
                                  e.target.src = ProfileDemo;
                                }}
                              />
                            </span>
                            <span className="name">{message.sender}</span>
                            <span className="time-stamp">
                              {new Date(message.created_at).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                          </div>
                          {isChatImage(message?.chatImage)  && (
                            <>
                              <img
                                className="chat-image"
                                src={message.chatImage}
                                alt=""
                              />
                            </>
                          )}
                          {message.is_text ? (
                            <span className="message">{message.text}</span>
                          ) : (
                            <FileView
                              link={message.file}
                              isSelf={message.sender === userName}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="other-user">
                          <div className="user-detail">
                            <span>
                              {console.log(selectedChat)}
                              <img
                                src={
                                  selectedChat.groupMembers[0]?.profile_image
                                }
                                alt=""
                                onError={(e) => {
                                  e.target.src = ProfileDemo;
                                }}
                              />
                            </span>
                            <span className="name">{message.sender}</span>
                            <span className="time-stamp">
                              {new Date(message.created_at).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                          </div>
                          {message.is_text ? (
                            <span className="message">{message.text}</span>
                          ) : (
                            <FileView
                              link={message.file}
                              isSelf={message.sender === userName}
                            />
                          )}
                        </div>
                      )}
                    </>
                  }
                  {/* End of JSX fragment */}
                </>
              );
            })}
          </div>
          <div className="chatInput-mobile">
            <span className="file-icon">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleUploadFileChange}
                style={{ display: "none" }}
              />

              <AttachmentIcon onClick={handleFileUploadClick} />
            </span>
            <input
              type="text"
              placeholder="Type a message.."
              className="inner-input"
              ref={inputRef}
              // onChange={setText}
              cleanOnEnter
              onEnter={handleUserMessage}
            />

            <img
              className="submit"
              src={SendMsg}
              alt=""
              onClick={handleUserMessage}
            />
          </div>
        </div>
      );

    // case "profile":
    //   return (
    //     <div className="container patient">
    //       <div className="profile-01">
    //         <span onClick={handleBackClick}>
    //           <IoIosArrowBack />
    //         </span>
    //       </div>
    //       <div className="profile">
    //         <h3>Profile Component</h3>
    //       </div>
    //     </div>
    //   );

    case "profile":
      return (
        <div className="profile-section">
          <div className="display-flex-mobile">
            <IoIosArrowBack onClick={handleBackClick} />
            <h3 className="h3-profile">Patient</h3>
          </div>
          <div className="profile">
            <div>
              <div className="profile-sec">
                <img
                  src={
                    selectProfile?.groupMembers?.[0]?.profile_image ||
                    ProfileDemo
                  }
                  alt=""
                  className="patient-pic"
                  onError={(e) => {
                    e.target.src = ProfileDemo;
                  }}
                />

                <div className="text">
                  <p className="name">
                    {`${selectProfile?.groupMembers?.[0]?.name} |`}
                    <span className="sex">{` ${selectProfile?.groupMembers?.[0]?.gender}`}</span>
                    <span className="age">
                      {calculateAge(
                        selectProfile?.groupMembers?.[0]?.birthdate
                      )}
                    </span>
                  </p>
                </div>
                <hr className="line-1" />
              </div>
              {/* Add more profile details */}
              <div className="display-flex div-side-1">
                <img src={Call} alt="" />
                {selectProfile?.groupMembers?.[0]?.phoneNumber}
              </div>
              <div className="display-flex div-side-1">
                <img src={Message} alt="" />
                {selectProfile?.groupMembers?.[0]?.email}
              </div>
              <div className="display-flex div-side-1">
                <img src={Upload} alt="" />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                />
                <button>Upload</button>
              </div>
            </div>
            <div className="docs">
              <div className="each-doc display-flex">
                <img src={File} alt="" />
                <div className="text-m">
                  <p className="txt-01">File.zip</p>
                  <p className="txt-02">02 October,2022 at 08:17</p>
                  <p className="txt-02">15.45 Mb</p>
                </div>
                <img src={Upload} alt="" />
                <img src={Delete} alt="" />
              </div>
              <div className="each-doc display-flex">
                <img src={File} alt="" />
                <div className="text-m">
                  <p className="txt-01">File.zip</p>
                  <p className="txt-02">02 October,2022 at 08:17</p>
                  <p className="txt-02">15.45 Mb</p>
                </div>
                <img src={Upload} alt="" />
                <img src={Delete} alt="" />
              </div>
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default ChatApp;
