import React, { useEffect } from "react";
import AOS from "aos";
import "./Name.scss";
import page1 from "../../../../Assets/image/signup-2.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";

function Name({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  const [nameErr, setNameErr] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    if (e.target.value.length < 1 || value === "") {
      setNameErr("Name is required");
      return setFormData({ ...formData, name: "" });
    }
    if (/^[a-zA-Z0-9_\s-]+$/.test(e.target.value)) {
      setNameErr("");
      setFormData({ ...formData, name: e.target.value });
    }
  };
  return (
    <section className="section-name">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              We make smiles healthier & brighter
            </span>
          </div>
          <img
            src={page1}
            data-aos="zoom-out"
            data-aos-duration="1000"
            alt=""
          />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              <div className="title">
                Let's get to know you a little. <span>&#x1F60A;</span>{" "}
              </div>
              <div className="sub-title">Tell us your full name.</div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter your full name"
                    value={formData.name}
                    name="name"
                    size=""
                    className="input"
                    id="form1"
                    type="text"
                    onChange={handleInputChange}
                  />
                  <small className="error">{nameErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !formData.name
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.name}
                  onClick={() => nextSteps()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Name;
