import React, { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom'

import AOS from 'aos';
import "./ThankYou.scss"
import page from "../../../../Assets/image/signup-2.png"



function ThankYou({ nextSteps, prevStep, formData, setFormData,progresBarMaxValue,progresBarValue }) {
  const navigate = useNavigate();


    useEffect(() => {
        // window.scrollTo(0, 0)
        AOS.init({
            once: true,
        });
    }, [])
    return (
        <section className="section-specialty">
            <div className="inner-container">
                <div className="left-column">
                    <div className="greeting-message" >
                        <span data-aos="fade-right" data-aos-duration="1000">
                            Dental Hygiene for all
                        </span>
                    </div>
                    <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
                </div>
                <div className="right-column">
                    <div className="container-wrapper" >
                        <div className='container-wrapper-inner' data-aos="fade-up" data-aos-duration="1000" >

                            {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
                            <div className="sub-title">Thank you! Your registration is complete. please verify your email.<span>&#x1F60A;</span> <br /><br />

                                We shall get back to you soon!<span>&#x1F60A;</span></div>
                            <div className="button-container" >
                                {/* <button className='prev disable-button' onClick={() => prevStep()}>Previous</button> */}
                                <div className="end">

                                    <button className='continue primary-button '  onClick={() => navigate("/")}>Continue</button>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default ThankYou