import React, { useState } from "react";
import { IoMicCircleOutline, IoMicCircleSharp } from "react-icons/io5";
import "./SpeechToText.scss";

const SpeechToText = ({ handleSend }) => {
  const [listening, setListening] = useState(false);

  const startSpeechRecognition = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const recognition =
        new window.webkitSpeechRecognition() || new window.SpeechRecognition();

      recognition.onstart = () => {
        setListening(true);
        try {
          const inputElement = document.getElementsByClassName("rsc-input")[0];
          inputElement.disabled = true;
        } catch (error) {
          console.log(error);
        }
        console.log("Speech recognition started");
      };

      recognition.onresult = (event) => {
        const newTranscript = event.results[0][0].transcript;
        console.log("fgfgfgfgfgf");
        const imagePreviewElement = document.getElementsByClassName(
          "image-filed"
        )[0];

        const image = imagePreviewElement.src;

        handleSend(newTranscript, image);
      };

      recognition.onend = () => {
        try {
          const inputElement = document.getElementsByClassName("rsc-input")[0];
          inputElement.disabled = false;
        } catch (error) {
          console.log(error);
        }
        setListening(false);
        console.log("Speech recognition ended");
      };

      recognition.onerror = (event) => {
        setListening(false);
        console.error("Speech recognition error:", event.error);
      };

      // Set the audio stream for speech recognition
      recognition.stream = stream;

      recognition.start();
    } catch (error) {
      console.error("Error initializing speech recognition:", error);
    }
  };

  return (
    <div className="speech-to-text">
      {listening ? (
        <IoMicCircleSharp size={"30px"} fill="#212529" />
      ) : (
        <IoMicCircleOutline
          size={"30px"}
          onClick={startSpeechRecognition}
          fill="#212529"
        />
      )}
    </div>
  );
};

export default SpeechToText;
