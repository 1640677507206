import React, { useEffect } from "react";
import "./ReturnRefund.scss";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

const ReturnRefund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="return-refund">
      <div className="title">Return & Refund</div>
      <div className="sub-title">
        <span>
          Given GentleDental.ai’s focus on leveraging AI to enhance dental care,
          incorporating a policy for returns and refunds, especially for digital
          products or services like the Gentle AI Smart Toothbrush, is essential
          for maintaining trust and satisfaction among users. Here’s a
          comprehensive example that could serve as a template for
          GentleDental.ai’s Return & Refund Policy:
        </span>
      </div>
      <div className="contant">
        <h4>
          Return & Refund Policy for GentleDental.ai and the Gentle AI Smart
          Toothbrush:{" "}
        </h4>
        <div className="inner-wrapper">
          <h5>Introduction:</h5>
          <span>
            At GentleDental.ai, we strive to ensure that our customers are
            completely satisfied with their purchases, including digital
            services and physical products like the Gentle AI Smart Toothbrush.
            This policy outlines the conditions under which returns and refunds
            are accepte
          </span>
        </div>
        <div className="inner-wrapper">
          <h5>Digital Services:</h5>

          <div className="list">
            <span>
              <strong>1. Cancellation Policy: </strong>
              <span></span>
              <ul>
                <li>
                  Users may cancel their subscription to any of
                  GentleDental.ai’s digital services within 14 days of the
                  initial subscription without any penalty. Full refunds will be
                  issued for cancellations made within this period.
                </li>
                <li>
                  Cancellations made after 14 days may not be eligible for a
                  full refund, depending on the usage and the specific terms of
                  the subscription agreement.
                </li>
              </ul>
            </span>
            <span>
              <strong>2. Refund Eligibility: </strong>
              <ul>
                <li>
                  Refunds for digital services are generally processed in cases
                  where the service did not function as advertised or if there
                  was a significant disruption in service availability.
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      <div className="contant">
        <h4>Physical Products (e.g., Gentle AI Smart Toothbrush):</h4>

        {/* <h5>Digital Services:</h5> */}

        <div className="list">
          <span>
            <strong>1. Return Period:: </strong>
            <span></span>
            <ul>
              <li>
                Customers have 30 days from the date of purchase to return the
                Gentle AI Smart Toothbrush for a refund or exchange, provided
                that the product is in its original condition and packaging.
              </li>
            </ul>
          </span>
          <span>
            <strong>2. Condition of Returned Products: </strong>
            <ul>
              <li>
                Products must be returned unused, in their original packaging,
                and with all included accessories. Returns that do not meet
                these conditions may be subject to a restocking fee or may not
                be accepted for a refund.
              </li>
            </ul>
          </span>
          <span>
            <strong>3. Process for Returns: </strong>
            <ul>
              <li>
                To initiate a return, customers should contact our customer
                service team to obtain a return authorization and shipping
                instructions.
              </li>
              <li>
                Customers are responsible for return shipping costs unless the
                return is due to a defect or error on our part.
              </li>
            </ul>
          </span>
          <span>
            <strong>4. Refund Processing: </strong>
            <ul>
              <li>
                Refunds will be processed to the original method of payment
                within a certain number of days after we receive the returned
                product. This period will be specified in the return
                authorization.
              </li>
            </ul>
          </span>
          <span>
            <strong>5. Exchanges: </strong>
            <ul>
              <li>
                If a product is defective or damaged upon arrival, we will
                exchange it for the same item at no additional cost. Customers
                must contact us within 7 days of receipt to qualify for an
                exchange.
              </li>
            </ul>
          </span>

          <span>
            <strong>Exceptions: </strong>
            <ul>
              <li>
                Some items may be ineligible for returns, including personalized
                or customized products, digital downloads, and opened
                consumables. These exceptions will be clearly noted at the time
                of purchase.
              </li>
            </ul>
          </span>
          <span>
            <strong>Contact Information: </strong>
            <span>
              For questions regarding returns and refunds or to initiate a
              return, please contact our customer service team at
              support@gentledental.ai
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReturnRefund;
