import React, { useEffect, useState } from "react";
import AOS from "aos";
import "./Specialty.scss";
import page from "../../../../Assets/image/signup-1.png";
// import { MDBInput, MDBTextArea, MDBIcon, MDBSelect } from 'mdb-react-ui-kit';
import Multiselect from "multiselect-react-dropdown";
import { getSpecialities } from "../../../../apiCall";
import { toast } from "react-hot-toast";

function Specialty({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  // const onSelect = (selectedList, selectedItem) => {
  // }

  // const onRemove = (selectedList, removedItem) => {
  // }
  // const option = [{ name: 'Endodontist ', id: 1 },
  //                 { name: 'Orthodontist', id: 2 },
  //                 { name: 'Periodontist ', id: 3 },
  //                 { name: 'Prosthodontist ', id: 4 },
  //                 { name: 'Oral and Maxillofacial Surgeon ', id: 5 }]

  const [option, setOption] = useState([]);
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });

    const getData = async () => {
      const res = await getSpecialities();
      console.log(res);
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setOption(res.data);
        // toast.success("Data successfully");
      }
    };

    getData();
  }, []);
  return (
    <section className="section-specialty">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Dental Hygiene for all
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                Great! please choose your specialty Dr {formData.name} from the
                list below.
              </div>
              <div className="input-container">
                <div className="input-inner-container">
                  <Multiselect
                    options={option}
                    onRemove={(selectedList) =>
                      setFormData({ ...formData, specialty: selectedList })
                    }
                    onSelect={(selectedList) =>
                      setFormData({ ...formData, specialty: selectedList })
                    }
                    selectedValues={formData.specialty}
                    displayValue="title"
                  />
                </div>
              </div>

              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <div className="end">
                  {/* <button
                    className="next outline-button "
                    onClick={() => nextSteps()}
                  >
                    Skip
                  </button> */}
                  <button
                    className={`
                    primary-button next ${
                      !formData.specialty.length
                        ? " disable-button disable-hover"
                        : ""
                    }
                    `}
                    onClick={() => nextSteps()}
                    disabled={!formData.specialty.length}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Specialty;
