import React, { useEffect, useState } from "react";
import "./AboutUs.scss";
import aboutUsImage1 from "../../Assets/image/aboutus_image1.png";
import aboutUsImage2 from "../../Assets/image/aboutus_image2.png";
import ourTeam1 from "../../Assets/image/ellipse-1-bg-hTb.png";
import ourTeam2 from "../../Assets/image/ellipse-1-bg-jch.png";
import ourTeam3 from "../../Assets/image/ellipse-1-bg.png";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { getTeamDetail } from "../../apiCall";
import { useQuery } from "react-query";
import { domainName } from "../../Constants";

function AboutUs(props) {
  const { data, isLoading, refetch } = useQuery("faq", getTeamDetail);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const daummy_data = [
    {
      profile_img: ourTeam1,
      name: "Dr. Sudeshna Phukan Hazarika",
      rating: 5,
      designation: "Consultant Dentist",
    },
    {
      profile_img: ourTeam2,
      name: "Dr. Chinmoy Hazarika",
      rating: 3,
      designation: "Chief Clinician",
    },
    {
      profile_img: ourTeam3,
      name: "Dr. Touhid Barkat",
      rating: 4,
      designation: "Consultant Dentist",
    },
  ];
  const [ourTeamMember, setOurTeamMember] = useState(daummy_data);
  return (
    <div className="about-us-conatiner">
      <div className="about-us">
        <div className="title">About Us</div>
        <div className="content">
          GentleDental.ai stands at the forefront of dental healthcare
          innovation, leveraging the power of Artificial Intelligence (AI) to
          transform the way dental care is delivered, personalized, and
          experienced. Our platform integrates AI technology to offer a smarter,
          more efficient approach to dental health, making services more
          accessible and tailored to individual needs.
        </div>
      </div>
      <div className="what-we-do">
        <div className="row-1">
          <div className="image-wrapper">
            <div className="border-box"></div>
            <img src={aboutUsImage1} alt="" />
          </div>
          <div className="content-wrapper">
            <div className="title">What we do?</div>
            <div className="content">
              All treatment procedures at Gentle Dental are painless and very
              less invasive. We adhere to the highest form of infection control
              according to medical standards. All this at very affordable
              prices, and easy payment options.
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="image-wrapper">
            <div className="border-box"></div>
            <img src={aboutUsImage2} alt="" />
          </div>
          <div className="content-wrapper">
            <h3>How GentleDental.ai Uses AI:</h3>
            <div className="content">
              <h5>1. Virtual Dental Assessments:</h5>
              <span>
                Our AI-driven system can analyze symptoms and provide
                preliminary assessments, offering users a quick, initial
                understanding of their dental health status. By inputting their
                symptoms or concerns into our platform, users receive instant
                feedback that guides them on the next steps, whether it's
                booking a virtual consultation or scheduling an in-person visit.
              </span>
              <h5>2. Personalized Treatment Plans:</h5>
              <span>
                AI algorithms analyze user data, including health history and
                current conditions, to craft personalized dental care plans.
                These plans not only address immediate dental needs but also
                incorporate preventive strategies tailored to each user,
                optimizing long-term oral health.
              </span>
              <h5>3. Enhanced Diagnostic Accuracy:</h5>
              <span>
                We utilize AI to augment the diagnostic process, helping dental
                professionals identify and interpret dental issues more
                accurately. From analyzing X-rays to detecting early signs of
                conditions like gum disease or tooth decay, AI supports dentists
                in making informed, precise decisions.
              </span>
              <h5>4. Optimized Patient-Dentist Matching:</h5>
              <span>
                Our platform uses AI to match patients with the dental
                professionals best suited to their specific needs, ensuring that
                every user receives care from a dentist who specializes in
                treating their particular condition or meeting their dental
                health goals.
              </span>
              <h5>5. Educational Tools:</h5>
              <span>
                AI powers interactive, personalized educational content for
                users, helping them understand their dental health better and
                encouraging effective oral hygiene practices. By analyzing user
                interactions and feedback, the platform continually improves the
                relevance and impact of the educational material provided.
              </span>
              <h5>6. Appointment Scheduling and Reminders:</h5>
              <span>
                AI streamlines the appointment scheduling process, making it
                easier for users to book visits at convenient times.
                Additionally, intelligent reminders ensure patients stay on
                track with their appointments and treatment plans, enhancing
                compliance and outcomes.
              </span>
            </div>
            <h3>Our Commitment:</h3>
            <div className="content">
              <span>
                At GentleDental.ai, we are committed to using AI not as a
                replacement for human expertise but as a powerful tool to
                enhance the work of dental professionals and improve patient
                outcomes. We believe in the synergy between technology and human
                touch, ensuring that our platform respects the importance of
                personal interaction in dental care.
              </span>
              <span>
                By leveraging AI, GentleDental.ai aims to make dental healthcare
                more predictive, personalized, and preventive, aligning with our
                goal of improving oral health outcomes for all our users. We
                invite you to explore the future of dental care with
                GentleDental.ai, where innovation meets compassion and
                expertise.
              </span>
            </div>
          </div>
        </div>
      </div>

      {data?.data && (
        <div className="our-team">
          <div className="title">OUR TEAM</div>
          <div className="card-wrapper">
            {
              data.data.map((member) => (
                <div className="profile-card">
                  <img src={`${domainName}/uploads/${member?.profile_image}`} alt="" />
                  <div className="name">{member.name}</div>

                  <div className="designation">{member.designation}</div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AboutUs;
