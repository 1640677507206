import React, { useEffect } from "react";
import AOS from "aos";
import "./RegistreationNo.scss";
import page from "../../../../Assets/image/signup-3.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";

function RegistreationNo({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  const [regNoErr, setRegNoErr] = useState("");
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    if (value.length < 1) {
      setRegNoErr("Registration number is required");
      return setFormData({ ...formData, registrationNumber: "" });
    }
    setFormData({ ...formData, registrationNumber: value });
    setRegNoErr("");
  };
  return (
    <section className="section-registreation-no">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              AI-powered diagnosis for healthier teeth
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              <div className="title">
                <strong>Hi Dr. {formData.name}</strong> We are glad to have you
                here at gentledental, where we will help people achieve good
                dental hygiene together. <span>&#x1F60E;</span>{" "}
              </div>
              <div className="sub-title">
                Before we proceed, help us with your registration no. for
                verification purposes.
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter your registration no."
                    value={formData.registrationNumber}
                    name="registrationNumber"
                    size="sm"
                    className="input"
                    id="form1"
                    type="text"
                    onChange={handleInputChange}
                  />
                  <small className="error">{regNoErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !formData.registrationNumber
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.registrationNumber}
                  onClick={() => nextSteps()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default RegistreationNo;
