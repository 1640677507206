import React, { useEffect } from "react";
import AOS from "aos";
import "./Fees.scss";
import page from "../../../../Assets/image/signup-2.png";
import { useState } from "react";

function Fees({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  const [feesInputErr, setFeesInputErr] = useState("");
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.value.length < 1) {
      setFeesInputErr("Consultation fees is required");
      return setFormData({ ...formData, checkupPrice: "" });
    }
    setFeesInputErr("");
    setFormData({ ...formData, checkupPrice: e.target.value });
  };
  return (
    <section className="section-fees">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Dental Hygiene for all
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                That’s great! Just a few more steps to go...
                <br />
                <br />
                Now, Please tell us about your expected consultation fees
              </div>
              <div className="input-container">
                <div className="input-box">
                <div className="input-inner-container">
                  <i class="fas fa-rupee-sign"></i>
                  <input
                    type="number"
                    placeholder=""
                    value={formData.checkupPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <small className="error">{feesInputErr}</small>
                </div>
              </div>

              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !formData.checkupPrice
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.name}
                  onClick={() => nextSteps()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Fees;
