import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Home.scss";
import banner from "../../Assets/image/Rectangle 1652.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStateValue } from "../../StateProvider";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  const [{ isLoggedIn, user_type }] = useStateValue();
  const navigate = useNavigate();

  console.log(isLoggedIn, user_type);
  if (isLoggedIn === true && user_type === "doctor") {
    navigate("/dashboardhome");
  }
  useEffect(() => {
    console.log(isLoggedIn, user_type);
    if (isLoggedIn === true && user_type === "doctor") {
      console.log("navigate");
      navigate("/dashboardhome");
    }
  }, []);
  return (
    <section className="section-home">
      <div className="comman-container">
        <div className="inner-container">
          <div className="left" data-aos="fade-up" data-aos-duration="1000">
            <h1>Check Your Teeth online With Ai.</h1>
            <h2>
              gentledental.ai is dedicated to revolutionizing dental care by
              merging expert knowledge with innovative technology.
            </h2>
            <Link to="/chat-bot">
              <button>See How It Works?</button>
            </Link>
          </div>
          <div className="right" data-aos="fade-up" data-aos-duration="1000">
            <img src={banner} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
