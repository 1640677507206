import React, { useEffect } from "react";
import "./Faqs.scss";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useQuery } from "react-query";
import {  getFaq } from "../../apiCall";

const Faqs = () => {
  const { data } = useQuery("faq", getFaq);
  console.log(data);
  const dummy_faq_fa = [
    {
      question: "Do I need to arrive early for my first appointment?",
      answer: "Yes. Please arrive 10-15 minutes early.",
    },
    {
      question: " What do I need to bring to my first appointment?",
      answer:
        "Dental Insurance Card (if applicable).\n Identification such as Driver's License or State ID\n Patient Health History Form",
    },
    {
      question: "How long will my first appointment last?",
      answer:
        "It varies, but please plan on 1 to 1.5 hours for the first visit.. Please arrive 10-15 minutes early.",
    },
  ];
  const dummy_faq_gd = [
    {
      question: "Why should I go to the dentist regularly",
      answer:
        "Many people do not see a dentist on a regular basis. They only go when they have a problem. This is known as 'crisis treatment' versus 'preventive treatment.' While these patients may feel they are saving money, it often ends up costing much more in dollars and time. This is because many dental problems do not have symptoms until they reach the advanced stages of the disease process. An example is tooth decay. It is typical to hear, 'Nothing hurts... I don't have any problems.'\nTooth decay often does not hurt until it gets close to the nerve of the tooth. It is not uncommon to see a patient with a huge cavity who has never felt a thing. The dentist can usually detect a cavity 3-4 years before it develops any symptoms. This early detection can help you prevent root canal treatment.",
    },
    {
      question: "Why should I floss, isn't brushing enough?",

      answer:
        "Flossing reduces the number of bacteria in your mouth. There are millions of these microscopic creatures feeding on food particles left on your teeth. These bacteria live in plaque which can be removed by flossing. Brushing your teeth gets rid of some of the bacteria in your mouth. Flossing gets rid of the bacteria the toothbrush can't get to. That's the bacteria hiding in the tiny spaces between your teeth. If you do not floss, you allow plaque to remain between your teeth. Eventually it hardens into tartar. Plaque can be removed by brushing. Only the dentist can remove tartar.\nAsk your dentist to show you the proper way to floss. You will both notice the difference at the next cleaning appointment.\nIdentification such as Driver's License or State ID\nPatient Health History Form        ",
    },
  ];
  const [firstAppointmentFaq, setFirstAppointmentFaq] = useState(dummy_faq_fa);
  const [generalDentistryFaq, setGeneralDentistryFaq] = useState(dummy_faq_gd);
  const textLines = (text) =>
    text.split("\n").map((line, index, array) => (
      // Render each line as a separate element
      // Include a key for each element and avoid adding a <br> after the last line
      <span key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </span>
    ));
  const handelSearch = (event) => {
    const input = event.target.value;
    if (input) {
      setFirstAppointmentFaq(
        dummy_faq_fa.filter((value) =>
          value.question.toLocaleLowerCase().includes(input)
        )
      );
      setGeneralDentistryFaq(
        dummy_faq_gd.filter((value) =>
          value.question.toLocaleLowerCase().includes(input)
        )
      );
    } else {
      setFirstAppointmentFaq(dummy_faq_fa);
      setGeneralDentistryFaq(dummy_faq_gd);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="faqs">
      <div className="headig-wrapper">
        <div className="heading">Welcome. How can we help you?</div>
        {/* <div className="search-bar">
          <IoSearch />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => handelSearch(e)}
          />
        </div> */}
      </div>
      <div className="question-answer-wrapper">
        {data?.data && data?.data.map((faq) => 
        <>
          <div className="title">
            {faq?.title}
            <div className="subtitle">
              {faq?.content}
            </div>
          </div>
          {faq.question.map((questionAnswer, index) => (
            <div className="question-answer" key={`gd-${index}`}>
              <div className="question">
                {index + 1}. {questionAnswer.question}
              </div>
              <div className="answer">{textLines(questionAnswer.answer)}</div>
            </div>
          ))}
        </>
        )}
      </div>
    </div>
  );
};

export default Faqs;
