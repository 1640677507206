import React, { useEffect, useState } from "react";
import "./MembershipPlan.scss";
import { displayRazorpay } from "../../Utils/Razorpay";
import { useStateValue } from "../../StateProvider";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getMembershipPlans, hasRecentPayment } from "../../apiCall";

const MembershipPlan = () => {
  const userTypeList = ["patient", "doctor"];
  const [{ isLoggedIn, user_type }] = useStateValue();
  const [activeTab, setActiveTab] = useState("patient");
  const { data } = useQuery("faq", getMembershipPlans);
  const openRazorPay = async (planId) => {
    if (isLoggedIn) {
      const recentPayment = await hasRecentPayment();
      if (recentPayment.status[0].Response === 200) {
        if (recentPayment.data.hasRecentPayment) {
          toast(
            "Currently, your subscription is not active.  Please wait for a while. You recently made a payment. We'll let you know by email when your payment is received.",
            {
              icon: "⚠️",
            }
          );
        } else {
          const data = displayRazorpay(planId);
        }
      } else {
        toast.error("something went wrong. please reload the page.");
      }
    } else {
      toast.error("Sign in Required");
    }
  };
  useEffect(() => {
    if (userTypeList.includes(user_type)) {
      setActiveTab(user_type);
    }
  }, [user_type]);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="membership-plan-container">
      <h3>Membership Plan</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      {!isLoggedIn && (
        <div className="user-type">
          <button
            className={
              activeTab === "patient" ? "primary-button" : "outline-button"
            }
            onClick={() => setActiveTab("patient")}
          >
            Patient
          </button>
          <button
            className={
              activeTab === "doctor" ? "primary-button" : "outline-button"
            }
            onClick={() => setActiveTab("doctor")}
          >
            Doctor
          </button>
        </div>
      )}
      {data?.data && (
        <>
          {activeTab === "patient" && (
            <>
              {data.data.length > 0 ? (
                <div className="plans">
                  {data.data.map((plan, index) => {
                    if (plan.user_type === "patient") {
                      return (
                        <div className="plan-details" id={index}>
                          <p className="duration">{plan?.plan_name}</p>
                          <p className="price">
                            ₹ {plan.price - plan.discount}
                            {plan.price > 0 && plan.discount > 0 && (
                              <span className="discount">
                                -₹ {plan.discount} off
                              </span>
                            )}
                          </p>

                          {plan.price !== 0 && (
                            <button
                              className="btn"
                              onClick={() => openRazorPay(plan.id)}
                            >
                              Choose plan
                            </button>
                          )}
                          <p className="plan-desc">
                            <ul>
                              {plan?.feature.map((featureDetail) => (
                                <li>{featureDetail.title}</li>
                              ))}
                            </ul>
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                <div className="plan-not-found">Membership Plans not available</div>

              )}
            </>
          )}
          {activeTab === "doctor" && (
            <>
              {data.data.length > 0 ? (
                <div className="plans">
                  {data.data.map((plan, index) => {
                    if (plan.user_type === "doctor") {
                      return (
                        <div className="plan-details" id={index}>
                          <p className="duration">{plan?.plan_name}</p>
                          <p className="price">
                            ₹ {plan.price - plan.discount}
                            {plan.price > 0 && plan.discount > 0 && (
                              <span className="discount">
                                -₹ {plan.discount} off
                              </span>
                            )}
                          </p>
                          {plan.price !== 0 && (
                            <button
                              className="btn"
                              onClick={() => openRazorPay(plan.id)}
                            >
                              Choose plan
                            </button>
                          )}
                          <p className="plan-desc">
                            <ul>
                              {plan?.feature.map((featureDetail) => (
                                <li>{featureDetail.title}</li>
                              ))}
                            </ul>
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                <div className="plan-not-found">Membership Plans not available</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MembershipPlan;
