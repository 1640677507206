import React, { useEffect, useState } from "react";
import "./EmailVerify.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { emailVerify } from "../../apiCall";
import { useStateValue } from "../../StateProvider";

function EmailVerify() {
  const [{ isLoading }, dispatch] = useStateValue();

  const { token } = useParams();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "SET_LOADING", status: true });
      const re = await emailVerify({ token: token });
      dispatch({ type: "SET_LOADING", status: false });

      if (re?.status[0].Response === 200) {
        setIsEmailVerified(true);
      }
    };
    fetchData();
  }, [token]);
  return (
    <div className="email-verify-wrapper">
      {!isLoading && (
        <>
          {isEmailVerified ? (
            <div class="email-verify">
              <h1 class="header">Email Verified Successfully!</h1>
              <p>
                Your email has been successfully verified. You can now access
                all the features of our site.
              </p>
              <p>
                {/* <link rel="stylesheet" href="" /> */}
                <Link to="/">Go to Homepage</Link>
              </p>
              <div class="footer">
                <p>If you have any questions, please contact support.</p>
              </div>
            </div>
          ) : (
            <div class="email-verify-error">
              <h1 class="header"> Something Went Wrong!</h1>
              <p>
                I’m sorry, something went wrong when we tried to verify your
                account. Please try again later or contact our support team at
                support@service.com. We apologize for any inconvenience this may
                have caused you. Thank you for your patience and understanding.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EmailVerify;
