import React, { useEffect } from "react";
import "./ShippingDelivery.scss";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

const ShippingDelivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="shipping-delivery">
      <div className="title">Shipping & Delivery</div>
      <div className="contant">
        <span>
          At GentleDental.ai, we are committed to making your dental health
          journey as smooth and stress-free as possible, which includes
          providing clear information on how we handle shipping and delivery for
          any products or services that may require it. While we primarily focus
          on direct dental services and in-clinic treatments, here's a general
          overview of how shipping and delivery aspects might be relevant to
          you:
        </span>
        <div className="list">
          <span>
            <strong>1. Dental Products: </strong>
            If you're obtaining dental care products like custom dental
            aligners, mouthguards, or aftercare kits directly from us, we ensure
            that these items are delivered to you in a timely and secure manner.
            We partner with reputable delivery services to ship your products
            straight to your doorstep or preferred delivery address.
          </span>
          <span>
            <strong>2. Prescriptions: </strong>
            For medications prescribed following your dental treatments, we
            might facilitate these through local pharmacies that offer delivery
            services. This ensures you receive your necessary medications
            without any inconvenience.
          </span>
          <span>
            <strong>3. Documents and Records: </strong>
            Should you need copies of your dental records, treatment plans, or
            any other documents, we can provide these electronically for
            immediate access. If hard copies are required, we can arrange for
            these to be mailed to your specified address.
          </span>
          <span>
            <strong>4. Appointments and Consultations: </strong>
            We leverage technology to offer virtual consultations and follow-ups
            when possible, saving you the trip to our clinic. For treatments
            that require in-person visits, we provide all the necessary
            information and support to ensure your appointment goes smoothly,
            including scheduling and reminders.
          </span>
        </div>
        <span>
          Please note, for specific details about shipping and delivery related
          to a particular product or service you're interested in, we recommend
          contacting us directly. Our team is here to provide you with the
          information you need, including costs, delivery times, and any special
          instructions you might need to follow.
        </span>
        <span>
          For further assistance or to schedule an appointment, please reach out
          to us directly through our website or by calling our office. We're
          here to ensure your experience with GentleDental.ai is pleasant and
          meets all your dental care needs.
        </span>
      </div>
    </div>
  );
};

export default ShippingDelivery;
