import { api, domainName } from "./Constants";
import axios from "axios";

// ********************************** google loggin  **********************************
export async function GoogleLogin(data) {
  return fetch(`${domainName}${api.googleLogin}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw new Error("Updating patient information: " + error.message);
    });
}

// *************************************************** doctor google signup form ****************************************************************

export async function DoctorGoogleSignUpForm(userData) {
  const token = localStorage.getItem("token");
  const data = await fetch(`${domainName}${api.doctorGoogleSignUpForm}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** get Dentist Appointments ****************************************************************

export async function PatientGoogleSignUpForm(userData) {
  const token = localStorage.getItem("token");
  const data = await fetch(`${domainName}${api.patientGoogleSignUpForm}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}

// *************************************************** demo ****************************************************************

export async function demo(userData) {
  const data = await fetch(`${domainName}${api.demo}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** email verify ****************************************************************

export async function emailVerify(userData) {
  const data = await fetch(`${domainName}${api.emailVerify}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** email check ****************************************************************

export async function emailCheck(email) {
  const data = await fetch(`${domainName}${api.emailCheck}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  }).then((data) => data.json());
  return data;
}

// *************************************************** phone number check ****************************************************************

export async function phoneNumberCheck(phonenumber) {
  const data = await fetch(`${domainName}${api.phoneNumberCheck}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(phonenumber),
  }).then((data) => data.json());
  return data;
}

// *************************************************** dentist signup ****************************************************************

export async function dentistSignup(userData) {
  const data = await fetch(`${domainName}${api.dentistSignup}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** get Dentist Appointments ****************************************************************

export async function getDentistAppointments(email) {
  const token = localStorage.getItem("token");

  const data = await fetch(`${domainName}${api.dentistAppointments}/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** patient signup ****************************************************************

export async function patientSignup(userData) {
  const data = await fetch(`${domainName}${api.patientSignup}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** dentist sign in ****************************************************************

export async function dentistSignin(userData) {
  const data = await fetch(`${domainName}${api.dentistSignin}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** patient sign in ****************************************************************

export async function signin(userData) {
  const data = await fetch(`${domainName}${api.login}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
  return data;
}
// *************************************************** fetch chatbot steps ****************************************************************

export async function fetchChetBotStep() {
  return fetch(`${domainName}${api.chatBotStep}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}
// *************************************************** fetch doctor profile ****************************************************************

export async function fetchDoctorProfile(token) {
  return fetch(`${domainName}${api.doctorProfile}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// *************************************************** post chatbot convo ****************************************************************

export async function postChatBotConvo( data) {
  console.log(data)
  const token = window.localStorage.getItem("token");

  return fetch(`${domainName}${api.chatBotHistory}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

// *************************************************** fetch group list ****************************************************************

export async function getGroupList(token) {
  return fetch(`${domainName}${api.groupList}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// *************************************************** fetch group messages ****************************************************************

export async function getGroupLMessages(token, groupId) {
  return fetch(`${domainName}${api.groupMessages}/${groupId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// *************************************************** post new message ****************************************************************

export async function postGroupMessage(token, message) {
  return fetch(`${domainName}${api.groupMessages}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(message),
  }).then((data) => data.json());
}

// *************************************************** post File ****************************************************************

export const postGroupMessageFile = async (groupId, data) => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return axios
    .post(`${domainName}${api.groupMessagesFile}/${groupId}`, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

// *************************************************** fetch expertise ****************************************************************

export async function getExperties() {
  return fetch(`${domainName}${api.experties}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// *************************************************** fetch speciality ****************************************************************

export async function getSpecialities() {
  return fetch(`${domainName}${api.specilities}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}
// *************************************************** get Prompt Question ****************************************************************

export async function getPromptQuestion() {
  return fetch(`${domainName}${api.getPromptQuestion}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// *************************************************** fetch patient profile ****************************************************************

export async function fetchPatientProfile(token) {
  console.log("Api call started...");
  return fetch(`${domainName}${api.patientProfile}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}

// **************************** Update Patient Address *****************************************

export async function updatePatientAddress(token, addressData) {
  return fetch(`${domainName}${api.patientUpdateAddress}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(addressData),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status[0].Response === 200) {
        return data;
      } else {
        throw new Error(data.status[0].Message);
      }
    })
    .catch((error) => {
      throw new Error("updating patient address: " + error.message);
    });
}

//******************************** Update patient Information ******************************* */

export async function updatePatientInformation(token, informationData) {
  console.log(token);
  console.log("started api call");
  return fetch(`${domainName}${api.patientUpdateInformation}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(informationData),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      throw new Error("Updating patient information: " + error.message);
    });
}

// ************************** Get Appointment *************************************

export async function fetchAppointment(token) {
  const storedToken = window.localStorage.getItem("token");

  return fetch(`${domainName}${api.appointment}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storedToken}`,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
}
// ************************** update Appointment *************************************

export async function updateAppointment(data) {
  const storedToken = window.localStorage.getItem("token");

  return fetch(`${domainName}${api.appointment}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storedToken}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
// ************************** cancel Appointment *************************************

export async function cancelAppointment(data) {
  const storedToken = window.localStorage.getItem("token");

  return fetch(`${domainName}${api.cancelAppointment}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storedToken}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

// ************************* Patient Submit Image to Backend *****************************

export async function updateImagePatient(imgInput, token) {
  const formData = new FormData();
  formData.append("image", imgInput.files[0]);
  return fetch(`${domainName}${api.patientImage}`, {
    method: "PATCH",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
}

//******************************** Update Doctor Information ******************************* */

export async function updateDoctorInformation(token, userData) {
  console.log(userData);
  return fetch(`${domainName}${api.doctorPersonalInfoUpdate}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((data) => data.json());
}

//******************************** Update Doctor social media ******************************* */

export async function updateDoctorSocial(token, data) {
  return fetch(`${domainName}${api.doctorSocialUpdate}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

//******************************** Update Doctor website ******************************* */

export async function updateDoctorWebsite(token, data) {
  return fetch(`${domainName}${api.doctorWebsiteUpdate}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

//******************************** Update Doctor dentist details ******************************* */

export async function updateDoctorDentist(token, data) {
  return fetch(`${domainName}${api.doctorDentistUpdate}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

//******************************** Update Doctor Address ******************************* */

export async function updateDoctorAddress(token, data) {
  return fetch(`${domainName}${api.doctorAddressUpdate}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

//******************************** change password ******************************* */

export async function changePassword(token, data) {
  return fetch(`${domainName}${api.changePassword}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

// *************************************************** post profileImg ****************************************************************

export const postProfileImage = async (data) => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return axios
    .patch(`${domainName}${api.doctorProfileImgUpdate}`, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

// *************************** Profile Image Patient **************************

export const profileImageUpdate = async (data) => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return axios
    .patch(`${domainName}${api.patientImage}`, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

// Patient social links updation
export const patientSocialLinksUpdate = async (data, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return await axios.patch(
    `${domainName}${api.patientUpdateSocialLinks}`,
    data,
    { headers }
  );
};
// *************************** Start payment **************************

export const paymentStart = async (data) => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return fetch(`${domainName}${api.payment}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
};

// *************************** activate Subscription **************************

export const activateSubscription = async (data) => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return fetch(`${domainName}${api.activateSubscription}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
};
// *************************** check recent payment **************************

export const hasRecentPayment = async () => {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };

  return fetch(`${domainName}${api.hasRecentPayment}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    body: JSON.stringify(),
  }).then((data) => data.json());
};

// ************************** send to gpt *************************************

export async function sendGPT(data) {
  return fetch(`${domainName}${api.sendToGPT}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
// **************************subscriptionHistory *************************************

export async function getSubscriptionHistory(data) {
  const local_access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${local_access_token}`,
  };
  return fetch(`${domainName}${api.subscriptionHistory}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    // body: JSON.stringify(data),
  }).then((data) => data.json());
}

// ************************** get faq *************************************

export async function getFaq() {

  return fetch(`${domainName}${api.faq}`, {
    method: "get",
  }).then((data) => data.json());
}
// ************************** get membership plan *************************************

export async function getMembershipPlans() {

  return fetch(`${domainName}${api.membership}`, {
    method: "get",
  }).then((data) => data.json());
}
// ************************** get tream detail *************************************

export async function getTeamDetail() {

  return fetch(`${domainName}${api.teamMember}`, {
    method: "get",
  }).then((data) => data.json());
}
