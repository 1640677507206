import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import {
  fetchChetBotStep,
  hasRecentPayment,
  postChatBotConvo,
  sendGPT,
} from "../../apiCall";
import "./chatBot.scss";
import botPic from "../../Assets/icons/bot.svg";
import { ThemeProvider } from "styled-components";
import { InfinitySpin } from "react-loader-spinner";
// import { toast } from "react-hot-toast";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import ReactDOM from "react-dom/client";
import {
  BookAppointmentModal,
  PurchaseModal,
} from "../../components/modal/modal";
import { ChATGPT_API_KEY } from "../../Constants";
import SpeechToText from "../../components/SpeechToText/SpeechToText";
import TextToSpeech from "./components/TextToSpeech";
import { toast } from "react-toastify";
import ImageUpload from "./components/ImageUpload";
import { IoMdCloseCircle } from "react-icons/io";
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content:
    "Explain like you are talking with a dental patient. and provide a 200-word response.",
};

// import BookAppointment from './BookAppointment/BookAppointment';
const botTypingLoader = (messageId) => {
  const target = document.getElementsByClassName("rsc-content")[0];
  // Create a parent element (e.g., a <div>)
  const parentElement = document.createElement("div");
  parentElement.classList.add("sc-dnqmqq", "efROPc", "rsc-ts", "rsc-ts-bot");

  // Create a child element (e.g., a <p>) and set its content
  const childElement1 = document.createElement("div");
  childElement1.classList.add("sc-htoDjs", "vmYlS", "rsc-ts-image-container");

  const imgElement = document.createElement("img");
  imgElement.classList.add("sc-gzVnrw", "cwuCQv", "rsc-ts-image");
  imgElement.src =
    "data:image/svg+xml,%3csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M303 70a47 47 0 1 0-70 40v84h46v-84c14-8 24-23 24-40z' fill='%2393c7ef'/%3e%3cpath d='M256 23v171h23v-84a47 47 0 0 0-23-87z' fill='%235a8bb0'/%3e%3cpath fill='%2393c7ef' d='M0 240h248v124H0z'/%3e%3cpath fill='%235a8bb0' d='M264 240h248v124H264z'/%3e%3cpath fill='%2393c7ef' d='M186 365h140v124H186z'/%3e%3cpath fill='%235a8bb0' d='M256 365h70v124h-70z'/%3e%3cpath fill='%23cce9f9' d='M47 163h419v279H47z'/%3e%3cpath fill='%2393c7ef' d='M256 163h209v279H256z'/%3e%3cpath d='M194 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%233c5d76'/%3e%3cpath d='M380 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%231e2e3b'/%3e%3cpath d='M186 349a70 70 0 1 0 140 0H186z' fill='%233c5d76'/%3e%3cpath d='M256 349v70c39 0 70-31 70-70h-70z' fill='%231e2e3b'/%3e%3c/svg%3e";
  imgElement.alt = "avatar";
  childElement1.appendChild(imgElement);

  const childElement2 = document.createElement("div");
  childElement2.classList.add("sc-bZQynM", "idTwaj", "rsc-ts-bubble");
  childElement2.id = `custome-message-${messageId}`;
  const spanElement = document.createElement("span");
  spanElement.classList.add("rsc-loading");
  const chieldSpanElement = document.createElement("span");
  chieldSpanElement.classList.add("sc-bdVaJa", "hseFPs");
  chieldSpanElement.textContent = ".";
  const chieldSpanElement2 = document.createElement("span");
  chieldSpanElement2.classList.add("sc-bdVaJa", "hseFPs");
  chieldSpanElement2.textContent = ".";
  const chieldSpanElement3 = document.createElement("span");
  chieldSpanElement3.classList.add("sc-bdVaJa", "hseFPs");
  chieldSpanElement3.textContent = ".";
  spanElement.appendChild(chieldSpanElement);
  spanElement.appendChild(chieldSpanElement2);
  spanElement.appendChild(chieldSpanElement3);
  childElement2.appendChild(spanElement);

  parentElement.appendChild(childElement1);
  parentElement.appendChild(childElement2);
  target.appendChild(parentElement);
};
const createBotElement = (text, messageId, isSpeechRunning, handelSpeech) => {
  console.log(text);
  const target = document.getElementById(`custome-message-${messageId}`);
  console.log(target);
  const childElements = target.querySelectorAll("*");
  childElements.forEach((child) => {
    child.remove();
  });
  console.log(target);
  const childElement1 = document.createElement("div");
  childElement1.textContent = text;
  console.log(text);
  target.appendChild(childElement1);
  const childElement2 = document.createElement("div");
  childElement2.style.display = "flex";
  childElement2.style.width = "100%";
  childElement2.style.justifyContent = "flex-end";
  const rootInstance = ReactDOM.createRoot(childElement2);

  rootInstance.render(
    <TextToSpeech
      text={text}
      isSpeechRunning={isSpeechRunning}
      handelSpeech={handelSpeech}
    />
  );
  target.appendChild(childElement2);
};

const createOptonElement = (text, fun) => {
  const target = document.getElementsByClassName("rsc-content")[0];
  // Create a parent element (e.g., a <div>)
  const parentElement = document.createElement("div");
  parentElement.classList.add("sc-dnqmqq", "efROPc", "rsc-ts", "rsc-ts-bot");

  // Create a child element (e.g., a <p>) and set its content
  const childElement = document.createElement("ul");
  childElement.classList.add(
    "sc-EHOje",
    "jvzENE",
    "rsc-os",
    "book-appointment-wrapper"
  );

  const subChildElement = document.createElement("li");
  subChildElement.classList.add("sc-htpNat", "GgOGn", "rsc-os-option");
  const subChildBottonElement = document.createElement("button");
  subChildBottonElement.classList.add(
    "sc-bxivhb",
    "hIgTkN",
    "rsc-os-option-element",
    "primary-button"
  );
  subChildBottonElement.textContent = text;
  subChildBottonElement.onclick = fun;

  subChildElement.appendChild(subChildBottonElement);
  childElement.appendChild(subChildElement);
  parentElement.appendChild(childElement);
  target.appendChild(parentElement);
};
const createUserElement = (text, image = "") => {
  console.log(image);
  const target = document.getElementsByClassName("rsc-content")[0];
  // Create a parent element (e.g., a <div>)
  const parentElement = document.createElement("div");
  parentElement.classList.add("sc-dnqmqq", "eBYsSN", "rsc-ts", "rsc-ts-user");

  // Create a child element (e.g., a <p>) and set its content
  const childElement1 = document.createElement("div");
  childElement1.classList.add("sc-dnqmqq", "eBYsSN", "rsc-ts-user-image");

  const imgElement = document.createElement("img");
  imgElement.classList.add("sc-gzVnrw", "eijbEu", "rsc-ts-image");
  imgElement.src =
    "data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e";
  imgElement.alt = "avatar";
  childElement1.appendChild(imgElement);

  const imageWrapper = document.createElement("div");
  const childElement2 = document.createElement("div");
  childElement2.classList.add("sc-bZQynM", "iOCYje", "rsc-ts-bubble");
  childElement2.textContent = text;

  if (image) {
    const imagePreviewElement = document.createElement("div");
    imagePreviewElement.classList.add("user-upload-image-preview");
    const imageElement = document.createElement("img");
    imageElement.classList.add("user-upload-image");
    imageElement.src = image;
    imagePreviewElement.appendChild(imageElement);

    imageWrapper.appendChild(imagePreviewElement);
    imageWrapper.appendChild(childElement2);
    parentElement.appendChild(imageWrapper);
  } else {
    parentElement.appendChild(childElement2);
  }

  parentElement.appendChild(childElement1);
  target.appendChild(parentElement);
};
const handelButtonClick = () => {
  const inputElement = document.getElementsByClassName("rsc-input")[0];

  // Create a new keyboard event for the Enter key press
  const enterKeyEvent = new KeyboardEvent("keydown", {
    key: "Enter",
    keyCode: 13,
    bubbles: true,
    cancelable: true,
  });

  // Dispatch the Enter key press event to the input element
  inputElement.dispatchEvent(enterKeyEvent);
};

const handelInputChnage = (event) => {
  if (event.target.value === "") {
    try {
      const sendBtnElement = document.getElementsByClassName(
        "rsc-submit-button"
      )[0];
      const speechBtnElement = document.getElementsByClassName(
        "speech-button-container"
      )[0];
      sendBtnElement.classList.add("display-none");
      speechBtnElement.classList.remove("display-none");
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const sendBtnElement = document.getElementsByClassName(
        "rsc-submit-button"
      )[0];
      const speechBtnElement = document.getElementsByClassName(
        "speech-button-container"
      )[0];
      sendBtnElement.classList.remove("display-none");
      speechBtnElement.classList.add("display-none");
    } catch (error) {
      console.log(error);
    }
  }
};

const enableInput = (fun, imageUpload) => {
  const inputElement = document.getElementsByClassName("rsc-input")[0];
  const btnElement = document.getElementsByClassName("rsc-submit-button")[0];
  btnElement.classList.add("display-none");
  const divElement = document.getElementsByClassName("sc-cSHVUG")[0];
  divElement.removeChild(inputElement);
  divElement.removeChild(btnElement);

  // const imagePreviewElement = document.createElement("div");
  // imagePreviewElement.classList.add("image-preview");
  // const imageElement = document.createElement("img");
  // imageElement.src = image
  // imagePreviewElement.appendChild(imageElement)
  const parentDivElement = document.createElement("div");
  parentDivElement.classList.add("custome-input-container");
  const parentInputElement = document.createElement("input");
  parentInputElement.classList.add("sc-kAzzGY", "iAuOXT", "rsc-input");
  parentInputElement.placeholder = "Type the message ...";
  parentInputElement.disabled = false;
  parentInputElement.addEventListener("input", handelInputChnage);
  const speechButtonElement = document.createElement("div");
  speechButtonElement.classList.add("speech-button-container");
  const imageUploadButtonElement = document.createElement("div");
  imageUploadButtonElement.classList.add("speech-button-container");
  // divElement.appendChild(imagePreviewElement);
  divElement.appendChild(parentDivElement);
  parentDivElement.appendChild(parentInputElement);
  parentDivElement.appendChild(btnElement);
  parentDivElement.appendChild(speechButtonElement);
  parentDivElement.appendChild(imageUploadButtonElement);
  const rootInstance = ReactDOM.createRoot(speechButtonElement);
  rootInstance.render(<SpeechToText handleSend={fun} />);
  const imageUploaadInstance = ReactDOM.createRoot(imageUploadButtonElement);
  imageUploaadInstance.render(
    <ImageUpload handleSend={fun} handleImageUpload={imageUpload} />
  );

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      // Call your function here
      const message = parentInputElement.value;
      parentInputElement.value = "";
      const imagePreviewElement = document.getElementsByClassName(
        "image-filed"
      )[0];
      let image = "";
      if (imagePreviewElement) {
        image = imagePreviewElement?.src;
      }
      fun(message, image);
      parentInputElement.value = "";

      event.stopPropagation();
    }
  }
  parentInputElement.addEventListener("keydown", handleKeyPress);
  document.getElementsByClassName("rsc-submit-button")[0].disabled = false;
  document.getElementsByClassName(
    "rsc-submit-button"
  )[0].onclick = handelButtonClick;
};

const imagePreview = (imageSrc) => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

  const oldImgElement = document.getElementsByClassName("image-filed")[0];
  console.log("sdfsdfsd", oldImgElement);
  if (oldImgElement) {
    oldImgElement.src = imageSrc;
  } else {
    const chatWindowElement = document.getElementsByClassName("rsc-content")[0];
    chatWindowElement.classList.add("chat-window-image-preview");
    const divElement = document.getElementsByClassName("sc-cSHVUG")[0];
    if (!divElement) {
      console.error("Element with class 'sc-cSHVUG' not found in the DOM.");
      return; // Exit the function if the element is not found
    }
    const oldImagePreivewElement = document.getElementsByClassName(
      "image-preview"
    )[0];

    console.log(oldImagePreivewElement, "eeeeeeeeeeeeeeeeeeeeeeeeee");
    const imagePreviewElement = document.createElement("div");
    imagePreviewElement.classList.add("image-preview");
    const imageElement = document.createElement("img");
    imageElement.classList.add("image-filed");
    imageElement.src = imageSrc;

    const removeImageElement = document.createElement("div");
    removeImageElement.classList.add("remove-icon");
    const imageUploaadRemoveInstance = ReactDOM.createRoot(removeImageElement);
    imageUploaadRemoveInstance.render(
      <IoMdCloseCircle
        size={20}
        // fill="red"
        onClick={() => {
          const divsElement = document.getElementsByClassName(
            "image-preview"
          )[0];
          if (divsElement) {
            divsElement.remove();
            const chatWindowElement = document.getElementsByClassName(
              "rsc-content"
            )[0];
            chatWindowElement.classList.remove("chat-window-image-preview");
          }
        }}
      />
    );

    imagePreviewElement.appendChild(imageElement);
    imagePreviewElement.appendChild(removeImageElement);
    divElement.appendChild(imagePreviewElement);
  }
};

function MyChatBot() {
  useEffect(() => {}, []);
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [isSpeechRunning, setIsSpeechRunning] = useState(false);

  const [image, setImage] = useState(null);
  const analyzeImage = async (chatMessages, image, message) => {
    console.log("dddddddddddddddd", message, image);
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      let content = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      if (messageObject?.image) {
        content = [
          {
            type: "text",
            text: `Analyze the following image and determine if it is related to teeth or dental. If it is not, please respond with: 'This image is not related to dental. Please upload a clear dental image.' If it is related to dental health, then answer :${message} `, // Instruction for the model
          },
          {
            type: "image_url",
            image_url: {
              url: messageObject?.image,
            },
          },
        ];
      } else {
        content = messageObject.message;
      }
      return { role: role, content: content };
    });

    const base64String = image.split(",")[1];

    const apiKey = ChATGPT_API_KEY;

    // Set up the request headers
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };

    // Set up the request payload with the base64 image
    // const payloadContent =
    const payload = {
      model: "gpt-4o",
      messages: [
        ...apiMessages,

        {
          role: "user",
          content: message,
        },
      ],
      max_tokens: 300,
    };

    try {
      // Make the API request to OpenAI
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      );

      // Parse the JSON response
      const data = await response.json();

      if (data) {
        messages = [
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ];
        createBotElement(
          data.choices[0].message.content,
          messages.length,
          isSpeechRunning,
          handelSpeech
        );
        setTimeout(1000);

        botTypingLoader(`bookapponitment-${messages.length + 1}`);
        setTimeout(1000);

        createBotElement(
          "Do you want to book appointment with our specialist doctors?",
          `bookapponitment-${messages.length + 1}`,
          isSpeechRunning,
          handelSpeech
        );

        createOptonElement("Book Appointment", saveHistoryAndCreateGroup);
        const inputElement = document.getElementsByClassName("rsc-input")[0];
        inputElement.disabled = false;
        inputElement.value = "";
        document.getElementsByClassName(
          "rsc-submit-button"
        )[0].disabled = false;

        setIsTyping(false);
      }
    } catch (error) {
      console.error("Error:", error);

      // Handle any errors by showing a message
      const resultElement = document.getElementById("result");
      if (resultElement) {
        resultElement.textContent = "An error occurred. Please try again.";
      }
    }
  };

  const imageUpload = (imageFile) => {
    imagePreview(imageFile);
    setImage(imageFile);
    // const base64String = image.split(",")[1];
    // analyzeImage(base64String) // Get the base64 part
  };

  const [chatKey, setChatKey] = useState(0);
  const [text, setText] = useState("");
  const [steps, setSteps] = useState([]);
  const handelSpeech = (status) => {
    console.log("sdfsddddddddd", status);
    setIsSpeechRunning((prevState) => {
      console.log("Previous State:", prevState); // Logging previous state
      console.log("Status:", status); // Logging updated status
      return status; // Updating state
    });
    setTimeout(() => {
      console.log(isSpeechRunning);
    }, 1000);
    console.log("sdfsddddddddd", status);
    return status;
  };
  var messages = [];
  const [isTyping, setIsTyping] = useState(false);
  const [appointmentmodalShow, setAppointmentModalShow] = useState(false);
  const [appointment, setAppointment] = useState("");

  const setPurchaseModalShow = (status, chat) => {
    navigate("/membership-plan");
    toast.error("Please purchase a plan to schedule an appointment.");
    // dispatch({
    //   type: "SET_PURCHASE_MODAL_SHOW",
    //   status: status,
    // });
    // dispatch({
    //   type: "SET_CHAT_HISTORY",
    //   data: chat,
    // });
  };

  const getSummery = async (chatMessages) => {
    const chatHistory = [...chatMessages];
    chatHistory.push({
      message: "Give me a summary of this conversion.",
      direction: "outgoing",
      sender: "user",
    });
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      let content = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      if (messageObject?.image) {
        content = [
          {
            type: "text",
            text: `Analyze the following image and determine if it is related to teeth or dental. If it is not, please respond with: 'This image is not related to dental. Please upload a clear dental image.' If it is related to dental health, then answer :${messageObject.message} `, // Instruction for the model
          },
          {
            type: "image_url",
            image_url: {
              url: messageObject?.image,
            },
          },
        ];
      } else {
        content = messageObject.message;
      }
      return { role: role, content: content };
    });
    const apiRequestBody = {
      model: "gpt-4o",
      // prompt: "Generate a summary of the conversation.",
      max_tokens: 110,
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    return await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + ChATGPT_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        return data?.choices[0]?.message.content;
      });
  };

  const handleSend = async (message, image) => {
    try {
      const sendBtnElement = document.getElementsByClassName(
        "rsc-submit-button"
      )[0];
      const speechBtnElement = document.getElementsByClassName(
        "speech-button-container"
      )[0];
      sendBtnElement.classList.add("display-none");
      speechBtnElement.classList.remove("display-none");
    } catch {}
    const inputElement = document.getElementsByClassName("rsc-input")[0];
    inputElement.disabled = true;
    inputElement.value = "";
    document.getElementsByClassName("rsc-submit-button")[0].disabled = true;

    createUserElement(message, image);
    console.log(message);

    if (message !== "") {
      setText("");
      // const divElement = document.getElementsByClassName("image-preview")[0];
      // divElement.re

      const newMessage = {
        message,
        image: image,
        direction: "outgoing",
        sender: "user",
      };

      const newMessages = [...messages, newMessage];
      // setMessages(newMessages);
      messages = newMessages;
      botTypingLoader(messages.length + 1);

      // scrollToBottom()
      setIsTyping(true);
      // setScrollPercent(100)

      if (image) {
        const divElement = document.getElementsByClassName("image-preview")[0];
        divElement.remove();
        const chatWindowElement = document.getElementsByClassName(
          "rsc-content"
        )[0];
        chatWindowElement.classList.remove("chat-window-image-preview");

        // divElement.remove();
        analyzeImage(newMessages, image, message);
      } else {
        await processMessageToChatGPT(newMessages);
      }
    }
  };
  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat
    console.log(chatMessages);
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      let content = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      if (messageObject?.image) {
        content = [
          {
            type: "text",
            text: `Analyze the following image and determine if it is related to teeth or dental. If it is not, please respond with: 'This image is not related to dental. Please upload a clear dental image.' If it is related to dental health, then answer :${messageObject.message} `, // Instruction for the model
          },
          {
            type: "image_url",
            image_url: {
              url: messageObject?.image,
            },
          },
        ];
      } else {
        content = messageObject.message;
      }
      return { role: role, content: content };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    console.log([
      systemMessage, // The system message DEFINES the logic of our chatGPT
      ...apiMessages, // The messages from our chat with ChatGPT
    ]);
    const apiRequestBody = {
      model: "gpt-4o",
      // max_tokens: 110,
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + ChATGPT_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        messages = [
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ];

        createBotElement(
          data.choices[0].message.content,
          messages.length,
          isSpeechRunning,
          handelSpeech
        );
        setTimeout(1000);

        botTypingLoader(`bookapponitment-${messages.length + 1}`);
        setTimeout(1000);
        createBotElement(
          "Do you want to book appointment with our specialist doctors?",
          `bookapponitment-${messages.length + 1}`,
          isSpeechRunning,
          handelSpeech
        );

        createOptonElement("Book Appointment", saveHistoryAndCreateGroup);

        const inputElement = document.getElementsByClassName("rsc-input")[0];
        inputElement.disabled = false;
        inputElement.value = "";
        document.getElementsByClassName(
          "rsc-submit-button"
        )[0].disabled = false;

        setIsTyping(false);
      });
  }

  const { isLoading, data } = useQuery("myData", fetchChetBotStep, {
    onSuccess: (data) => {
      if (data.status[0].Response === 200) {
        if (data.data.normalChatbot) {
          setSteps(data.data.steps);
        } else {
          setSteps([
            {
              id: "0",
              message:
                "Welcome to Gentle Dental Chat! How can we assist you today?",
              end: true,
            },
          ]);
        }
      } else {
        toast.error("something went wrong.");
      }
    },
  });

  const theme = {
    background: "#FFFFFF",
    fontFamily: "Montserrat",
    headerBgColor: "#FFFFFF",
    headerFontColor: "black",
    headerFontSize: "15px",
    botBubbleColor: "#F1FAFF",
    botFontColor: "black",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  const sendToChatGpt = async (renderedSteps) => {
    const questionArray = [];
    const answerArray = [];

    for (let i = 0; i < renderedSteps.length; i++) {
      if (renderedSteps[i]?.value) {
        answerArray.push(renderedSteps[i].message);
      } else if (
        renderedSteps[i].message !== "We identify your problem. please hold on."
      ) {
        questionArray.push(renderedSteps[i].message);
      }
    }

    const chatData = {
      questions: questionArray,
      answers: answerArray,
    };
    botTypingLoader(questionArray.length * 2 + 2);

    const res = await sendGPT(chatData);
    const reStatus = res["status"][0]["Error"];
    if (reStatus === "True") {
      toast.error(res["status"][0]["Message"]);
    } else {
      const messageHistory = [];
      for (let i = 0; i < questionArray.length; i++) {
        messageHistory.push({ message: questionArray[i], sender: "ChatGPT" });
        messageHistory.push({
          message: answerArray[i],
          sender: "user",
          direction: "outgoing",
        });
      }
      messageHistory.push({ message: res.data, sender: "ChatGPT" });

      console.log(messageHistory);
      // setMessages(messageHistory)
      messages = messageHistory;
      // messages()

      setTimeout(1000);
      createBotElement(
        res.data,
        messages.length + 1,
        isSpeechRunning,
        handelSpeech
      );
      botTypingLoader(`bookapponitment-${messages.length + 1}`);
      setTimeout(1000);
      createBotElement(
        "Do you want to book appointment with our specialist doctors?",
        `bookapponitment-${messages.length + 1}`,
        isSpeechRunning,
        handelSpeech
      );

      createOptonElement("Book Appointment", saveHistoryAndCreateGroup);
      if (data.data.chatGptChatbot) {
        enableInput(handleSend, imageUpload);
      }
    }
  };
  const saveHistoryAndCreateGroup = async () => {
    dispatch({ type: "SET_LOADING", status: true });
    const summary = await getSummery(messages);
    dispatch({ type: "SET_CHAT_HISTORY", data: messages });
    dispatch({ type: "SET_CHAT_SUMMERY", data: summary });

    const is_subscriber = window.localStorage.getItem("is_subscriber");
    if (is_subscriber === "true") {
      const renderedSteps = messages;
      for (let i = 0; i < renderedSteps.length; i++) {
        renderedSteps[i]["id"] = i + 1;
      }

      // Initialize two empty arrays for even and odd position elements
      const data = { conversation: renderedSteps, summary: summary };
      const res = await postChatBotConvo(data);
      dispatch({ type: "SET_LOADING", status: false });
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
        // if (res["status"][0]["Response"]) {
        //   window.localStorage.setItem("is_subscriber", false);
        // }
      } else {
        console.log(res);
        setAppointment(res?.data);
        setAppointmentModalShow(true);
        // toast.success("Appoinment created.");
        // navigate("/dashboardhome");
      }
    } else {
      const recentPayment = await hasRecentPayment();
      if (recentPayment.status[0].Response === 200) {
        if (recentPayment.data.hasRecentPayment) {
          toast(
            "Currently, your subscription is not active.  Please wait for a while. You recently made a payment. We'll let you know by email when your payment is received.",
            {
              icon: "⚠️",
            }
          );
        } else {
          setPurchaseModalShow(true, messages);
        }
      } else {
        toast.error("something went wrong. please reload the page.");
      }
      setChatKey((prevKey) => prevKey + 1);
      dispatch({ type: "SET_LOADING", status: false });
    }
  };
  const redirect = async (e) => {
    const renderedSteps = e.renderedSteps;
    const local_sub = window.localStorage.getItem("is_subscriber");

    if (!data.data.normalChatbot) {
      const messageHistory = [];

      messageHistory.push({
        message: renderedSteps[0].message,
        sender: "ChatGPT",
      });

      messages = messageHistory;
      console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLL");
      enableInput(handleSend, imageUpload);
    } else if (
      renderedSteps[renderedSteps.length - 1].message === "restart ...."
    ) {
      setChatKey((prevKey) => prevKey + 1);
    } else if (local_sub === "true") {
      sendToChatGpt(renderedSteps);
    } else {
      const recentPayment = await hasRecentPayment();
      if (recentPayment.status[0].Response === 200) {
        if (recentPayment.data.hasRecentPayment) {
          toast(
            "Currently, your subscription is not active.  Please wait for a while. You recently made a payment. We'll let you know by email when your payment is received.",
            {
              icon: "⚠️",
            }
          );
        } else {
          setPurchaseModalShow(true, e.renderedSteps);
        }
      } else {
        toast.error("something went wrong. please reload the page.");
      }
      setChatKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <section className="section-dashboard-home">
      <PurchaseModal />
      <div className="comman-container">
        <div className="inner-container">
          <div className="column-2">
            {!isLoading && steps.length > 0 ? (
              <ThemeProvider theme={theme}>
                <div className="bot-header">
                  <div className="header-inner-wrapper">
                    <img src={botPic} alt="" />
                    <p>GentledentalBot</p>
                  </div>
                  <button
                    className="primary-button"
                    onClick={() => {
                      setChatKey((prevKey) => prevKey + 1);
                    }}
                  >
                    {" "}
                    Restart
                  </button>
                </div>

                <ChatBot
                  handleEnd={redirect}
                  steps={steps}
                  key={chatKey}
                  botDelay={1000}
                />
              </ThemeProvider>
            ) : (
              <div className="loader">
                <InfinitySpin width="200" color="#0290DE" />
              </div>
            )}
            <BookAppointmentModal
              title="Confirm Appointment"
              successResponseMessage="Appointment created."
              show={appointmentmodalShow}
              appointment={appointment}
              handleClose={() => setAppointmentModalShow(false)}
            />

            {/* <BookAppointment/> */}
            {/* <ThemeProvider theme={theme}>
              <ChatBot steps={data} headerTitle="Dr. Ralph Edwards" />
            </ThemeProvider> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyChatBot;
