import React, { useState, useRef, useEffect } from "react";
import "./Patient.scss";
import ChatBotDiv from "./component/ChatBotDiv/ChatBotDiv";
import Call from "../../Assets/icons/call.svg";
import Upload from "../../Assets/icons/upload.svg";
import Message from "../../Assets/icons/ic_round-mail-outline.png";
import { ReactComponent as Search } from "../../Assets/icons/Search.svg";
import ChatApp from "./component/chatapp-mobile/chatapp-mobile";
import { getGroupList, postGroupMessageFile, getGroupLMessages } from "../../apiCall";
import { toast } from "react-hot-toast";
import { CHAT_REFRESH_TIME } from "../../Constants";
import { useQuery } from "react-query";
import SideNav from "../Dashboard/component/side-nav/side-nav";
import ProfileDemo from "../../Assets/image/user-profile.png";
import ShowMoreText from "../../components/ShowMoreText/showMoreText"


function Patient() {
  const userName = window.localStorage.getItem("name");
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  
  useEffect(() => {
    const getData = async () => {
      const token = localStorage.getItem("token");

      const res = await getGroupList(token);
      console.log(res);

      if (!res["status"]) {
        toast.error(res.detail);
        console.log(res.detail);
      }
      const reStatus = res["status"][0]["Error"];

      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        setPatients(res.data);
        setFilteredPatients(res.data);
        setSelectedPatient(res.data[0]);
        // toast.success("Data successfully")
      }
    };
    getData();
  }, []);

  const [selectedPatient, setSelectedPatient] = useState(null);

  const handlePatientSelection = (patient) => {
    setSelectedPatient(patient);
  };
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  console.log(selectedUploadFile);
  const fileInputRef = useRef(null);

  // const handleFileUpload = async () => {
  //   console.log("calling");
  //   const file = fileInputRef.current.files[0];
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   console.log(file);

  //   const res = await postGroupMessageFile(selectedPatient.groupId, formData);
  //   console.log(res);
  //   const reStatus = res["data"]["status"][0]["Error"];

  //   if (reStatus === "True") {
  //     toast.error(res["status"][0]["Message"]);
  //   } else {
  //     toast.success("File uploaded.");
  //     setChatHistory((prevMessages) => {
  //       return [
  //         ...prevMessages,
  //         {
  //           text: "",
  //           sender: userName,
  //           is_chat_bot: false,
  //           is_text: false,
  //           created_at: new Date(),
  //           file: res.data.data.fileUrl,
  //         },
  //       ];
  //     });
  //   }
  // };

  const handleFileUpload = async () => {
    if (selectedPatient) { // Check if selectedPatient is not null
      console.log("calling");
      const file = fileInputRef.current.files[0];
      const formData = new FormData();
      formData.append("file", file);
  
      console.log(file);
  
      const res = await postGroupMessageFile(selectedPatient.groupId, formData);
      console.log(res);
      const reStatus = res["data"]["status"][0]["Error"];
  
      if (reStatus === "True") {
        toast.error(res["status"][0]["Message"]);
      } else {
        toast.success("File uploaded.");
        setChatHistory((prevMessages) => {
          return [
            ...prevMessages,
            {
              text: "",
              sender: userName,
              is_chat_bot: false,
              is_text: false,
              created_at: new Date(),
              file: res.data.data.fileUrl,
            },
          ];
        });
      }
    }
  };
  
  const handleUploadFileChange = (event) => {
    setSelectedUploadFile(event.target.files[0]);
  
    if (selectedPatient) { // Check if selectedPatient is not null
      handleFileUpload();
    }
  };
  const handleButtonClick = () => {
    console.log("clicked");
    fileInputRef.current.click();
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const session_token = localStorage.getItem("token");
  //     const local_token = localStorage.getItem("token");

  //     const token = session_token ? session_token : local_token;
  //     const res = await getGroupLMessages(token, selectedPatient.groupId);
  //     console.log(res);
  //     const reStatus = res["status"][0]["Error"];

  //     if (reStatus === "True") {
  //       toast.error(res["status"][0]["Message"]);
  //     } else {
  //       setChatHistory(res.data);
  //       // toast.success("Data successfully");
  //     }
  //   };
  //   getData();
  // }, [selectedPatient]);





  useEffect(() => {
    console.log("sdffffffffffffffffffffffff111111111111111111111")

    const getData = async () => {
      if (selectedPatient) { // Check if selectedPatient is not null
        const token = localStorage.getItem("token");
        const res = await getGroupLMessages(token, selectedPatient.groupId);
        const reStatus = res["status"][0]["Error"];
  
        if (reStatus === "True") {
          toast.error(res["status"][0]["Message"]);
        } else {
          setChatHistory(res.data);
          // toast.success("Data successfully");
        }
      }
    };
    getData();
  }, [selectedPatient]);
  // if (selectedPatient) {
  const token = localStorage.getItem("token");
  const { data, error, isLoading } = useQuery(
    "myData",
    () =>
      getGroupLMessages(
        token,
        selectedPatient?.groupId ? selectedPatient?.groupId : -1
      ),
    {
      refetchInterval: CHAT_REFRESH_TIME,
    }
  );
  // }

  const handleSearchInput = (event) => {
    if(event.target.value===""){

      setFilteredPatients(patients)
    }
    else{

      setFilteredPatients(patients?.filter(element => element.groupMembers[0].name.includes(event.target.value)))
    }

  };
  useEffect(() => {
    if (data) {
      const reStatus = data["status"][0]["Error"];

      if (reStatus === "True" || error) {
        if (error) {
          toast.error(error);
        } else {
          toast.error(data["status"][0]["Message"]);
        }
      } else {
        setChatHistory(data.data);
        // toast.success("messages fetched")
      }
    }
  }, [data, error]);
  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-patient-section container">
        <div className="header" style={{ display: "flex", flexDirection: "row" }}>
          <h2>{userName}</h2>
          <div className="search-div display-flex">
            <input type="text" placeholder="Search " onChange={(event)=>handleSearchInput(event)} />
            <Search />
          </div>
        </div>
        <div className="chat-app">
          <div className="left-panel">
            <div className="patient-list">
              {filteredPatients.map((patient) => (
                <div
                  key={patient.groupId}
                  className={`patient ${
                    selectedPatient &&
                    selectedPatient.groupId === patient.groupId
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handlePatientSelection(patient)}
                >
                  <div className="profile-sec">
                    <img
                      src={patient?.groupMembers[0]?.profile_image}
                      alt=""
                      className="patient-pic"
                      onError={(e) => {
                        e.target.src = ProfileDemo;
                      }}
                    />
                    <div className="text">
                      <p className="name">
                        {patient?.groupMembers[0]?.name}
                        <span className="sex">{` ${patient?.groupMembers[0]?.gender}`}</span>
                        <span className="age">{` ${new Date().getFullYear() -
                          new Date(
                            patient?.groupMembers[0]?.birthdate
                          ).getFullYear()} years`}</span>
                      </p>
                      <p className="problem">
                        {patient?.groupMembers[0]?.dentalProblem &&
                          patient?.groupMembers[0]?.dentalProblem
                            .split(" ")
                            .slice(0, 3)
                            .join(" ")}{" "}
                        | <span className="age"> New</span>
                      </p>
                      <p className="age">Hey</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="middle-panel">
            {selectedPatient ? (
              <>
              {console.log(selectedPatient)}
                <header className="profile header-of-chat-app">
                  <div className="profile-sec">
                    <img
                      src={selectedPatient?.groupMembers[0]?.profile_image}
                      alt=""
                      className="patient-pic"
                      onError={(e) => {
                        e.target.src = ProfileDemo;
                      }}
                    />
                    <div className="text">
                      <p className="name">
                        {selectedPatient.groupMembers[0]?.name}
                        <span className="sex">{` ${selectedPatient.groupMembers[0]?.gender}`}</span>
                        <span className="age">{` ${new Date().getFullYear() -
                          new Date(
                            selectedPatient.groupMembers[0]?.birthdate
                          ).getFullYear()} years`}</span>
                      </p>
                      <p className="problem">
                        {selectedPatient.groupMembers[0]?.dentalProblem &&
                          selectedPatient.groupMembers[0]?.dentalProblem
                            .split(" ")
                            .slice(0, 3)
                            .join(" ")}{" "}
                        | <span className="age"> New</span>
                      </p>
                    </div>
                  </div>
                </header>
                
                {window.innerWidth > 1135 && (
                  <ChatBotDiv
                    selectedPatient={selectedPatient}
                    chatHistory={chatHistory}
                    setChatHistory={setChatHistory}
                  />
                )}
              </>
            ) : (
              <div className="no-patient-selected">No patient selected</div>
            )}
          </div>
          <div className="right-panel">
            {selectedPatient && (
              <div className="profile">
                <div>
                  <div className="profile-sec">
                    <img
                      src={selectedPatient?.groupMembers[0]?.profile_image}
                      alt=""
                      className="patient-pic"
                      onError={(e) => {
                        e.target.src = ProfileDemo;
                      }}
                    />
                    <div className="text">
                      <p className="name">
                        {`${selectedPatient.groupMembers[0]?.name} |`}
                        <span className="sex">{` ${selectedPatient.groupMembers[0]?.gender}`}</span>
                        <span className="age">{` ${new Date().getFullYear() -
                          new Date(
                            selectedPatient.groupMembers[0]?.birthdate
                          ).getFullYear()}years`}</span>
                      </p>
                      <p className="problem">
                        {selectedPatient.groupMembers[0]?.dentalProblem &&
                          selectedPatient.groupMembers[0]?.dentalProblem
                            .split(" ")
                            .slice(0, 3)
                            .join(" ")}{" "}
                        | <span className="age"> New</span>
                      </p>
                    </div>
                    <hr className="line-1" />
                  </div>
                  {/* Add more profile details */}
                  <div className="display-flex div-side-1">
                    <img src={Call} alt="" />
                    +91{selectedPatient.groupMembers[0]?.phoneNumber}
                  </div>
                  <div className="display-flex div-side-1">
                    <img src={Message} alt="" />
                    {selectedPatient.groupMembers[0]?.email}
                  </div>
                  <div
                    className="display-flex div-side-1 button"
                    onClick={handleButtonClick}
                  >
                    <img src={Upload} alt="" />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleUploadFileChange}
                      style={{ display: "none" }}
                    />
                    <button>Upload</button>
                  </div>
                  {/* Add document list */}
                  <div>
                    <strong>Summary: </strong>
                    <ShowMoreText text={selectedPatient?.summary} maxWords={20} />
                    
                  </div>
                </div>
                <div className="docs">
                  {/* <div className="each-doc display-flex">
                    <img src={File} alt="" />
                    <div className="text-m">
                      <p className="txt-01">File.zip</p>
                      <p className="txt-02">02 October,2022 at 08:17</p>
                      <p className="txt-02">15.45 Mb</p>
                    </div>
                    <img src={Upload} alt="" />
                    <img src={Delete} alt="" />
                  </div>
                  <div className="each-doc display-flex">
                    <img src={File} alt="" />
                    <div className="text-m">
                      <p className="txt-01">File.zip</p>
                      <p className="txt-02">02 October,2022 at 08:17</p>
                      <p className="txt-02">15.45 Mb</p>
                    </div>
                    <img src={Upload} alt="" />
                    <img src={Delete} alt="" />
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="mobile-view-patient">
        {window.innerWidth <= 1135 && <ChatApp mypatients={filteredPatients} handleSearchInput={handleSearchInput} />}
      </section>
    </div>
  );
}

export default Patient;
