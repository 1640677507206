import React, { useEffect } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import "./ChooseOne.scss";
import page1 from "../../../../Assets/image/signup-1.png";
import dentistIcon from "../../../../Assets/icons/dentist.svg";
import patientIcon from "../../../../Assets/icons/patient.svg";
import google from "../../../../Assets/icons/google.svg";
import facebook from "../../../../Assets/icons/Facebook.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../StateProvider";

function ChooseOne({ setUser, formData, setFormData }) {
  const [, dispatch] = useStateValue();

  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => verifyUser(tokenResponse),
    onError: (errorResponse) => toast.error(errorResponse),
  });
  const verifyUser = async (data) => {
    try {
      const re = await GoogleLogin({ access_token: data?.access_token });
      if (re?.status[0].Response === 200) {
        window.localStorage.setItem("token", re.access_token);
        window.localStorage.setItem("email", re.data.email);
        window.localStorage.setItem("profile", re.data.profile);
        window.localStorage.setItem("isGoogleLogIn", true);
        window.localStorage.setItem("userType", re.data.userType);
        window.localStorage.setItem(
          "isProfileCompleted",
          re.data.isProfileCompleted
        );

        toast.success("login successful.");
        dispatch({ type: "USER_TYPE", status: re.data.userType });
        dispatch({ type: "LOGIN", status: true });
        dispatch({ type: "GOOGLELOGINSTATUS", status: true });
        dispatch({
          type: "USERPROFILESTATUS",
          status: re.data.isProfileCompleted,
        });

        if (re.data.isProfileCompleted) {
          window.localStorage.setItem("email", re.data.email);
          window.localStorage.setItem("name", re.data.name);
          window.localStorage.setItem("phoneNumber", re.data.phoneNumber);
          if (re.data.is_doctor) {
            navigate("/dashboardhome");
          } else {
            window.localStorage.setItem(
              "is_subscriber",
              re.data.is_subscriber
            );

            navigate("/");
          }
        } else {
          navigate("/google/sign-up-form");
        }
      } else {
        toast.error(re.status[0].Message);
      }
    } catch {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  return (
    <section className="section-choose-one">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Welcome to gentledental
            </span>
          </div>
          <img
            src={page1}
            data-aos="zoom-out"
            data-aos-duration="1000"
            alt=""
          />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div className="title" data-aos="fade-up" data-aos-duration="1000">
              Welcome! Let's create your account. Choose one.
            </div>
            <div className="option">
              <div
                className="patient cursor-pointer"
                onClick={() => setUser("patient")}
              >
                <img src={patientIcon} alt="" />
                <div>Patient</div>
              </div>
              <div
                className="dentist cursor-pointer"
                onClick={() => setUser("dentist")}
              >
                <img src={dentistIcon} alt="" />
                <div>Dentist</div>
              </div>
            </div>
            <div className="or-signup-with">
              <div className="line"></div>
              <span>Or sign up with</span>
              <div className="line"></div>
            </div>
            <div className="signup-option">
              <div className="icon-container">
                <div
                  className="google icon cursor-pointer"
                  onClick={() => googleLogin()}
                >
                  <img src={google} alt="" /> Google
                </div>
                {/* <div className="facebook icon cursor-pointer">
                  <img src={facebook} alt="" />
                </div> */}
              </div>
              <div className="have-account">
                Already have an account{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => navigate("/sign-in")}
                >
                  {" "}
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseOne;
