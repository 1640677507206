// export const domainName = "http://127.0.0.1:8000"
// export const domainName = "http://143.244.131.242:5000"
export const domainName = "https://www.admin.gentledental.ai"
export const CHAT_REFRESH_TIME = 3000
export const googleClinetId = "750018740745-2n4dgagcd7efni2en9mkrgr01pl08ak5.apps.googleusercontent.com"
export const ChATGPT_API_KEY = "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";
export const PINCODE_API = "579b464db66ec23bdd000001d31e4df26e2c414865a4ef916ba9f37d";
export const api = {
    demo: "demo",
    emailCheck: "/api/isEmailAvailable",
    googleLogin: "/api/google/verify/google_access_token",
    doctorGoogleSignUpForm: "/api/doctor/google/signup-form",
    patientGoogleSignUpForm: "/api/patient/google/signup-form",
    phoneNumberCheck: "/api/isPhoneNumberAvailable",
    dentistSignup: "/api/doctor/signup",
    dentistAppointments: "/api/doctor/appointment-slots",
    dentistSignin: "/api/doctor/login",
    doctorProfile: "/api/doctor/profile",
    experties: '/api/doctor/expertise', 
    specilities: '/api/doctor/specialty',
    doctorProfileImgUpdate: '/api/doctor/profile/image',
    doctorPersonalInfoUpdate: '/api/doctor/profile/personal-info',
    doctorAddressUpdate: '/api/doctor/profile/address',
    doctorSocialUpdate: '/api/doctor/profile/dentist-social-link',
    doctorDentistUpdate: '/api/doctor/profile/dentist-experience',
    doctorWebsiteUpdate: '/api/doctor/profile/personal-website',
    patientSignup: "/api/patient/signup",
    login: "/api/auth/login",
    emailVerify: "/api/auth/email-verify",
    changePassword: "/api/auth/change-password",
    chatBotStep: "/chat-bot/chat-flow",
    chatBotHistory: "/chat-bot/chat-bot-history",
    groupList: "/chat/group-list",
    groupMessages: "/chat/group-message",
    groupMessagesFile: "/chat/group-message-file",
    patientProfile: "/api/patient/profile",
    patientImage: "/api/patient/profile/image",
    patientUpdateAddress: "/api/patient/profile/address",
    patientUpdateInformation: "/api/patient/profile/personal-info",
    patientUpdateSocialLinks: "/api/patient/profile/patient-social-link",
    appointment: "/api/appoinment",
    cancelAppointment: "/api/appoinment/cancel",
    payment: "/payment",
    activateSubscription: "/api/patient/activate-subscription",
    hasRecentPayment: "/recent-payment",
    sendToGPT: "/chat-bot/send-gpt",
    getPromptQuestion: "/chat-bot/prompt-question",
    subscriptionHistory: "/api/patient/subscription-history",
    faq: "/api/faq",
    membership: "/api/membership",
    teamMember: "/api/team",

}