import React, { useEffect } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import "./ChooseOne.scss";
import page1 from "../../../../Assets/image/signup-1.png";
import dentistIcon from "../../../../Assets/icons/dentist.svg";
import patientIcon from "../../../../Assets/icons/patient.svg";
import google from "../../../../Assets/icons/google.svg";
import facebook from "../../../../Assets/icons/Facebook.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "../../../../apiCall";
import toast from "react-hot-toast";

function ChooseOne({ setUser, formData, setFormData }) {
  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => verifyUser(tokenResponse),
    onError: (errorResponse) => toast.error(errorResponse),
  });
  const verifyUser = async (data) => {
    try {
      const re = await GoogleLogin({ access_token: data?.access_token });
      console.log(re);
      if (re?.status[0].Response === 200) {
        window.localStorage.setItem("token",  re.access_token);
        window.localStorage.setItem("email", re.data.email);
        window.localStorage.setItem(
          "is_profile_completed",
          re.is_profile_completed
        );
        toast.success("login successful.");
      } else {
        toast.error(re.status[0].Message);
      }
    } catch {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  return (
    <section className="section-choose-one">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Welcome to gentledental
            </span>
          </div>
          <img
            src={page1}
            data-aos="zoom-out"
            data-aos-duration="1000"
            alt=""
          />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div className="title" data-aos="fade-up" data-aos-duration="1000">
            Welcome! Let's complete your profile. Choose one.
            </div>
            <div className="option">
              <div
                className="patient cursor-pointer"
                onClick={() => setUser("patient")}
              >
                <img src={patientIcon} alt="" />
                <div>Patient</div>
              </div>
              <div
                className="dentist cursor-pointer"
                onClick={() => setUser("dentist")}
              >
                <img src={dentistIcon} alt="" />
                <div>Dentist</div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseOne;
