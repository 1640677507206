import React, { useEffect } from "react";
import AOS from "aos";
import "./Email.scss";
import page from "../../../../Assets/image/signup-5.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import { emailCheck } from "../../../../apiCall";
import toast from "react-hot-toast";

function Email({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarValue,
  progresBarMaxValue,
}) {
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const email_regex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}/;
  const [emailErr, setEmailErr] = useState("");

  const handleNext = async () => {
    const re = await emailCheck({ email: formData.email });
    if (re.status[0].Response === 200) {
      nextSteps();
    } else {
      toast.error("Email Is Already In Use.");
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.length < 1) {
      setEmailErr("Email is required");
      return setFormData({ ...formData, email: "" });
    }
    if (email_regex.test(e.target.value)) {
      setFormData({ ...formData, email: e.target.value });
      setEmailErr("");
    } else {
      setFormData({ ...formData, email: e.target.value });
      setEmailErr("Email is not valid");
    }
  };
  return (
    <section className="section-email">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Stronger gums & gentler smiles
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              <div className="title">
                Enter your email address that we should use to stay connected.
                We ensure no spam :)<span>&#x1F60E;</span>{" "}
              </div>
              <div className="sub-title">
                We value your work and precious time.
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter your email address"
                    name="email"
                    value={formData.email}
                    size="sm"
                    className="input"
                    id="form1"
                    type="email"
                    onChange={handleInputChange}
                  />
                  <small className="error">{emailErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !email_regex.test(formData.email) || emailErr
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!email_regex.test(formData.email) || emailErr}
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Email;
