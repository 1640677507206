import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import { useStateValue } from "../../StateProvider";
import PurchasePlan from "../PurchasePlan/PurchasePlan"
import BookAppointment from "../BookAppointment/BookAppointment";
import CancelAppointment from "../CancelAppointment/CancelAppointment";
import AppointmentDetail from "../AppointmentDetail/AppointmentDetail";
import ImageCapture from "../../views/Chat-Bot/components/ImageCapture";

// *************************************************** login form ****************************************************************

export function PurchaseModal(props) {
  const [{ purchaseModalShow }, dispatch] = useStateValue();
  const setPurchaseModalShow = () => {
    dispatch({
      type: "SET_PURCHASE_MODAL_SHOW",
      status: false,
    });
  };
  return (
    <Modal
      {...props}
      show={purchaseModalShow}
      onHide={() => setPurchaseModalShow(false)}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body>
        <PurchasePlan/>
      </Modal.Body>
    </Modal>
  );
}
export function BookAppointmentModal({title,successResponseMessage,show,handleClose,appointment}) {
  
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body>
        <BookAppointment title={title} successResponseMessage={successResponseMessage} appointment={appointment} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

export function CancelAppointmentModal({show,handleClose,appointment,refetch}) {
  
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body>
        <CancelAppointment appointment={appointment} handleClose={handleClose} refetch={refetch} />
      </Modal.Body>
    </Modal>
  );
}
export function AppointmentDetailModal({show,handleClose,appointment}) {
  
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body>
        <AppointmentDetail appointment={appointment} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}
export function CameraImageUploadModal ({show,handleClose,handleImageUpload}) {
  
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body>
        <ImageCapture handleImageUpload={handleImageUpload} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}