export const initialState = {
    // demo: false,
    isGoogleLogIn: localStorage.getItem("isGoogleLogIn"),
    isProfileCompleted:  localStorage.getItem("isProfileCompleted"),
    isLoggedIn: localStorage.getItem("token") ? true : false,
    user_type: localStorage.getItem("userType") ,
    isLoading: false,
};

for (let index = 0; index < localStorage.length; index++) {
    // const key = localStorage.key(index);
    // const value = localStorage.getItem(key);
}

function reducer(state, action) {
    switch (action.type) {

        case 'DEMO':
            return {
                ...state,
                demo: action.status
            }
        case 'SET_LOADING':
            window.scrollTo(0,0)
            return {
                ...state,
                isLoading: action.status
            }

        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: action.status
            }
        case 'GOOGLELOGINSTATUS':
            return {
                ...state,
                isGoogleLogIn: action.status
            }
        case 'USERPROFILESTATUS':
            return {
                ...state,
                isProfileCompleted: action.status
            }
        case 'USER_TYPE':
            return {
                ...state,
                user_type: action.status
            }
        case 'SET_PURCHASE_MODAL_SHOW':
            return {
                ...state,
                purchaseModalShow: action.status
            }
        case 'SET_CHAT_HISTORY':
            return {
                ...state,
                chatHistory: action.data
            }
        case 'SET_CHAT_SUMMERY':
            return {
                ...state,
                chatSummery: action.data
            }

        default:
            return state
    }
}

export default reducer;