import React, { useEffect, useState } from "react";
import "./Membership.scss";
import { useQuery } from "react-query";

import SideNav from "../component/side-nav/side-nav";

import facebook from "../../../Assets/icons/Facebook.svg";
import instagram from "../../../Assets/icons/Instagram.svg";
import { FaTwitter } from "react-icons/fa";

import { Link } from "react-router-dom";
import { getSubscriptionHistory } from "../../../apiCall";
import toast from "react-hot-toast";

function Membership() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  
  const getActiveSubscription = (data) => {
    for (var i = 0; i < data.length; i++) {
      console.log(data[i]); // Print each element of the array

      var dateToCompare = new Date(data[i].subscription_end_at__date);

      // Current date
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (dateToCompare > currentDate) {
        return data[i];
      }
    }
  };
  const { data, isLoading } = useQuery(
    "subscription-history",
    getSubscriptionHistory,
    {
      onSuccess: (data) => {
        if (data.status[0].Response === 200) {
          console.log(data.data);
          setSubscriptionDetail(data.data);
          setActiveSubscriptionDetail(getActiveSubscription(data?.data))
        } else {
          toast.error(data.status[0].Message);
        }
      },
    }
  );
  const [activeSubscriptionDetail, setActiveSubscriptionDetail] = useState(data?.data ? getActiveSubscription(data?.data) : {});

  const [subscriptionDetail, setSubscriptionDetail] = useState(
    data?.data ? data?.data : []
  );

  return (
    <div className="outer-div">
      <SideNav />
      <section className="main-dashboard-div">
        <h2 className="txt-01">Membership Details</h2>
        <div className="ap-001">
          <div className="active-membership-div">
            <div className="a-01">
              <div className="app-div-01">
                <h3>Active Membership</h3>
              </div>
              <hr />
              <div className="membership-detail-wrapper">
                <div className="membership-detail">
                  <div className="title">Membership Type</div>
                  <div className="detail">
                    {activeSubscriptionDetail?.subscription_plan
                      ? activeSubscriptionDetail.subscription_plan
                      : "-"}
                  </div>
                </div>
                <div className="membership-detail">
                  <div className="title">amount</div>
                  <div className="detail">
                    {activeSubscriptionDetail?.payment_detail__amount
                      ? activeSubscriptionDetail.payment_detail__amount
                      : "-"}
                  </div>
                </div>
                <div className="membership-detail">
                  <div className="title"> Payment Date</div>
                  <div className="detail">
                    {activeSubscriptionDetail?.create_at__date
                      ? activeSubscriptionDetail.create_at__date
                      : "-"}
                  </div>
                </div>
                <div className="membership-detail">
                  <div className="title"> Payment Id</div>
                  <div className="detail">
                    {activeSubscriptionDetail?.payment_detail__payment_id
                      ? activeSubscriptionDetail.payment_detail__payment_id
                      : "-"}
                  </div>
                </div>
                <div className="membership-detail">
                  <div className="title"> Membership Expiry Date</div>
                  <div className="detail">
                    {activeSubscriptionDetail?.subscription_end_at__date
                      ? activeSubscriptionDetail.subscription_end_at__date
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ap-001">
          <div className="membership-div">
            <div className="a-01">
              <div className="app-div-01">
                <h3>Membership History</h3>
              </div>
            </div>
          </div>
          <div>
            <table className="hide-mobile">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Membership Type</th>
                  <th>Amount</th>
                  <th>Payment Date</th>
                  <th>Payment Id</th>
                  <th>Membership Expiry Date</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {subscriptionDetail.length === 0 ? (
                  <tr>
                    <td colSpan="5">No Membership Detail found.</td>
                  </tr>
                ) : (
                  subscriptionDetail.map((subscription, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="img-001">
                          <span>{subscription?.subscription_plan}</span>
                        </div>
                      </td>
                      <td>{subscription?.payment_detail__amount}</td>
                      <td>{subscription?.create_at__date}</td>
                      <td>{subscription?.payment_detail__payment_id}</td>
                      <td className="address-text disabled">
                        {subscription?.subscription_end_at__date}
                      </td>
                      {/* <td>
                        <GrStatusGood />
                        <MdOutlineCancel />
                      </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <div className="membership-list hide-web">
              <p className="p-tag">Profile</p>
              <hr className="line" />
              {subscriptionDetail.length === 0 ? (
                // <tr>
                <p colSpan="5">No Membership Detail found.</p>
              ) : (
                // </tr>
                subscriptionDetail.map((subscription, index) => (
                  <React.Fragment key={index}>
                    {/* <tr> */}
                    {/* <td> */}
                    <div
                      className="profile-div"
                      onClick={() => handleCollapse(index)}
                    >
                      {/* <img
                        src={subscription.profile_image}
                        alt=""
                        className="profile-img"
                      /> */}
                      <div className="content-wrapper">
                        <div className="title">
                          {subscription.subscription_plan}
                        </div>
                        <div className="content">
                          <div>{subscription.create_at__date}</div>-
                          <div>{subscription.subscription_end_at__date}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`info-div collapse${
                        expandedIndex === index ? " show" : ""
                      }`}
                    >
                      {/* <td colSpan="5"> */}
                      <p className="info-heading">subscription Details</p>
                      <hr className="info-divider" />
                      <p className="info-data">
                        Membership Type: {subscription.subscription_plan}
                      </p>
                      <p className="info-data">
                        Amount: {subscription.payment_detail__amount}
                      </p>
                      <p className="info-data">
                        Payment Date: {subscription.create_at__date}
                      </p>
                      <p className="info-data">
                        Payment Id: {subscription.payment_detail__payment_id}
                      </p>
                      <p className="info-data">
                        Membership Expiry Date:{" "}
                        {subscription.subscription_end_at__date}
                      </p>
                    </div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Membership;
