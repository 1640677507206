import React, { useEffect } from "react";
import AOS from "aos";
import "./Address.scss";
import page from "../../../../Assets/image/signup-1.png";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import axios from "axios";
import { useState } from "react";
import { useRef } from "react";
import { PINCODE_API } from "../../../../Constants";

function Address({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarMaxValue,
  progresBarValue,
}) {
  const [pincode, setPincode] = useState("");
  const [pincodeErr, setPincodeErr] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const stateRef = useRef(null);
  const cityRef = useRef(null);

  const checkPincode = async (e) => {
    setPincode(e.target.value);

    const stateElement = stateRef.current;
    const cityElement = cityRef.current;
    if (e.target.value.length < 1) {
      return setPincodeErr("Pincode is required");
    }
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(e.target.value)) {
      while (stateElement.options.length > 0) {
        stateElement.remove(0);
      }
      while (cityElement.options.length > 0) {
        cityElement.remove(0);
      }
      const newStateOption = new Option(
        "select pincode first",
        "select pincode first"
      );
      const newCityOption = new Option(
        "select pincode first",
        "select pincode first"
      );
      stateElement.add(newStateOption);
      cityElement.add(newCityOption);

      setCity("");
      setState("");
      setFormData({ ...formData, postalCode: e.target.value });
      return setPincodeErr("Pincode is invalid");
    } else {
      setPincodeErr("");
      try {
        const response = await axios.get(
          `https://api.data.gov.in/resource/9115b89c-7a80-4f54-9b06-21086e0f0bd7?api-key=${PINCODE_API}&format=json&limit=600&filters%5Bpincode%5D=${e.target.value}`
        );
        if (response.status === 200 && response?.data?.records.length > 0) {
          const option_list = response?.data?.records;
          setFormData({
            ...formData,
            state: response.data.records[0].statename.toLowerCase(),
            city: response.data.records[0].village_locality_name.toLowerCase(),
            postalCode: e.target.value,
          });
          var stateArray = [];
          var cityArray = [];
          function isInArray(value, array) {
            return array.indexOf(value) !== -1;
          }

          option_list.forEach(function(data) {
            if (!isInArray(data["statename"].toLowerCase(), stateArray)) {
              stateArray.push(data["statename"].toLowerCase());
            }
            if (
              !isInArray(data["village_locality_name"].toLowerCase(), cityArray)
            ) {
              cityArray.push(data["village_locality_name"].toLowerCase());
            }
          });

          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          stateArray.forEach((option) => {
            const newOption = new Option(option, option);
            stateElement.add(newOption);
          });
          cityArray.forEach((option) => {
            const newOption = new Option(option, option);
            cityElement.add(newOption);
          });
          if (formData?.state && formData?.city) {
            setCity(formData?.city);
            setState(formData?.state);
            stateElement.value = formData?.state;
            cityElement.value = formData?.city;
          } else {
            setCity(response.data.records[0].statename.toLowerCase());
            setState(
              response.data.records[0].village_locality_name.toLowerCase()
            );
          }

          setPincodeErr("");
        } else {
          setCity("");
          setState("");

          while (stateElement.options.length > 0) {
            stateElement.remove(0);
          }
          while (cityElement.options.length > 0) {
            cityElement.remove(0);
          }
          const newStateOption = new Option(
            "select pincode first",
            "select pincode first"
          );
          const newCityOption = new Option(
            "select pincode first",
            "select pincode first"
          );
          stateElement.add(newStateOption);
          cityElement.add(newCityOption);

          setFormData({ ...formData, postalCode: e.target.value });
          setPincodeErr("Pincode is invalid");
        }
      } catch (err) {}
    }
  };

  const handleAddressInput = (e) => {
    const value = e.target.value.trim();
    if (e.target.value.length < 1 && value === "") {
      setAddressErr("Address is required");
      setFormData({ ...formData, address: "" });
    } else {
      setAddressErr("");
      setFormData({ ...formData, address: e.target.value });
    }
  };

  const handleNext = () => {
    nextSteps();
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
    if (formData?.postalCode.length === 6) {
      checkPincode({ target: { value: formData?.postalCode } });
    }
  }, []);
  return (
    <section className="section-clinic-detail">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Dental Hygiene for all
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
              <div className="sub-title">
                That's great.<br></br>
                Now, we need some detalis of your home<span>&#x1F914;</span>
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBTextArea
                    label="Enter the address of your home"
                    name="experience"
                    value={formData.address}
                    size="sm"
                    className="input"
                    id="form1"
                    type="text"
                    onChange={handleAddressInput}
                  />
                  <small className="error">{addressErr}</small>
                </div>
                <div className="input-box">
                  <div className="pincode-state-city">
                    <MDBInput
                      label="Postal Code"
                      name="experience"
                      size="sm"
                      value={pincode}
                      className="input"
                      id="form1"
                      type="number"
                      onChange={checkPincode}
                    />
                    <div className="select-container">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="state"
                        ref={stateRef}
                        onChange={(e) => {
                          setState(e.target.value);
                          setFormData({
                            ...formData,
                            state: e.target.value,
                          });
                        }}
                      >
                        <option selected>select pincode first</option>
                      </select>
                      <label htmlFor="select-state">State</label>
                      <div class="form-notch">
                        <div class="form-notch-leading"></div>
                        <div class="form-notch-middle-state"></div>
                      </div>
                    </div>
                    <div className="select-container">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="city"
                        ref={cityRef}
                        onChange={(e) => {
                          setCity(e.target.value);
                          setFormData({
                            ...formData,
                            city: e.target.value,
                          });
                        }}
                      >
                        <option selected>select pincode first</option>
                      </select>
                      <label htmlFor="select-state">City</label>

                      <div class="form-notch">
                        <div class="form-notch-leading"></div>
                        <div class="form-notch-middle-city "></div>
                      </div>
                    </div>
                  </div>
                  <small className="error">{pincodeErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    !formData.address |
                    // !formData.city |
                    !formData.postalCode |
                    state |
                    city
                      ? // !formData.state
                        "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.name}
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Address;
