import React, { useCallback, useState, useEffect, useRef } from "react";
import "./ChatBotDiv.scss";
import "aos/dist/aos.css";
import SendMsg from "../../../../Assets/icons/send.png";
import Bot from "../../../../Assets/icons/chatbot.png";
import { postGroupMessage, postGroupMessageFile } from "../../../../apiCall";
import { toast } from "react-toastify";
import FileView from "./FileView";
import ProfileDemo from "../../../../Assets/image/user-profile.png";
import Upload from "../../../../Assets/icons/upload.svg";
import { ReactComponent as AttachmentIcon } from "../../../../Assets/icons/attachment.svg";
import { ImAttachment } from "react-icons/im";
// import { Picker } from "emoji-mart";
// import 'emoji-mart/dist/css/emoji-mart.css';
// import { BsEmojiSmile } from "react-icons/bs";
import InputEmoji from "react-input-emoji";

function ChatBotDiv({ selectedPatient, chatHistory, setChatHistory }) {
  const userName = window.localStorage.getItem("name");
  const userProfilePic = window.localStorage.getItem("profile");
  const chatBoxRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef();

  const [text, setText] = useState("");
  const handleFileUpload = async () => {
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const res = await postGroupMessageFile(selectedPatient.groupId, formData);
    const reStatus = res["data"]["status"][0]["Error"];

    if (reStatus === "True") {
      toast.error(res["status"][0]["Message"]);
    } else {
      toast.success("File uploaded.");
      setChatHistory((prevMessages) => {
        return [
          ...prevMessages,
          {
            text: "",
            sender: userName,
            is_chat_bot: false,
            is_text: false,
            created_at: new Date(),
            file: res.data.data.fileUrl,
          },
        ];
      });
    }
  };

  const handleUploadFileChange = (event) => {
    handleFileUpload();
  };
  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };
  const scrollToBottom = useCallback(() => {
    console.log("scrolling");
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, []);

  const isChatImage = (url) => {
    if (url) {
      if (url.includes("/uploads/group_message")) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, chatHistory]);
  // console.log(text);
  const handleUserMessage = async () => {
    inputRef.current.value = "";
    console.log("user msg entered");
    console.log(text);
    if (text) {
      const token = localStorage.getItem("token");
      // const myMessage = inputRef.current.value;
      const myMessage = text;
      // text = "";
      // console.log(text);
      const res = await postGroupMessage(token, {
        message: myMessage,
        groupId: selectedPatient.groupId,
      });
      console.log(res);
      const reStatus = res?.status?.[0]?.Error;

      if (reStatus === "True") {
        toast.error(res.status[0].Message);
      } else {
        const newMessage = {
          text: myMessage,
          sender: userName,
          is_chat_bot: false,
          is_text: true,
          created_at: new Date(),
          file: "",
        };
        console.log(newMessage);
        setChatHistory((prevMessages) => [...prevMessages, newMessage]);
        // console.log(chatHistory);
      }

      // scrollToBottom();
    }
  };

  return (
    <>
      <div className="chat-bot" ref={chatBoxRef}>
        {console.log(chatHistory)}
        <div className="chat-messages">
          {/* {chatHistory
            // .slice()
            // .reverse()
            .map((message, index) => ( */}
          {chatHistory?.map((message, index) => (
            <div
              key={index}
              className={`user-self ${
                message.sender === userName
                  ? "self"
                  : "GentleDentleBot align-flex-start"
              }`}
            >
              <div
                className={`time-name GentleDentleBot ${
                  message.sender === userName ? "self" : ""
                }`}
              >
                <span className="bot-img">
                  {message.is_chat_bot && <img src={Bot} alt="" />}

                  {message.sender === userName && !message.is_chat_bot && (
                    <img
                      src={userProfilePic}
                      alt=""
                      onError={(e) => {
                        e.target.src = ProfileDemo;
                      }}
                    />
                  )}
                  {message.sender !== userName && !message.is_chat_bot && (
                    <img
                      src={selectedPatient.groupMembers[0]?.profile_image}
                      alt=""
                      onError={(e) => {
                        e.target.src = ProfileDemo;
                      }}
                    />
                  )}
                  {/* } */}
                </span>
                <span className="sender">
                  {message.sender || "GentleDentleBot"}
                </span>
                <span className="timestamp">
                  {new Date(message.created_at).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </div>
              {console.log(message.sender)}
              {isChatImage(message?.chatImage) && (
                <>
                  <img className="chat-image" src={message.chatImage} alt="" />
                </>
              )}
              <div
                className={`${
                  message.sender ? "user-self-msg" : "GentleDentleBot"
                } ${message.is_text ? "message" : "file-message"} ${
                  message.sender === userName ? "self" : ""
                }`}
              >
                {message.is_text ? (
                  <div className={`text  ${message.sender ? "user-text" : ""}`}>
                    {message.text}
                  </div>
                ) : (
                  <FileView
                    link={message.file}
                    isSelf={message.sender === userName}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="input-div">
        <div className="input-block">
          {/* {showEmojis && (
        <div>
          <Picker />
        </div>
      )} */}
          {/* <button className="button" onClick={() => setShowEmojis(!showEmojis)}>
            <BsEmojiSmile/>
          </button> */}
          {/* <input
            type="text"
            ref={inputRef}
            placeholder="send a message"
            value={inputEmoji}
        
          /> */}
          <InputEmoji
            ref={inputRef}
            value={text}
            onChange={setText}
            cleanOnEnter
            onEnter={handleUserMessage}
            placeholder="Type a message.."
          />
          {/* <div className="chatInput-mobile"> */}
          <span className="file-icon">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleUploadFileChange}
              style={{ display: "none" }}
            />
            <ImAttachment size={24} onClick={handleFileUploadClick} />
          </span>
          {/* </div> */}
          <span className="send-msg">
            <img src={SendMsg} alt="" onClick={handleUserMessage} />
          </span>
        </div>
      </div>
    </>
  );
}

export default ChatBotDiv;
