import React, { useState, useEffect } from 'react'
import { fetchDoctorProfile } from '../../apiCall';
import "./DashboardBookAppo.scss"
import dashboard from "../../Assets/icons/Dashboard.svg"
import download from "../../Assets/icons/Download.svg"
import facebook from "../../Assets/icons/Facebook.svg"
import file from "../../Assets/icons/File.svg"
import instagram from "../../Assets/icons/Instagram.svg"
import linkdin from "../../Assets/icons/Linkdin.svg"
import logOut from "../../Assets/icons/Log-Out.svg"
import search from "../../Assets/icons/Search.svg"
import setting from "../../Assets/icons/Setting.svg"
import userPic from "../../Assets/image/user-profile.png"
// import { ThemeProvider } from 'styled-components';
// import { InfinitySpin } from 'react-loader-spinner'
import BookAppointment from './BookAppointment/BookAppointment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { toast } from 'react-hot-toast';
import { InfinitySpin } from 'react-loader-spinner';


function DashboardBookAppo() {

  const [doctorProfile, setDoctorProfile] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init();

    const dataFetch = async () => {
      const step = window.localStorage.getItem("doctorProfile")
      if (step !== null) {
        const initData = JSON.parse(window.localStorage.getItem("doctorProfile"))
        setDoctorProfile(initData)
      }
      const token = window.localStorage.getItem("token")
      
      const profileRe = await fetchDoctorProfile(token);
      const reMessage = profileRe["status"][0]["Message"]
      if (reMessage === "success") {
        // toast.success(reMessage)
        setDoctorProfile(profileRe["data"])
        window.localStorage.setItem("doctorProfile", JSON.stringify(profileRe["data"]))
      }
      else {
        toast.error(reMessage)
      }


    };

    dataFetch();
  }, []);
  return (
    <section className='section-dashboard-home'>
      <div className="comman-container">
        <div className="inner-container">
          <div className="column-1">
            <button className="check-your-teeth">Check Your Teeth</button>
            <div className="profile">
              <div className="user-detail">
                <img src={userPic} className='profile-pic' alt="" />
                <div className="user-name-wrapper">
                  <div className="username">Shailesh</div>
                  <div className="edite">Edit Profile</div>
                </div>
              </div>
              <div className="social-link">
                <img src={facebook} alt="" />
                <img src={instagram} alt="" />
              </div>
            </div>
            <div className="dashboard-link">
              <div className="link"><img src={dashboard} alt="" />Dashboard</div>
              <div className="link"><img src={setting} alt="" />Setting</div>
              <div className="link"><img src={logOut} alt="" />Log Out</div>
            </div>
            <div className="under-line"></div>
            <div className="share-files">
              <div className="heading">
                <div className='title'>Shared files</div>
                <div className="see-all">See all</div>
              </div>
              <div className="files">
                <img className="file-logo" src={file} alt="" />
                <div className="file-name-wrapper">
                  <div className="file-name">File.zip</div>
                  <div className="file-detail">02 October,2022 at 08:17 15.45 Mb</div>
                </div>
                <img className="file-download" src={download} alt="" />
              </div>

            </div>
            <div className="dentists">
              <div className="heading">Dentists</div>
              <div className="search-bar">
                <img src={search} alt="" />
                <input type="text" cla placeholder='Search Dentists&Services' />
              </div>
              <div className="dentist-profile-wrapper">
                <img src={userPic} className='dentist-profile-pic' alt="" />
                <div className="dentist-detail-wrapper">
                  <div className="dentist-name">Dr. Chinmoy Hazarika</div>
                  <div className="profession">Endodontist</div>
                  <div className="social-link-wrapper">
                    <img src={linkdin} alt="" />
                    <img src={instagram} alt="" />
                    <img src={facebook} alt="" />
                  </div>
                </div>
              </div>
              <div className="dentist-profile-wrapper">
                <img src={userPic} className='dentist-profile-pic' alt="" />
                <div className="dentist-detail-wrapper">
                  <div className="dentist-name">Dr. Chinmoy Hazarika</div>
                  <div className="profession">Endodontist</div>
                  <div className="social-link-wrapper">
                    <img src={linkdin} alt="" />
                    <img src={instagram} alt="" />
                    <img src={facebook} alt="" />
                  </div>
                </div>
              </div>
              <div className="dentist-profile-wrapper">
                <img src={userPic} className='dentist-profile-pic' alt="" />
                <div className="dentist-detail-wrapper">
                  <div className="dentist-name">Dr. Chinmoy Hazarika</div>
                  <div className="profession">Endodontist</div>
                  <div className="social-link-wrapper">
                    <img src={linkdin} alt="" />
                    <img src={instagram} alt="" />
                    <img src={facebook} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column-2">
            {doctorProfile.length > 0 ?
                         <BookAppointment doctorProfile={doctorProfile} />


              : <div className='loader'>
                <InfinitySpin
                  width='200'
                  color="#0290DE"
                />
              </div>}
            {/* <BookAppointment /> */}
            {/* <ThemeProvider theme={theme}>
              <ChatBot steps={data} headerTitle="Dr. Ralph Edwards" />
            </ThemeProvider> */}

          </div>
        </div>
      </div>

    </section>
  )
}

export default DashboardBookAppo