import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import search from "../../Assets/icons/Search.svg";
import menu from "../../Assets/icons/Menu.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import linkdin from "../../Assets/icons/Linkdin.svg";
import facebook from "../../Assets/icons/Facebook.svg";
import instagram from "../../Assets/icons/Instagram.svg";
import google from "../../Assets/icons/Google-+.svg";
import { useStateValue } from "../../StateProvider";
import logo from "../../Assets/image/logo.png";

function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [{ isLoggedIn }, dispatch] = useStateValue();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const logOut = () => {
    window.localStorage.clear();
    handleClose();

    dispatch({ type: "LOGIN", status: false });
    dispatch({ type: "GOOGLELOGINSTATUS", status: false });
    dispatch({ type: "USERPROFILESTATUS", status: false });
    dispatch({ type: "USER_TYPE", status: "" });
    navigate("/");
  };

  useEffect(() => {
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  return (
    <header className="section-header">
      <div className="comman-container header-container">
        <div className="web-inner-container">
          <Link to="/" className="logo-link">
            <div
              className="logo"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {/* <Logo /> */}
              <img src={logo} alt="" />
            </div>
          </Link>
          <div className="link-wrapper">
            <div
              className="nav-link-wrapper"
              data-aos="zoom-out"
              data-aos-duration="1000"
            >
              <Link to="/about-us">
                <div className="nav-link">About Us</div>
              </Link>
              {/* <Link>
                <div className="nav-link">Services</div>
              </Link> */}
              <Link to="/membership-plan">
                <div className="nav-link">Membership Plans</div>
              </Link>
              {/* <div className="nav-link"  onClick={() => navigate("/about-us")}>TEAM</div> */}
              <Link to="/faqs">
                <div className="nav-link">FAQ’s</div>
              </Link>
              <Link to="/contact-us">
                <div className="nav-link">Ask Questions ?</div>
              </Link>
              <Link to="/chat-bot">
                <div className="nav-link">Chat Bot</div>
              </Link>
            </div>
            <div className="user" data-aos="fade-left" data-aos-duration="1000">
              <img src={search} alt="" />
              {isLoggedIn ? (
                <>
                  <button
                    className="login outline-button"
                    onClick={() => logOut()}
                  >
                    Log out
                  </button>
                  <Link>
                    <button
                      className="login outline-button dashboard"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboardhome");
                      }}
                    >
                      Dashboard
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/sign-in">
                    <button className="login outline-button">Log In</button>
                  </Link>
                  <Link to="/sign-up">
                    <button className="signup primary-button">Sign up</button>
                  </Link>
                </>
              )}
              {/* <div className="user-detail">
                              <img src={userPic} className="user-profile" alt="" />
                              <div className="user-name">Ralph Edwards</div>
                          </div> */}
            </div>
          </div>
        </div>
        <div className="mobile-inner-container">
          <img
            src={menu}
            onClick={() => handleShow()}
            className="menu"
            alt=""
          />
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
          <img src={search} className="search" alt="" />
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="nav-link-wrapper">
            <div className="nav-link-wrapper main-nav-options">
              <Link to="/" onClick={() => handleClose()}>
                Home
              </Link>
              <Link to="/about-us" onClick={() => handleClose()}>
                About Us
              </Link>
              {/* <Link onClick={() => handleClose()}>Services</Link> */}
              <Link to="//membership-plan" onClick={() => handleClose()}>
              Membership Plans
              </Link>
              <Link to="/faqs" onClick={() => handleClose()}>
                FAQ’s
              </Link>
              <Link to="/contact-us" onClick={() => handleClose()}>
                Ask Questions ?
              </Link>
              <Link to="/chat-bot" onClick={() => handleClose()}>
                <div className="nav-link">Chat Bot</div>
              </Link>
              {isLoggedIn ? (
                <div className="action-btn">
                  <Link>
                    <button
                      className="logout outline-button"
                      onClick={() => logOut()}
                    >
                      Log out
                    </button>
                  </Link>
                  <Link>
                    <button
                      className="primary-button"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboardhome");
                      }}
                    >
                      Dashboard
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="action-btn">
                  <Link to="/sign-in" onClick={() => handleClose()}>
                    <button className="outline-button">Log In</button>
                  </Link>
                  <Link to="/sign-up" onClick={() => handleClose()}>
                    <button className="primary-button">Sign up</button>
                  </Link>
                </div>
              )}
            </div>
            {/* <div className='nav-link-wrapper main-nav-options'>
            <Link to={"/dashboardhome"} className='side-div'>
              <img src={Dashboard} alt='dashboard' />
              <p className='side-nav-txt'>Dashboard</p>
            </Link>
            <Link to={"/chat"} className='side-div'>
              <img src={Chat} alt='dashboard' />
              <p className='side-nav-txt txt-blue'>Dashboard</p>
            </Link>
            <Link to={"/edit-profile"} className='profile side-div'>
              <img src={Profile} alt='profile' />
              <p className='side-nav-txt txt-blue'>Profile</p>
            </Link>
          </div> */}
            <div className="line"></div>
            <div className="social-link">
              <img src={linkdin} alt="" />
              <img src={facebook} alt="" />
              <img src={google} alt="" />
              <img src={instagram} alt="" />
            </div>
            <div className="toll-free-number">Toll Free : 18XX-XXX-XXXX</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
}

export default Header;
