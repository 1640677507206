import React, { useEffect } from 'react';
import AOS from 'aos';
import "./OtherLink.scss"
import page from "../../../../Assets/image/signup-4.png"



function OtherLink({ nextSteps, prevStep, formData, setFormData, progresBarMaxValue, progresBarValue }) {
    useEffect(() => {
        // window.scrollTo(0, 0)
        AOS.init({
            // initClassName: 'aos-init',
            // useClassNames: false,
            once: true,
        });
    }, [])
    return (
        <section className="section-other-link">
            <div className="inner-container">
                <div className="left-column">
                    <div className="greeting-message" >
                        <span data-aos="fade-right" data-aos-duration="1000">
                            AI-powered diagnosis for healthier teeth
                        </span>
                    </div>
                    <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
                </div>
                <div className="right-column">
                    <div className="container-wrapper" >
                        <div className='container-wrapper-inner' data-aos="fade-up" data-aos-duration="1000" >

                            <progress id="form-step" value={progresBarValue} max={progresBarMaxValue} ></progress>
                            {/* <div className="title" >Enter your email address that we should use to stay connected. We ensure no spam :)<span>&#x1F60E;</span> </div> */}
                            <div className="sub-title">Do you have any personal website ?</div>
                            <div className="input-container" >
                                <div className="input-inner-container">
                                    <i class="fas fa-globe"></i>
                                    <input type="text" placeholder='Enter your personal website link' value={formData.OtherLink} onChange={(e) => setFormData({ ...formData, OtherLink: e.target.value })} />
                                </div>
                            </div>

                            <div className="button-container" >
                                <button className='prev disable-button' onClick={() => prevStep()}>Previous</button>
                                <div className="end">

                                    <button className='next outline-button ' onClick={() => nextSteps()}>Skip</button>
                                    <button className='next primary-button ' onClick={() => nextSteps()}>Next</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default OtherLink