import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import "./dashboardSideHeader.scss";
import search from "../../Assets/icons/Search.svg";
import menu from "../../Assets/icons/Menu.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import linkdin from "../../Assets/icons/Linkdin.svg";
import facebook from "../../Assets/icons/Facebook.svg";
import instagram from "../../Assets/icons/Instagram.svg";
import google from "../../Assets/icons/Google-+.svg";
import { useStateValue } from "../../StateProvider";
import Dashboard from "../../Assets/icons/Dashboard.svg";
import Profile from "../../Assets/icons/profile.svg";
import Chat from "../../Assets/icons/chat.svg";
import { BsCalendar2Date } from "react-icons/bs";
import { MdCardMembership } from "react-icons/md";
import logo from "../../Assets/image/logo.png";

// import userPic from "../Assets/image/user-profile.png"

function DashboardSideHeader() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [{ isLoggedIn }, dispatch] = useStateValue();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const logOut = () => {
    window.localStorage.setItem("token", "");
    handleClose();

    window.localStorage.clear();

    dispatch({ type: "LOGIN", status: false });
    dispatch({ type: "GOOGLELOGINSTATUS", status: false });
    dispatch({ type: "USERPROFILESTATUS", status: false });
    dispatch({ type: "USER_TYPE", status: "" });
    navigate("/");
  };

  useEffect(() => {
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);
  return (
    <header className="section-header">
      <div className="comman-container">
        <div className="web-inner-container">
          <Link to="/" className="logo-link">
            <div
              className="logo"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={logo} alt="" />
            </div>
          </Link>
          <div className="link-wrapper">
            <div
              className="nav-link-wrapper"
              data-aos="zoom-out"
              data-aos-duration="1000"
            >
              <Link to="/about-us">
                <div className="nav-link">About Us</div>
              </Link>
              {/* <Link>
                <div className="nav-link">Services</div>
              </Link> */}
              <Link to="/membership-plan">
                <div className="nav-link">Membership Plan</div>{" "}
              </Link>
              {/* <div className="nav-link"  onClick={() => navigate("/about-us")}>TEAM</div> */}
              <Link to="/faqs">
                <div className="nav-link">FAQ’s</div>
              </Link>
              <Link to="/contact-us">
                <div className="nav-link">Ask Questions ?</div>
              </Link>
              <Link to="/chat-bot">
                <div className="nav-link">Chat Bot</div>
              </Link>
            </div>
            <div className="user" data-aos="fade-left" data-aos-duration="1000">
              <img src={search} alt="" />
              {isLoggedIn ? (
                <>
                  {/* <Link> */}
                  <button
                    className="login outline-button"
                    onClick={() => logOut()}
                  >
                    Log out
                  </button>
                  {/* </Link> */}
                  <Link>
                    <button
                      className="login outline-button dashboard"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboardhome");
                      }}
                    >
                      Dashboard
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/sign-in">
                    <button className="login outline-button">Log In</button>
                  </Link>
                  <Link to="/sign-up">
                    <button className="signup primary-button">Sign up</button>
                  </Link>
                </>
              )}
              {/* <div className="user-detail">
                                <img src={userPic} className="user-profile" alt="" />
                                <div className="user-name">Ralph Edwards</div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="mobile-inner-container">
          <img
            src={menu}
            onClick={() => handleShow()}
            className="menu"
            alt=""
          />
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
          <img src={search} className="search" alt="" />
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="nav-link-wrapper">
            <div className="nav-link-wrapper main-nav-options">
              <Link
                to={"/dashboardhome"}
                className="side-div"
                onClick={() => handleClose()}
              >
                <img src={Dashboard} alt="dashboard" />
                <p className="side-nav-txt">Dashboard</p>
              </Link>
              <Link
                to={"/calendar"}
                className="side-div"
                onClick={() => handleClose()}
              >
                <BsCalendar2Date size={24} />
                <p className="side-nav-txt">Calendar</p>
              </Link>
              <Link
                to={"/chat"}
                className="side-div"
                onClick={() => handleClose()}
              >
                <img src={Chat} alt="dashboard" />
                <p className="side-nav-txt txt-blue">Chat</p>
              </Link>
              {window.localStorage.getItem("userType") === "patient" && (
                <Link
                  to={"/membership"}
                  className="side-div"
                  onClick={() => handleClose()}
                >
                  <MdCardMembership size={24} />
                  <p className="side-nav-txt txt-blue">Membership</p>
                </Link>
              )}
              <Link
                to={"/edit-profile"}
                className="side-div"
                onClick={() => handleClose()}
              >
                <img src={Profile} alt="profile" />
                <p className="side-nav-txt txt-blue">Profile</p>
              </Link>
            </div>
            <div className="action-btn">
              <button className="login outline-button" onClick={() => logOut()}>
                Log out
              </button>
            </div>
            {/* <div className='line'></div>
            <div className='social-link'>
              <img src={linkdin} alt='' />
              <img src={facebook} alt='' />
              <img src={google} alt='' />
              <img src={instagram} alt='' />
            </div>
            <div className='toll-free-number'>Toll Free : 18XX-XXX-XXXX</div> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
}

export default DashboardSideHeader;
