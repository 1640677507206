import React, { useEffect, useState } from "react";
import AOS from "aos";
import "./Signin.scss";
import page1 from "../../Assets/image/signup-1.png";
import dentistIcon from "../../Assets/icons/dentist.svg";
import patientIcon from "../../Assets/icons/patient.svg";
import google from "../../Assets/icons/google.svg";
// import facebook from "../../Assets/icons/Facebook.svg"
// import { MDBInput } from 'mdb-react-ui-kit';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { GoogleLogin, signin } from "../../apiCall";
import { useStateValue } from "../../StateProvider";
import { useGoogleLogin } from "@react-oauth/google";

function Signin() {
  const initialValues = { email: "", password: "", userType: "doctor" };
  const [formData, setFormData] = useState(initialValues);
  const [userType, setUserType] = useState("doctor");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const [, dispatch] = useStateValue();
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => verifyUser(tokenResponse),
    onError: (errorResponse) => toast.error(errorResponse),
  });
  const verifyUser = async (data) => {
    try {
      const re = await GoogleLogin({ access_token: data?.access_token });
      if (re?.status[0].Response === 200) {
        window.localStorage.setItem("token", re.access_token);
        window.localStorage.setItem("profile", re.data.profile);
        window.localStorage.setItem("email", re.data.email);
        window.localStorage.setItem("isGoogleLogIn", true);
        window.localStorage.setItem("userType", re.data.userType);
        window.localStorage.setItem(
          "isProfileCompleted",
          re.data.isProfileCompleted
        );

        toast.success("login successful.");
        dispatch({ type: "USER_TYPE", status: re.data.userType });
        dispatch({ type: "LOGIN", status: true });
        dispatch({ type: "GOOGLELOGINSTATUS", status: true });
        dispatch({
          type: "USERPROFILESTATUS",
          status: re.data.isProfileCompleted,
        });

        if (re.data.isProfileCompleted) {
          window.localStorage.setItem("name", re.data.name);
          window.localStorage.setItem("phoneNumber", re.data.phoneNumber);
          if (re.data.is_doctor) {
            navigate("/dashboardhome");
          } else {
            window.localStorage.setItem(
              "is_subscriber",
              re.data.is_subscriber
            );

            navigate("/");
          }
        } else {
          navigate("/google/sign-up-form");
        }
      } else {
        toast.error(re.status[0].Message);
      }
    } catch {}
  };

  const onSubmit = async () => {
    setFormData({ ...formData, userType: userType });
    const data = { ...formData, userType: userType };
    window.scrollTo(0, 0);

    dispatch({ type: "SET_LOADING", status: true });
    const signup = await signin(data);
    dispatch({ type: "SET_LOADING", status: false });
    const reMessage = signup["status"][0]["Error"];
    if (reMessage === "False") {
      toast.success(signup.status[0].Message);
      window.localStorage.setItem("token", signup["access_token"]);
      window.localStorage.setItem("email", signup.data.email);
      window.localStorage.setItem("name", signup.data.name);
      window.localStorage.setItem("phoneNumber", signup.data.phoneNumber);
      window.localStorage.setItem("profile", signup.data.profile);
      window.localStorage.setItem("userType", signup.data.userType);
      if (signup.data.userType === "patient") {
        window.localStorage.setItem("is_subscriber", signup.data.is_subscriber);
      }

      dispatch({ type: "LOGIN", status: true });
      dispatch({ type: "USER_TYPE", status: signup.data.userType });

      if (signup.data.userType === "doctor") {
        navigate("/dashboardhome");
      } else {
        navigate(-1);
      }
    } else {
      toast.error(signup.status[0].Message);
      setFormData(initialValues);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const email_regex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}/;
  const pass_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}/;
  const [emailErr, setEmailErr] = useState("");
  const [pwErr, setPwErr] = useState("");

  const handleEmailChange = (e) => {
    if (e.target.value.length < 1) {
      setEmailErr("Email is required");
      return setFormData({ ...formData, email: "" });
    }
    if (email_regex.test(e.target.value)) {
      setFormData({ ...formData, email: e.target.value });
      setEmailErr("");
    } else {
      setFormData({ ...formData, email: e.target.value });
      setEmailErr("Email is not valid");
    }
  };

  const handlePwChange = (e) => {
    const value = e.target.value.trim();
    if (value.length < 1) {
      setPwErr("Password is required");
      return setFormData({ ...formData, password: "" });
    }
    if (pass_regex.test(value)) {
      setFormData({ ...formData, password: e.target.value });
      setPwErr("");
    } else {
      setFormData({ ...formData, password: e.target.value });
      setPwErr(
        "Password must contain uppercases, lowercases, numbers and special characters and atleast 8 and maximum 16 characters"
      );
    }
  };
  return (
    <section className="section-signin">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              Welcome to gentledental
            </span>
          </div>
          <img
            src={page1}
            data-aos="zoom-out"
            data-aos-duration="1000"
            alt=""
          />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="title">Choose Account Type</div>
              <div className="option">
                <div
                  className={
                    userType === "patient"
                      ? "patient cursor-pointer selected "
                      : "patient cursor-pointer"
                  }
                  onClick={() => {
                    setFormData({ ...formData, userType: "patient" });
                    setUserType("patient");
                  }}
                >
                  <img src={patientIcon} alt="" />
                  <div>Patient</div>
                </div>
                <div
                  className={
                    userType === "doctor"
                      ? "dentist cursor-pointer selected "
                      : "dentist cursor-pointer"
                  }
                  onClick={() => {
                    setFormData({ ...formData, userType: "doctor" });
                    setUserType("doctor");
                  }}
                >
                  <img src={dentistIcon} alt="" />
                  <div>Dentist</div>
                </div>
              </div>
              <div className="sub-title">Login to your account</div>
              <div className="content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
              <div className="input-container">
                <div className="input-box">
                  <input
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleEmailChange}
                  />
                  <small className="error">{emailErr}</small>
                </div>
                {/* <MDBInput label='Enter email' name='emadxil' value={formData.email} size='sm' className='input' id='form1' type='email' onChange={(e) => setFormData({ ...formData, email: e.target.value })} /> */}
              </div>
              <div className="input-container">
                <div className="input-box">
                  <input
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    value={formData.password}
                    onChange={handlePwChange}
                  />
                  <small className="error">{pwErr}</small>
                </div>

                {/* <MDBInput label='Enter Password' name='pasdsword' value={formData.password} size='sm' className='input' id='form1' type='password' onChange={(e) => setFormData({ ...formData, password: e.target.value })} /> */}
              </div>
              <div className="forgot-password-wrap">
                {/* <div className="remember-me">
                  <input
                    type="checkbox"
                    name="rememberMe"
                    value={rememberMe}
                    onChange={() => setRememberMe((prev) => !prev)}
                  />
                  <label className="fs-200 fw-semi-bold"> Remember me</label>
                </div> */}
                {/* <Link
                  to="/forgot-password"
                  className="fs-200 fw-semi-bold forgot-password"
                >
                  Forgot Password ?
                </Link> */}
              </div>
              <div className="btn-container">
                <button className="primary-button" onClick={() => onSubmit()}>
                  Login
                </button>
              </div>
              <div className="icon-container">
                <div
                  className="google-icon cursor-pointer"
                  onClick={() => googleLogin()}
                >
                  <img src={google} alt="" /> Google
                </div>
              </div>

              <div className="have-account">
                Don't have an account{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => navigate("/sign-up")}
                >
                  {" "}
                  Signup
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signin;
