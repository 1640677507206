import React from "react";

import { useStateValue } from "../../StateProvider";
import { ReactComponent as Cross } from "../../Assets/icons/cross.svg";
import "./purchasePlan.scss";
import { displayRazorpay } from "../../Utils/Razorpay";

const PurchasePlan = () => {
  const [{chatHistory,chatSummery}, dispatch] = useStateValue();

  const setPurchaseModalShow = (status) => {
    dispatch({
      type: "SET_PURCHASE_MODAL_SHOW",
      status: status,
    });
  };
  return (
    <div className='plan-model-main'>
      <div className='cross' onClick={() => setPurchaseModalShow(false)}>
        <Cross />
      </div>
      <div className='container'>
        <h3>Choose Your Plan</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>

        <div className='plans'>
          <div className='plan-details'>
            <p className='duration'>Monthly</p>
            <p className='price'>
              ₹6000 <span className='discount'>-15%</span>
            </p>
            <button
              className='btn'
              onClick={() => {
                const data = displayRazorpay({ totalAmount: 6000,chatHistory:chatHistory,chatSummery:chatSummery });
                console.log(data);
              }}
            >
              Choose plan
            </button>
            <p className='plan-desc'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className='plan-details'>
            <p className='duration'>Annual</p>
            <p className='price'>
              ₹600 <span className='discount'>-15%</span>
            </p>
            <button
              className='btn'
              onClick={() => {
                const data = displayRazorpay({ totalAmount: 600.,chatHistory:chatHistory });
                console.log(data);
              }}
            >
              Choose plan
            </button>
            <p className='plan-desc'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasePlan;
