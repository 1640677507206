import React, { useEffect } from "react";
import AOS from "aos";
import "./Password.scss";
import page from "../../../../Assets/image/signup-2.png";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";

function Password({
  nextSteps,
  prevStep,
  formData,
  setFormData,
  progresBarValue,
  progresBarMaxValue,
}) {
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      // initClassName: 'aos-init',
      // useClassNames: false,
      once: true,
    });
  }, []);

  const pass_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}/;
  const [pwErr, setPwErr] = useState("");
  const [confirmPwErr, setConfirmPwErr] = useState("");

  const handlePwChange = (e) => {
    const value = e.target.value.trim();
    if (e.target.value.length < 1 && value === "") {
      setPwErr("Password is required");
      setFormData({ ...formData, password: "" });
    }
    if (pass_regex.test(value)) {
      setFormData({ ...formData, password: e.target.value });
      setPwErr("");
    } else {
      setFormData({ ...formData, password: e.target.value });
      setPwErr("Password should match all the conditions above");
    }
  };

  const handleConfirmPwChange = (e) => {
    if (e.target.value < 1) {
      setConfirmPwErr("Confirm Password is required");
      setFormData({
        ...formData,
        confirmPassword: "",
      });
    } else {
      setFormData({
        ...formData,
        confirmPassword: e.target.value,
      });
      if (e.target.value === formData.password) {
        setConfirmPwErr("");
      } else {
        setConfirmPwErr("Passwords do not match");
      }
    }
  };
  return (
    <section className="section-password">
      <div className="inner-container">
        <div className="left-column">
          <div className="greeting-message">
            <span data-aos="fade-right" data-aos-duration="1000">
              We make smiles healthier & brighter
            </span>
          </div>
          <img src={page} data-aos="zoom-out" data-aos-duration="1000" alt="" />
        </div>
        <div className="right-column">
          <div className="container-wrapper">
            <div
              className="container-wrapper-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <progress
                id="form-step"
                value={progresBarValue}
                max={progresBarMaxValue}
              ></progress>
              <div className="title">
                Let’s set a strong password for your account
                <span>&#x1F60E;</span>{" "}
              </div>
              <div className="password-des">
                {" "}
                Password Must Be Contains Uppercase, Lowercase Letters, Numbers,
                Special Characters And Length Is Greater Than 8 Character And
                Less Then 16 Character.
              </div>
              <div className="input-container">
                <div className="input-box">
                  <MDBInput
                    label="Enter your password"
                    name="password"
                    value={formData.password}
                    size="sm"
                    className="input"
                    id="form1"
                    type="password"
                    onChange={handlePwChange}
                  />
                  <small className="error">{pwErr}</small>
                </div>
                <div className="input-box">
                  <MDBInput
                    label="Re-Enter your Confirm password"
                    name="password"
                    value={formData.confirmPassword}
                    size="sm"
                    className="input"
                    id="form1"
                    type="password"
                    onChange={handleConfirmPwChange}
                  />
                  <small className="error">{confirmPwErr}</small>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="prev disable-button"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button
                  className={
                    (formData.password !== formData.confirmPassword) |
                    !pass_regex.test(formData.password)
                      ? "next disable-button disable-hover"
                      : "next primary-button "
                  }
                  disabled={!formData.name}
                  onClick={() => nextSteps()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Password;
