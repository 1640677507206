import React, { useState } from 'react';
import "./BookAppointment.scss"
import linkdin from "../../../Assets/icons/Linkdin.svg"
import facebook from "../../../Assets/icons/Facebook.svg"
import instagram from "../../../Assets/icons/Instagram.svg"
import profilPic from "../../../Assets/image/user-profile.png"
import { PaginationControl } from 'react-bootstrap-pagination-control';

function BookAppointment({ doctorProfile }) {
    const [page, setPage] = useState(1)
    console.log(doctorProfile)
    return (
        <section className="section-book-appointment">
            <div className="commn-container">
                <div className="inner-container">
                    <div className="choose-dentist-containner">
                        <div className="heading">Choose Dentists</div>
                        <div className="sub-title">dentist Services - <strong>Tooth Decay</strong></div>
                        <div className="dentist-list-wrapper">
                            {doctorProfile.map(data => 
                                <div className="dentist-profile-wrapper">
                                    <img src={profilPic} alt="" />
                                    <div className="dentist-detail">
                                        <div className="name">Dr. {data.fullName}</div>
                                        <div className="expertise">{JSON.parse(data.expertise)}</div>
                                        <div className="social-links">
                                            <img src={linkdin} alt="" />
                                            <img src={facebook} alt="" />
                                            <img src={instagram} alt="" />
                                        </div>
                                    </div>
                                    <div className="view-profile">
                                        <button className="outline-button"> View</button>
                                    </div>
                                </div>

                            )}

                        </div>
                        <div className="pagination-wrapper" >
                            <PaginationControl
                                page={page}
                                between={4}
                                total={250}
                                limit={4}
                                changePage={(page) => {
                                    setPage(page);
                                    console.log(page)
                                }}
                                ellipsis={1}
                            />
                        </div>
                    </div>
                    <div className="choose-date">
                        <button className="date primary-button"><i class="fas fa-calendar"></i> Choose Date</button>
                        <span className="selected-date">Tuesday, 25 October</span>
                    </div>
                    <div className="choose-slot">
                        <div className="title">Choose Slots Availability</div>
                        <div className="dentist-name">Dr. Ralph Edwards</div>
                        <div className="slot-wrapper">
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                            <button className="slot">11:00</button>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="message">
                        <div className="heading">Message</div>
                        <textarea name="message-content" id="" cols="30" rows="10" placeholder="Type a Message..."></textarea>
                    </div>
                    <div className="confirm-btn">
                        <button className="primary-button">Confirm Appointment</button>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default BookAppointment