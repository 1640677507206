import React, { useEffect } from "react";
import "./PrivacyPolicy.scss";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="privacy-policy">
      <div className="title">Privacy Policy</div>
      <div className="sub-title">
        <h5>Introduction:</h5>
        <span>
          GentleDental.ai, including our innovative Gentle AI Smart Toothbrush,
          is dedicated to safeguarding the privacy of our users. This Privacy
          Policy provides detailed information on the collection, use,
          protection, and sharing of your data.
        </span>
      </div>
      <div className="contant">
        <h5>Information We Collect:</h5>
        <div className="list">
          <span>
            <strong>1. Personal Identification Information: </strong>
            <ul>
              <li>Name, contact details (email, phone number), and address.</li>
              <li>
                Account login credentials (username and encrypted password).
              </li>
            </ul>
          </span>
          <span>
            <strong>2. Dental and Health Information: </strong>
            <ul>
              <li>
                Oral health data, including brushing frequency, duration,
                pressure, and areas covered or missed, collected through the
                Gentle AI Smart Toothbrush.
              </li>
              <li>
                Dental history and treatment records submitted by you or your
                dental care provider.
              </li>
            </ul>
          </span>
          <span>
            <strong>3. Technical and Device Information: </strong>
            <ul>
              <li>
                IP address, browser type, and version; device identifiers; and
                operating system.
              </li>
              <li>
                Usage data including clicks, scrolls, and interaction with the
                GentleDental.ai services.
              </li>
            </ul>
          </span>
          <span>
            <strong>4. Cookies and Tracking Technologies: </strong>
            <ul>
              <li>
                Use of cookies, web beacons, and similar technologies to track
                user activity and hold certain information to personalize and
                improve your experience.
              </li>
            </ul>
          </span>
        </div>

        <span>
          <h5>How We Use Your Information:</h5>
          <ul>
            <li>
              To deliver personalized dental care recommendations and monitor
              treatment progress.
            </li>
            <li>
              To improve our products, services, and user experience through
              data analysis.
            </li>
            <li>
              For customer support, to respond to inquiries and troubleshoot
              issues.
            </li>
            <li>
              To communicate updates, security alerts, and marketing
              communications tailored to your preferences.
            </li>
          </ul>
        </span>
        <span>
          <h5>Information Sharing and Disclosure:</h5>
          <ul>
            <li>
              With dental professionals for the provision of personalized care.
            </li>
            <li>
              Third-party service providers for data analysis, marketing
              assistance, customer service, and technology services. These
              providers are bound by confidentiality agreements to protect your
              information.
            </li>
            <li>
              In the event of a business transfer, sale, or merger, where user
              information is among the transferred assets.
            </li>
            <li>
              When required by law, such as to comply with a subpoena or similar
              legal process.
            </li>
          </ul>
        </span>
        <span>
          <h5>Data Security and Retention:</h5>
          <ul>
            <li>
              Implementing industry-standard security measures including
              encryption, firewalls, and secure socket layer technology to
              protect against unauthorized access to or misuse of user data.
            </li>
            <li>
              Data retention policies designed to retain user information only
              for as long as necessary to fulfill the purposes outlined in this
              policy.
            </li>
          </ul>
        </span>
        <span>
          <h5>Your Rights and Choices:</h5>
          <ul>
            <li>
              Access and Correction: You have the right to access, update, or
              correct your personal information.
            </li>
            <li>
              Deletion: You may request the deletion of your personal
              information, subject to certain exceptions.
            </li>
            <li>
              Opt-out: You have the right to opt-out of marketing communications
              and can adjust your preferences at any time.
            </li>
            <li>
              Data Portability: You may request a copy of your personal data in
              a structured, commonly used, and machine-readable format.
            </li>
          </ul>
        </span>
        <span>
          <h5>International Data Transfers:</h5>
          <span>
            Information we collect may be stored and processed in any country
            where we have operations or where we engage service providers. By
            using our services, you consent to the transfer of information to
            countries outside of your country of residence.
          </span>
        </span>
        <span>
          <h5>Changes to This Privacy Policy:</h5>
          <span>
            We reserve the right to update or change our Privacy Policy at any
            time. We will notify you of any significant changes by posting the
            new Privacy Policy on this page and updating the effective date.
          </span>
        </span>
        <span>
          <h5>Contacting Us:</h5>
          <span>
            For questions about this Privacy Policy or our privacy practices,
            please contact us at support@gentledental.ai
          </span>
        </span>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
